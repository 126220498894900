<template>
  <div
    class="product"
    :class="{ 'product--border': hasBorder, 'product--dessert': product.price }"
  >
    <div class="product__price" v-if="product.price">
      {{ product.price.toFixed(2) }}&thinsp;€
    </div>

    <img class="product__image" :src="product.img" alt="" />

    <div class="product__message" v-if="product.isSelected">
      <p
        v-if="product.price && !product.isValid"
        class="product__message__highlight"
      >
        {{ product.price.toFixed(2) }}&thinsp;€
      </p>
      <font-awesome-icon
        v-if="!product.price || product.isValid"
        :icon="product.isValid ? faCheck() : faTimes()"
        class="product__message-icon"
        :class="
          product.isValid
            ? 'product__message-icon--correct'
            : 'product__message-icon--invalid'
        "
        size="2x"
      />

      <div class="product__message-text" v-text="productMessage"></div>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'ProductItem',
  components: {
    FontAwesomeIcon
  },
  props: {
    product: {
      type: {
        img: String,
        isSelected: Boolean,
        isValid: Boolean,
        isUnderline: Boolean,
        price: Number
      },
      required: true
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    productMessage() {
      const nope = this.product.price ? 'Trop cher' : 'Nop';
      return this.product.isValid ? 'Yep' : nope;
    }
  },
  methods: {
    faTimes() {
      return faTimes;
    },
    faCheck() {
      return faCheck;
    }
  }
};
</script>

<style scoped lang="scss">
.product {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 1em;
  height: 100%;

  @media (max-width: 575px) {
    padding: 1em 0;
  }

  &--dessert {
    gap: 10px;
  }

  &--border {
    border-bottom: 10px solid #ffce00;
  }

  &__price {
    font-size: 1rem;
    font-weight: bold;
    line-height: 20px;
    color: black;
    background-color: white;
    border-radius: 40px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: -8px;

    @media (min-width: 2560px) {
      font-size: 1.5rem;
      line-height: 30px;
      margin-bottom: -15px;
    }

    @media (max-width: 575px) {
      font-size: 0.76rem;
      padding: 0;
      line-height: 10px;
      margin: 0;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
    object-position: center bottom;

    .product--dessert & {
      margin-top: 10px;
    }

    @media (max-width: 575px) {
      width: 80%;
    }
  }

  &__message {
    position: absolute;
    width: 85%;
    height: 85%;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 4px;

    &__highlight {
      color: black;
      background-color: #ffce00;
      padding: 0 0;
      font-size: 1rem;
      font-weight: bold;
      margin: 0 0 0.5rem;
      position: relative;

      &:before {
        content: '';
        left: -5px;
        top: 0;
        position: absolute;
        width: 5px;
        height: 0;
        border-bottom: 24px solid #ffce00;
        border-left: 5px solid transparent;
      }

      &:after {
        content: '';
        right: -5px;
        top: 0;
        position: absolute;
        width: 5px;
        height: 0;
        border-top: 24px solid #ffce00;
        border-right: 5px solid transparent;
      }

      @media (max-width: 575px) {
        font-size: 0.76rem;

        &:before {
          border-bottom: 18px solid #ffce00;
        }

        &:after {
          border-top: 18px solid #ffce00;
        }
      }

      @media (min-width: 2560px) {
        font-size: 1.5rem;

        &:before {
          border-bottom: 36px solid #ffce00;
        }

        &:after {
          border-top: 36px solid #ffce00;
        }
      }
    }

    @media (min-width: 2560px) {
      padding-top: 10px;
      width: 100%;
      height: 100%;
    }

    @media (max-width: 2560px) and (min-width: 1920px) {
      padding-top: 10px;
      width: 90%;
      height: 90%;
    }

    @media screen and (max-width: 575px) {
      width: 95% !important;
      height: 90% !important;
      margin: 0;
    }
  }

  &__message-icon {
    @media (min-width: 2560px) {
      width: 70px !important;
      height: 70px !important;
    }

    @media (max-width: 2560px) and (min-width: 1920px) {
      width: 50px !important;
      height: 50px !important;
    }

    &--invalid {
      color: red;
    }

    &--correct {
      color: green;
    }
  }

  &__message-text {
    font-size: 1rem;
    font-weight: 500;
    text-align: center;

    @media (min-width: 2560px) {
      font-size: 1.3rem;
    }
  }
}
</style>
