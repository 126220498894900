<template>
  <principle
    principle="Actions minimales"
    game="pizzas"
    gameName="À vous les pizzas !"
    bigTitle="true"
    nextGame="poulpe"
  >
    <template v-slot:main-content>
      <div class="introduction">
        <h2 class="introduction__title">
          D’après Bastien &amp; Scapin (1993)*&nbsp;:
        </h2>
        <p class="m-0 font-italic">
          Le critère Actions Minimales concerne la
          <b>charge de travail</b> quant aux actions nécessaires à l’atteinte
          d’un but, à l’accomplissement d’une tâche. Il s’agit ici de
          <b
            >limiter autant que possible les étapes par lesquelles doivent
            passer les utilisateurs.</b
          >
        </p>
      </div>

      <div class="section">
        <h2 class="section__title">Application de la loi</h2>
        <p>
          <b>"Est-ce qu’on ne pourrait pas faire plus simple&nbsp;?"</b><br />
          En tant que conceptrices d’IHM, c’est une des questions que nous nous
          posons régulièrement. En effet, plus les actions nécessaires pour
          atteindre un but sont nombreuses et compliquées, plus la charge de
          travail augmente. Or, plus la charge de travail augmente, plus le
          risque d’erreurs est élevé.
        </p>
      </div>

      <principle-practices>
        <template v-slot:header>
          <p class="m-0">
            Une bonne pratique simple est de ne pas demander à l'utilisateur de
            saisir des informations pouvant être déduites par le sytème.
          </p>
        </template>
        <template v-slot:good-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <img
                alt
                src="@/assets/img/principles/explication-bonne-pratique.png"
              />
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="rightIcon"
                class="valid-icon"
                size="2x"
              />
              <span>Bonne pratique</span>
            </p>
          </div>
        </template>
        <template v-slot:bad-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <img
                alt=""
                src="@/assets/img/principles/explication-mauvaise-pratique.png"
              />
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="wrongIcon"
                class="invalid-icon"
                size="2x"
              />
              <span> Mauvaise pratique </span>
            </p>
          </div>
        </template>
      </principle-practices>

      <div class="section">
        <p>
          Malheureusement, il n’est pas rare que ce principe soit enfreint, par
          exemple&nbsp;:
        </p>
        <ul class="section__list">
          <li>
            des menus à tiroirs pour lesquels il faut 5 clics pour atteindre
            l’outil recherché&nbsp;;
          </li>
          <li>
            des formulaires dans lesquels la ville n’est pas déduite du code
            postal et inversement&nbsp;;
          </li>
          <li>
            des formulaires sans données par défaut, alors que dans 80% des cas
            la saisie est identique et l’utilisateur n’aurait qu’à vérifier et
            valider.
          </li>
        </ul>
      </div>
      <div class="section">
        <p>
          Ce critère, bien qu’important, est évidemment à
          <strong>adapter selon le contexte</strong>, par exemple&nbsp;:
        </p>
        <ul class="section__list">
          <li>
            pour un formulaire d'inscription, on pourra préférer favoriser
            l'engagement (avec plusieurs petites questions générant plus de
            clics) plutôt que l'action minimale, car avoir toutes les questions
            sur la même page pourrait décourager l'utilisateur&nbsp;;
          </li>
          <li>
            pour un menu avec de nombreuses possibilités, il est préférable de
            favoriser le guidage en s'appuyant sur
            <router-link to="hick" class="basic_link"
              >la loi de Hick</router-link
            >
            (avec des sous-menus générant plus de clics) plutôt que l'action
            minimale, car avoir une liste interminable augmente considérablement
            le temps de recherche.
          </li>
        </ul>
      </div>

      <p class="section">
        <strong>
          Sur ce principe en particulier, il faudra réaliser des tests
          utilisateurs pour trouver le bon compromis entre efficacité et
          guidage.
        </strong>
      </p>
    </template>

    <template v-slot:game-explanation>
      <div class="game-explanation mb-4">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/cuisine-jeu1.png"
                alt="capture d'écran du jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="wrongIcon"
                  class="invalid-icon"
                  size="2x"
                />
                <span> Mauvaise pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Dans la première version du jeu, le critère Actions Minimales
              n’est pas respecté car&nbsp;:
            </p>
            <ul class="section__list">
              <li>
                Meiko fait 2 allers-retours dans la cuisine pour aller chercher
                la sauce tomate puis la mozzarella, alors qu’il pourrait en
                faire 1&nbsp;;
              </li>
              <li>
                les éléments de la cuisine sont éloignés les uns des autres ce
                qui fait perdre du temps à Meiko.
              </li>
            </ul>
            <p class="mt-4">
              Au total, il faut minimum 17 pas pour terminer le 1
              <sup>er</sup> jeu.
            </p>
          </div>
        </v-col>
      </div>
      <div class="game-explanation">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/cuisine-jeu2.png"
                alt="capture d'écran du jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="rightIcon"
                  class="valid-icon"
                  size="2x"
                />
                <span> Bonne pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Pour fluidifier l’expérience, comme c’est le cas dans le 2nd jeu,
              il suffit de&nbsp;:
            </p>
            <ul class="section__list">
              <li>
                réorganiser la cuisine pour rapprocher les éléments&nbsp;;
              </li>
              <li>
                réduire les déplacements inutiles en faisant un seul trajet pour
                aller chercher le pesto et les champignons.
              </li>
            </ul>
            <p class="mt-4 mb-4">
              <b>On réduit alors à 10 pas le parcours optimal.</b>
            </p>
            <p class="m-0">
              Évidemment, il est plus rapide de faire 10 pas que 17&nbsp;! Si
              l'on extrapole au domaine de la conception IHM, il faut essayer de
              réduire le nombre de clics (= le nombre de pas) en optimisant le
              parcours utilisateur.
            </p>
          </div>
        </v-col>
      </div>
    </template>

    <template v-slot:note>
      <p class="note">
        * Référence&nbsp;: Critères Ergonomiques pour l'Évaluation d'Interfaces
        Utilisateurs (version 2.1), J. M. Christian Bastien and Dominique L.
        Scapin, INRIA, Technical report N° 156 Mai 1993
        <a
          href="https://hal.inria.fr/inria-00070012"
          target="_blank"
          rel="noopener noreferrer"
          >[Accès document complet - Site web INRIA]</a
        >
      </p>
    </template>
  </principle>
</template>

<script>
import Principle from './layout/PrinciplePage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PrinciplePractices from './layout/PrinciplePractices.vue';

export default {
  components: {
    Principle,
    PrinciplePractices,
    FontAwesomeIcon
  },

  data() {
    return {
      rightIcon: faCheck,
      wrongIcon: faTimes
    };
  }
};
</script>

<style lang="scss" scoped>
@import './layout/principle-page-content.scss';
</style>
