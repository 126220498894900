<template>
  <game-container ref="container">
    <template>
      <div>
        <v-dialog v-model="dialog" persistent>
          <v-card class="rounded-dialog">
            <charade-result
                :step="step"
                :next="step === 1 ? nextStep : principle"
                :retry="replay"
                :success="success"
                :isLoading="isLoading"
            ></charade-result>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <v-container>
      <div class="title">
        <h1 v-if="step === 1">Quelle est la date de naissance de Nestor&nbsp;?</h1>
        <h1 v-if="step === 2">Quelle est la date d'exil de Nestor&nbsp;?</h1>
      </div>
      <v-card class="sentences">
        <sentence v-for="sentence in sentences[step]" :key="sentence.sentence" :clue="sentence.clue">
          {{ sentence.sentence }}
        </sentence>
        <hr>
        <form v-on:submit.prevent="submit">
          <div>
            <v-col sm="8" cols="12" class="answer answer--info">
              <label v-if="step === 2" for="answer">Quelle est la date d'exil de Nestor&nbsp;? (jj.mm.aaaa)</label>
            </v-col>
            <v-row class="charade-row">
              <v-col sm="8" cols="12" class="answer answer--input px-4">
                <v-text-field
                    id="answer"
                    type="text"
                    name="answer"
                    aria-describedby="inputLiveFeedback"
                    v-model="answer"
                    :error-messages="error"
                    placeholder="Saisissez votre réponse"
                    :state="step === 2 && error ? false : undefined"
                    class="answer-text-field"
                />
              </v-col>
              <v-col sm="4" cols="12" class="answer">
                <button type="submit" class="btn_jeux">
                  Vérifier
                </button>
              </v-col>
            </v-row>
          </div>
        </form>
      </v-card>
    </v-container>
  </game-container>
</template>

<script>
import GameContainer from "../GameContainer.vue";
import CharadeResult from "./CharradeResult.vue";
import Sentence from "./CharradeSentence.vue";

const dateRegex = /^(0[1-9]|[12]\d|3[01])\.(0[1-9]|1[0-2])\.([12]\d{3})$/;

const sentences = {
  1: [
    {sentence: "Mon premier est la boisson issue du raisin.", clue: "Vin"},
    {
      sentence: "Mon second est l'objet caché dans la galette des rois.",
      clue: "Fève"
    },
    {
      sentence: "Mon troisième est une céréale cultivée en Asie.",
      clue: "Riz"
    },
    {
      sentence:
          "Mon quatrième est une clôture végétale servant à limiter un jardin.",
      clue: "Haie"
    },
    {sentence: "Mon cinquième porte malheur.", clue: "13"},
    {sentence: 'Mon sixième est le contraire d\'"avec".', clue: "Sans"},
    {sentence: "Mon septième est l'âge maudit des rockeurs.", clue: "27"},
    {sentence: "Mon tout est la date de naissance de Nestor.", clue: null}
  ],
  2: [
    {
      sentence:
          "Mon premier est le nom d'une ville dont vient le fameux cheval utilisé par Ulysse.",
      clue: "Troie"
    },
    {
      sentence: "Mon second est le nom du dieu romain de la guerre.",
      clue: "Mars"
    },
    {
      sentence:
          "Mon troisième est le premier mot du célèbre juron du capitaine Haddock.",
      clue: "Mille"
    },
    {
      sentence:
          "Mon quatrième est l'adverbe désignant un objet acheté dans un magasin.",
      clue: "Neuf"
    },
    {
      sentence:
          "Mon cinquième est le liquide rouge qui coule dans notre corps.",
      clue: "Sang"
    },
    {
      sentence:
          "Mon sixième est le nom de la route emblématique de l’ouest sauvage américain.",
      clue: "66"
    },
    {sentence: "Mon tout est la date d'exil de Nestor.", clue: null}
  ]
};

export default {
  name: "Charade-Game",
  components: {
    GameContainer,
    CharadeResult,
    Sentence
  },
  computed: {
    success() {
      return (
          (this.step === 1 && this.answer === "20.02.1327") ||
          this.answer === "03.03.1966"
      );
    },
  },
  methods: {
    submit(event) {
      this.error = null;
      if (this.step === 1 || this.success || dateRegex.test(this.answer)) {
        this.dialog = true;
      } else {
        event.preventDefault();
        this.error = `La réponse doit être au format jj.mm.aaaa, 20.12.2012 pour le 20 décembre 2012 par exemple`;
      }
    },
    nextStep() {
      this.dialog = false;
      this.step++;
      this.answer = '';
    },
    principle() {
      this.$router.push("/principles/error-handling");
    },
    replay() {
      window.scrollTo({top: 0});
      this.answer = '';
      this.dialog = false;
    },
  },
  data() {
    return {
      answer: '',
      step: 1,
      error: null,
      sentences,
      isGoingToNextStep: false,
      dialog: false,
    };
  },
  mounted() {
    window.scrollTo({top: 0});
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/colors";
@import "../../../assets/scss/buttons";

.title {
  padding-top: 50px;
}

.sentences {
  margin-bottom: 30px;
}

.card-body {
  padding-top: 0;
}

form {
  padding-top: 0;
  margin-bottom: 20px;

  button[type="submit"] {
    @extend %action-button;
    width: auto;
    min-width: 150px;
    margin-top: 0;
    border-radius: 4px;
  }

  #answer {
    height: 48px;
  }
}

.answer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 80px;
  margin-top: 1rem;
  padding: 0;

  @media screen and (max-width: 575px) {
    margin-top: 0;
  }

  &--input {
    @media (max-width: 575px) {
      height: auto;
    }
  }

  &--info {
    height: auto;
    justify-content: start;
    margin-left: 15px;

    @media screen and (max-width: 575px) {
      margin-bottom: 15px;
    }
  }
}

h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
}

.btn_jeux {
  width: auto;
  min-width: 150px;
  height: 56px;
  margin: 0 !important;
  border-radius: 4px;
}

.charade-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }
}

.answer-text-field {
  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
}

</style>
