<template>
  <principle
    principle="Loi de Fitts"
    game="poulpe"
    gameName="Sauve qui peut !"
    nextGame="onTheDouble"
  >
    <template v-slot:main-content>
      <div class="introduction">
        <h2 class="introduction__title">
          La loi de Fitts est énoncée comme ceci par Paul Fitts en 1954&nbsp;:
        </h2>
        <p class="mb-0 font-italic">
          <b
            >Le temps requis pour atteindre une cible est proportionnel à la
            distance</b
          >
          entre le point de départ et la cible, et
          <b
            >inversement proportionnel à la taille de la zone de destination.
          </b>
        </p>
      </div>
      <div class="section">
        <h2 class="section__title">Application de la loi</h2>
        <p class="section__highlight">
          Quand on conçoit un écran, les fonctions les plus utilisées doivent
          être rapidement accessibles et donc de taille respectable et proches
          de l’action précédente.
        </p>
        <p>
          En revanche, une fonctionnalité rarement utilisée ou d'utilisation
          risquée (comme un bouton de suppression définitive par exemple) pourra
          être plus éloigné de la zone d'action principale.
        </p>
        <div>
          En effet, ce modèle mathématique qui étudie le mouvement humain nous
          indique que&nbsp;:
          <ul class="section__list">
            <li>
              <b>plus la cible est petite et lointaine</b>, plus le temps
              nécessaire pour la pointer est long&nbsp;;
            </li>
            <li>
              <b>plus la cible est grande et proche</b>, plus le temps
              nécessaire pour la pointer est court.*
            </li>
          </ul>
        </div>
      </div>

      <principle-practices>
        <template v-slot:header>
          Dans l'exemple "Bonne pratique" que nous vous proposons ici, le bouton
          d'action se situe juste en dessous du dernier champ à saisir. Cette
          loi est valable aussi bien dans le monde physique, avec par exemple
          l'apparition des boutons de réglage de la radio sur le volant pour
          diminuer la distance à parcourir et le temps nécessaire à baisser ou
          augmenter le son.
        </template>
        <template v-slot:good-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <img
                src="../../assets/img/arrow1.png"
                alt="fleche entre le champ et le bouton"
                class="arrow1"
              />
              <label>Passagère 1</label>
              <v-text-field
                class="input-form"
                type="text"
                value="Johana"
                disabled
              >
              </v-text-field>
              <label>Passagère 2</label>
              <v-text-field
                class="input-form"
                type="text"
                value="Lenù"
                disabled
              >
              </v-text-field>
              <button
                class="buton-validation add-button add-button--first"
                disabled
              >
                Ajouter une passagère
              </button>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="rightIcon"
                class="valid-icon"
                size="2x"
              />
              <span>Bonne pratique</span>
            </p>
          </div>
        </template>
        <template v-slot:bad-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <img
                src="../../assets/img/arrow2.png"
                alt="fleche entre le champ et le bouton"
                class="arrow2"
              />
              <div class="text-right">
                <button disabled class="add-button">+</button>
              </div>
              <label>Passagère 1</label>
              <v-text-field
                class="input-form"
                type="text"
                value="Johana"
                disabled
              >
              </v-text-field>
              <label>Passagère 2</label>
              <v-text-field
                class="input-form"
                type="text"
                value="Lenù"
                disabled
              >
              </v-text-field>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="wrongIcon"
                class="invalid-icon"
                size="2x"
              />
              <span> Mauvaise pratique </span>
            </p>
          </div>
        </template>
      </principle-practices>

      <div class="section">
        <p>
          Malheureusement, il n'est pas rare que ce principe soit enfreint, par
          exemple&nbsp;:
        </p>
        <ul class="section__list">
          <li>
            un bouton pour ajouter un élément à une liste de valeurs, petit et
            éloigné de la dernière saisie (exemple illustré ci-contre)&nbsp;;
          </li>
          <li>
            un bouton de validation de formulaire très éloigné de la zone de
            saisie&nbsp;;
          </li>
          <li>une barre d'ascenseur trop fine.</li>
        </ul>
      </div>
      <p class="section">
        <strong
          >Le principe semble simple et pourtant combien de fois avez-vous dû
          faire des aller-retours avec la souris entre deux zones trop
          éloignées&nbsp;?
        </strong>
      </p>
    </template>

    <template v-slot:game-explanation>
      <div class="game-explanation mb-4">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/Fitts-version1.png"
                alt="capture d'écran du jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="wrongIcon"
                  class="invalid-icon"
                  size="2x"
                />
                <span> Mauvaise pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Dans la première version du jeu, la loi de Fitts n’est pas
              respectée car les animaux sont petits et positionnés loin de l'eau
              claire, alors que l'action est fréquente et ne provoque pas
              d'action risquée, elle devrait donc être facilitée. Cliquer
              précisément sur ces cibles demande plus de temps et d’attention et
              peut être source de frustration car une tâche simple prend plus de
              temps qu’elle ne devrait.
            </p>
          </div>
        </v-col>
      </div>
      <div class="game-explanation">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/Fitts-version2.png"
                alt="capture d'écran du jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="rightIcon"
                  class="valid-icon"
                  size="2x"
                />
                <span> Bonne pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Pour simplifier l’expérience et améliorer le score (= le temps de
              réalisation de la tâche), il suffit d’augmenter la taille des
              animaux et la surface de l'eau claire, et de rapprocher ces deux
              élements, comme c’est le cas dans le 2nd jeu.
            </p>
          </div>
        </v-col>
      </div>
    </template>

    <template v-slot:note>
      <div class="note">
        * Référence&#8239;: Fitts, Paul M. (1954): The Information Capacity of
        the Human Motor System in Controlling the Amplitude of Movement. In
        Journal of Experimental Psychology, 47 pp. 381-391
      </div>
    </template>
  </principle>
</template>

<script>
import Principle from './layout/PrinciplePage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PrinciplePractices from './layout/PrinciplePractices.vue';

export default {
  name: 'FittsPrinciple',
  props: {
    nextGame: String
  },
  components: {
    Principle,
    PrinciplePractices,
    FontAwesomeIcon
  },
  computed: {
    rightIcon() {
      return faCheck;
    },
    wrongIcon() {
      return faTimes;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './layout/principle-page-content.scss';

.arrow1 {
  position: absolute;
  width: 20px;
  top: 220px;
  left: 10px;
}
.arrow2 {
  position: absolute;
  width: 20px;
  height: 160px;
  top: 55px;
  right: 20px;
}

.buton-validation {
  margin-top: 10px;
  width: 100%;
}

.add-button {
  padding: 0 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;

  &--first {
    padding: 8px;
  }
}

.btn-secondary.disabled.add-button,
.btn-secondary:disabled.add-button {
  font-size: 0.9rem;
}
</style>
