<template>
  <div id="games" class="container-fluid home_zone_2">

    <v-dialog :activator="catchThemAll">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button type="button" class="close ml-auto" aria-label="Close" @click="isActive.value = false">
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <catch-them-all-rule></catch-them-all-rule>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog :activator="charade">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button type="button" class="close ml-auto" aria-label="Close" @click="isActive.value = false">
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <charade-rule></charade-rule>
        </v-card>
      </template>
    </v-dialog>


    <v-dialog :activator="cherry">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button type="button" class="close ml-auto" aria-label="Close" @click="isActive.value = false">
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <cherry-rule></cherry-rule>
        </v-card>
      </template>
    </v-dialog>


    <v-dialog :activator="pizzas">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button type="button" class="close ml-auto" aria-label="Close" @click="isActive.value = false">
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <pizzas-rule></pizzas-rule>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog :activator="poulpe">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button type="button" class="close ml-auto" aria-label="Close" @click="isActive.value = false">
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <poulpe-rule></poulpe-rule>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog :activator="onTheDouble">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button type="button" class="close ml-auto" aria-label="Close" @click="isActive.value = false">
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <on-the-double-rule-modal></on-the-double-rule-modal>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog :activator="quiz">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button type="button" class="close ml-auto" aria-label="Close" @click="isActive.value = false">
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <quiz-rule></quiz-rule>
        </v-card>
      </template>
    </v-dialog>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-10">
          <p>
            Les ergonomes de Sogilis vous proposent des petits jeux démontrant des principes d'ergonomie.<br><br>
            Chaque jeu est composé de 2 versions&nbsp;: l'une respecte le principe tandis que l'autre le transgresse.
            <br>Après avoir joué les 2 versions, le principe vous sera expliqué.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-4 pt-5">
          <button ref="catchThemAll" class="btn-jeux m-auto">
            <div class="btn-div">
              <p class="num-game"> Jeu 1</p>
              <center><img src="../../assets/img/poule.png" height=120 alt="jouer au jeu Attrapez-les tous"/></center>
              <p>Attrapez les tous&nbsp;!</p>
            </div>
          </button>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-4 pt-5">
          <button ref="charade" class="btn-jeux m-auto">
            <div class="btn-div">
              <p class="num-game"> Jeu 2</p>
              <center><img src="../../assets/img/tortue.png" height=120 alt="jouer au jeu Huuum charade"/></center>
              <p>Huuum Charade&nbsp;!</p>
            </div>
          </button>
        </div>


        <div class="col-sm-6 col-md-4 col-lg-4 pt-5">
          <button ref="cherry" class="btn-jeux m-auto">
            <div class="btn-div">
              <p class="num-game"> Jeu 3</p>
              <center><img class="img-btn-jeux" src="../../assets/img/souris.png" height=120
                           alt="jouer au jeu La cerise sur le gâteau"/></center>
              <p>La cerise sur le gâteau&nbsp;!</p>
            </div>
          </button>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-4 pt-5">
          <button ref="pizzas" class="btn-jeux m-auto">
            <div class="btn-div">
              <p class="num-game"> Jeu 4</p>
              <center><img src="@/assets/img/ico_bouton_jeu_3.png" alt="jouer au jeu À vous les pizzas !"/></center>
              <p>À vous les pizzas&nbsp;!</p>
            </div>
          </button>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-4 pt-5">
          <button ref="poulpe" class="btn-jeux m-auto">
            <div class="btn-div">
              <p class="num-game"> Jeu 5</p>
              <center><img src="@/assets/img/poulpe/poulpy.svg" alt="jouer au jeu Sauve qui peut !"/></center>
              <p>Sauve qui peut&nbsp;!</p>
            </div>
          </button>
        </div>

        <div class="col-sm-6 col-md-4 col-lg-4 pt-5">
          <button ref="onTheDouble" class="btn-jeux m-auto">
            <div class="btn-div">
              <p class="num-game"> Jeu 6</p>
              <center><img src="@/assets/img/onTheDouble/renard.svg" alt="Au pas de course !"/></center>
              <p>Au pas de course&nbsp;!</p>
            </div>
          </button>
        </div>

      </div>
    </div>
    <div class="container">
      <hr class="divider"/>
      <div class="row justify-content-center">
        <h3 class="title-quiz">
          J'ai tout compris, je me teste !
        </h3>
      </div>
      <button ref="quiz" class="btn-quiz">Quiz</button>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue'

const catchThemAll = ref(null)
const charade = ref(null)
const cherry = ref(null)
const pizzas = ref(null)
const poulpe = ref(null)
const onTheDouble = ref(null)
const quiz = ref(null)
</script>

<script>
import CatchThemAllRule from "../games/catchThemAll/CatchThemAllRule.vue";
import CharadeRule from "../games/charade/CharradeRule.vue";
import CherryRule from "../games/CherryOnTheCake/CherryRule.vue";
import PizzasRule from "@/views/games/pizzas/PizzasRule.vue";
import PoulpeRule from "@/views/games/poulpe/PoulpeRule.vue";
import onTheDoubleRuleModal from "@/views/games/onTheDouble/onTheDoubleRuleModal.vue"
import QuizRule from "@/views/Quiz/QuizRule.vue";

export default {
  components: {
    CatchThemAllRule,
    CharadeRule,
    CherryRule,
    PizzasRule,
    PoulpeRule,
    onTheDoubleRuleModal,
    QuizRule,
  },
};
</script>

<style lang="scss">
@import '../../assets/scss/colors';
@import '../../assets/scss/zones';
@import '../../assets/scss/buttons';
@import '../../assets/scss/main.scss';

.v-dialog {
  @extend %modal-dialog;
}

.rounded-dialog {
  border-radius: 15px !important;
  padding: 15px !important;
  @extend %modal-body;
}

.home_zone_2 {
  @extend %content-zone;
  padding-bottom: 100px;

  .container {
    padding-top: 50px;
    z-index: -2;

    .title_level_3 {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 20px;
    }

    .title_level_3::after {
      background-color: white;
    }

    p {
      text-align: center;
    }
  }

  .title-quiz {
    padding-bottom: 1.25rem;
  }

  .btn-quiz {
    @extend %action-button;
    background: white;
    color: black !important;
    text-transform: initial !important;

    &:hover {
      text-decoration: none;
      font-weight: bold;
      background: #ffce00;
      filter: none !important;
    }
  }

  .divider {
    background: white;
  }

  .btn-div {
    display: flex;
    flex-direction: column;
  }

  .btn-jeux {
    width: 240px;
    height: fit-content !important;
    text-transform: initial !important;
    display: block;
    @extend %content-button;

    &:hover {
      width: 230px;

      &::after {
        content: "";
        background-image: url("../../assets/img/ico_play.svg");
        display: block;
        position: absolute;
        top: 113%;
        left: 52%;
        transform: translate(-50%, -50%);
        width: 48px;
        height: 57px;
        animation: arrive 0.5s forwards;
        border: none
      }
    }

    img {
      margin-bottom: 7px;
      height: 110px
    }
  }

  .num-game {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 0.9rem;
  }

  @keyframes arrive {
    0% {
      opacity: 0;
      margin-top: -100px;
    }

    100% {
      opacity: 1;
      margin-top: -133px;
    }
  }
}
</style>
