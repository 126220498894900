<template>
  <div class="row content justify-content-center m-auto">
    <h1>Attrapez les tous&nbsp;!</h1>
    <p>
      <b>Aide Rosalyne à retrouver ses œufs...</b> Rosalyne a perdu ses 10 œufs au milieu de ceux de ses sœurettes&nbsp;!
    </p>
    <p class="bold">Signe distinctif&nbsp;: ils ont tous un chapeau.</p>
    <p>Clique sur un œuf pour l'attraper. Tu as 10 secondes pour ramasser les 10 œufs de Rosalyne. Attention toutefois à
      ne pas kidnapper les œufs de ses sœurettes&nbsp;!</p>
    <v-row class="mt-1">
      <v-col class="text-center">
        <img src="../../../assets/img/rule-chapeau.png" alt=""/>
      </v-col>
      <v-col>
        <p>À toi de jouer&nbsp;! Prêt, feu, partez&nbsp;! </p>
        <button @click="play" class="primary-btn">Jouer</button>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "CatchThemAllRule",
  methods: {
    play() {
      this.$router.push("/game/catch-them-all");
    }
  },
};
</script>

<style lang="scss" >
@import '../../../assets/scss/main.scss';
@import '../../../assets/scss/buttons.scss';

.primary-btn {
  @extend %action-button;
}
</style>
