export const STEP_ROLL_OUT_DOUGH = 1;
export const STEP_GRAB_PESTO_AND_MUSHR = 2;
export const STEP_SPREAD_PESTO_AND_MUSHR = 3;
export const STEP_GRAB_PIZZA_1 = 4;
export const STEP_PUT_PIZZA_IN_OVEN = 5;
export const STEP_BAKE_PIZZA = 6;
export const STEP_GRAB_PIZZA_2 = 7;
export const STEP_DELIVER_PIZZA = 8;
export const STEP_FINISH = 9;
export const labels = [
  { id: STEP_ROLL_OUT_DOUGH, label: 'Étaler la pâte avec les doigts' },
  { id: STEP_GRAB_PESTO_AND_MUSHR, label: 'Aller chercher tous les ingrédients (pesto et champignons)' },
  { id: STEP_SPREAD_PESTO_AND_MUSHR, label: 'Disposez les ingrédients sur la pâte' },
  { id: STEP_GRAB_PIZZA_1, label: 'Prendre la pizza' },
  { id: STEP_PUT_PIZZA_IN_OVEN, label: 'Mettre la pizza au four' },
  { id: STEP_BAKE_PIZZA, label: 'Cuire 5 secondes' },
  { id: STEP_GRAB_PIZZA_2, label: 'Prendre la pizza' },
  { id: STEP_DELIVER_PIZZA, label: 'Emporter la pizza jusqu’au passe-plat' },
  { id: STEP_FINISH },
]
