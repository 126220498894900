<template>
  <div>
    <div class="principle-page">
      <v-dialog activator="#nextGameRef">
        <template v-slot:default="{ isActive }">
          <v-card class="rounded-dialog">
            <button
              type="button"
              class="close ml-auto"
              aria-label="Close"
              @click="isActive.value = false"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <template v-if="nextGame == 'catchThemAll'">
              <catch-them-all-rule></catch-them-all-rule>
            </template>
            <template v-else-if="nextGame == 'charade'">
              <charade-rule></charade-rule>
            </template>
            <template v-else-if="nextGame == 'CherryOnTheCake'">
              <cherry-rule></cherry-rule>
            </template>
            <template v-else-if="nextGame == 'pizzas'">
              <pizzas-rule></pizzas-rule>
            </template>
            <template v-else-if="nextGame == 'poulpe'">
              <poulpe-rule></poulpe-rule>
            </template>
            <template v-else-if="nextGame == 'onTheDouble'">
              <on-the-double-rule-modal></on-the-double-rule-modal>
            </template>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog activator="#gameRef">
        <template v-slot:default="{ isActive }">
          <v-card class="rounded-dialog">
            <button
              type="button"
              class="close ml-auto"
              aria-label="Close"
              @click="isActive.value = false"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <template v-if="game == 'catchThemAll'">
              <catch-them-all-rule></catch-them-all-rule>
            </template>
            <template v-else-if="game == 'charade'">
              <charade-rule></charade-rule>
            </template>
            <template v-else-if="game == 'CherryOnTheCake'">
              <cherry-rule></cherry-rule>
            </template>
            <template v-else-if="game == 'pizzas'">
              <pizzas-rule></pizzas-rule>
            </template>
            <template v-else-if="game == 'poulpe'">
              <poulpe-rule></poulpe-rule>
            </template>
            <template v-else-if="game == 'onTheDouble'">
              <on-the-double-rule-modal></on-the-double-rule-modal>
            </template>
          </v-card>
        </template>
      </v-dialog>

      <!-- APP HEADER -->
      <home-header></home-header>
      <!-- PAGE HEADER -->
      <v-container class="principle-page__header">
        <button @click="back" class="principle-page__header__back-link">
          <font-awesome-icon :icon="angleLeftIcon" />
          Liste des principes
        </button>
        <span class="principle-page__header__title">{{ principle }}</span>
      </v-container>

      <!-- NOT PLAYED YET BANNER -->
      <div class="principle-page__banner">
        <div
          class="d-flex justify-content-end py-0 align-items-center principle-page__banner__content"
        >
          <p class="principle-page__banner__text">
            Pas encore testé le jeu illustrant ce principe&nbsp;?
          </p>
          <button class="game-btn game-btn__alternate" id="gameRef">
            Jouer au jeu<i class="game-btn__icon"></i>
          </button>
        </div>
      </div>

      <!-- CONTENT -->
      <div class="principle-page__content">
        <!-- MAIN CONTENT INSERTION VIA SLOT -->
        <slot name="main-content"></slot>
        <!-- GAME EXPLANATION -->
        <div class="principle-page__game-explanation">
          <button
            @click="toggleExplanation"
            class="principle-page__game-explanation__toggle-btn"
          >
            <span v-if="isExplanationVisible">
              <font-awesome-icon :icon="angleUpIcon" class="mr-1" /> Cacher
            </span>
            <span v-else>
              <font-awesome-icon :icon="angleDownIcon" class="mr-1" /> Voir
            </span>
            l&apos;explication du jeu
          </button>
          <v-expand-transition>
            <v-card
              class="principle-page__game-explanation__content"
              v-show="isExplanationVisible"
            >
              <slot name="game-explanation"></slot>
            </v-card>
          </v-expand-transition>
          <p class="end-explanation"></p>
        </div>
        <!-- NOTE -->
        <slot name="note"></slot>
        <div class="d-flex justify-content-end">
          <button v-if="nextGame" class="game-btn" id="nextGameRef">
            Jouer au jeu suivant <i class="game-btn__icon"></i>
          </button>
          <router-link v-else class="game-btn" to="/#games"
            >Retourner aux jeux</router-link
          >
        </div>
      </div>
      <div class="footer">
        <v-container
          class="tell-us d-flex flex-row justify-content-center align-items-center"
        >
          <font-awesome-icon :icon="twitter" size="2x" class="mr-2" />
          <div>
            Utilisez
            <a
              href="https://twitter.com/hashtag/ergogames"
              target="_blank"
              rel="noopener noreferrer"
              ><b>#Ergogames</b></a
            >
            sur Twitter pour nous raconter vos expériences avec ce principe
            d'ergonomie&nbsp;!
          </div>
        </v-container>
      </div>
    </div>
    <main role="main">
      <router-view />
      <credits></credits>
    </main>
  </div>
</template>

<script>
import Credits from '../../CreditsFooter.vue';
import CatchThemAllRule from '../../games/catchThemAll/CatchThemAllRule.vue';
import CharadeRule from '../../games/charade/CharradeRule.vue';
import CherryRule from '../../games/CherryOnTheCake/CherryRule.vue';
import PizzasRule from '../../games/pizzas/PizzasRule.vue';
import PoulpeRule from '../../games/poulpe/PoulpeRule.vue';
import OnTheDoubleRuleModal from '../../games/onTheDouble/onTheDoubleRuleModal.vue';
import HomeHeader from '../../AppHeader.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import {
  faAngleLeft,
  faAngleUp,
  faAngleDown
} from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'PrinciplePage',
  props: {
    principle: String,
    game: String,
    nextGame: String,
    gameName: String
  },
  components: {
    HomeHeader,
    Credits,
    CatchThemAllRule,
    CharadeRule,
    CherryRule,
    PizzasRule,
    PoulpeRule,
    OnTheDoubleRuleModal,
    FontAwesomeIcon
  },
  data() {
    return {
      isExplanationVisible: false,
      twitter: faTwitterSquare,
      angleLeftIcon: faAngleLeft,
      angleUpIcon: faAngleUp,
      angleDownIcon: faAngleDown
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  methods: {
    toggleExplanation() {
      this.isExplanationVisible = !this.isExplanationVisible;
      const explanation = document.querySelector('.end-explanation');
      if (this.isExplanationVisible && explanation) {
        const navbarPadding = 80;
        const headerPadding = 140;
        const positionToScrollTo =
          explanation.offsetTop - navbarPadding - headerPadding;
        setTimeout(() => {
          window.scrollTo({ top: positionToScrollTo, behavior: 'smooth' });
        }, 500);
      }
    },
    back() {
      this.$router.push('/principles');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/colors';
@import '../../../assets/scss/buttons';
@import '../../../assets/scss/zones';

.principle-page {
  --content-width: 58rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1rem;
    max-width: var(--content-width);

    &__back-link {
      color: $neutral-grey;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-right: 2rem;

      &:hover {
        text-decoration: underline;
      }
    }

    &__title {
      color: $dark-grey;
      font-weight: 700;
      font-size: 2rem;

      @media screen and (max-width: 575px) {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }

  &__banner {
    background-color: #004180;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
      max-width: var(--content-width);
      width: 100%;
      padding: 1rem;
    }

    &__text {
      color: white;
      margin: 0;
    }

    @media screen and (max-width: 650px) {
      font-size: 0.9rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 0.7rem;
    }
  }

  &__content {
    max-width: var(--content-width);
    padding: 2rem 1rem;
  }

  &__game-explanation {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__toggle-btn {
      text-decoration: none;
      color: white;
      border-color: transparent;
      padding: 6px 12px;
      margin-bottom: 20px;
      background-color: #004180;
      border-radius: 50px;

      &:hover {
        font-weight: 500;
      }

      &:focus {
        border-color: transparent;
        outline: dotted currentColor 1px !important;
      }

      &.collapsed > .when-opened,
      &:not(.collapsed) > .when-closed {
        display: none;
      }
    }

    &__content {
      padding: 2rem;
      border: 2px solid #dfdfdf;
      box-shadow: none;
    }
  }
}

.game-btn {
  @extend %action-button;
  min-width: 155px;
  display: inline-block;
  margin: 0 0 0 1rem;

  &__icon {
    border: solid white;
    display: inline-block;
    padding: 3px;
    margin-left: 1rem;
    border-width: 0 3px 3px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &__alternate {
    background-color: $neutral-yellow;
    color: #004180;

    .game-btn__icon {
      border: solid #004180;
      border-width: 0 3px 3px 0;
    }

    &:hover {
      color: #004180;
    }
  }
}

.footer {
  width: 100%;
  background-color: $neutral-blue;
  color: white;

  a {
    color: white;

    &:hover,
    &:focus {
      color: white;
    }
  }

  .tell-us {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    svg {
      margin: 0;
    }
  }
}
</style>
