<template>
  <game-container
    ref="gameContainer"
    :class="{ 'game-content-background': !inShop }"
  >
    <template v-slot:header>
      <v-col>
        <v-row class="game-header">
          <v-col class="header__col">
            <div class="col__content">
              Panier
              <font-awesome-icon :icon="faShoppingCart"></font-awesome-icon>
              <div v-if="this.itemInCart > 0" class="header__items-found">
                {{ itemInCart }}
              </div>
              <div v-else class="header__items-found--empty"></div>
            </div>
          </v-col>
          <v-col class="header__col header__col--timer">
            <div class="col__content">
              <font-awesome-icon :icon="faClock"></font-awesome-icon>
              {{ clock() }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </template>

    <template>
      <div>
        <v-dialog v-model="dialog" persistent>
          <v-card class="rounded-dialog">
            <on-the-double-result-modal
              :round="this.round"
              :lastRound="this.lastRound"
              :replay="this.replay"
              :gameAlternative="this.goToAlternativeGame"
              :principle="this.goToPrinciplePage"
              :timeRoundOne="this.chronoRoundOne"
              :timeCurrentRound="this.chronoRoundTwo"
            ></on-the-double-result-modal>
          </v-card>
        </v-dialog>
      </div>
    </template>

    <div class="game-section" :class="{ 'game-section-background': !inShop }">
      <div
        class="shopping-list-wrapper"
        :class="{ 'shopping-list-wrapper--in-shop': inShop }"
      >
        <shopping-list
          :ingredientsList="ingredientsList"
          :dessertsList="dessertsList"
          :inShop="inShop"
          @next-step="nextStep"
        ></shopping-list>

        <shopping-images-list
          v-show="inShop"
          :items-to-show="itemsToShow"
          transition="scroll-y-transition"
        ></shopping-images-list>
      </div>

      <on-the-double-shopping-ingredients
        v-if="(round === 1 || round === 3) && inShop"
        :round="round"
        :validItemsSelected="validItemsSelected()"
        @item-found="onItemFound"
        @next-step="nextStep"
      >
        <template v-slot:button>
          <button @click="nextStep" class="primary-btn primary-btn--switch">
            Aller au rayon dessert
          </button>
        </template>
      </on-the-double-shopping-ingredients>

      <on-the-double-shopping-desserts
        v-else-if="round === 2"
        @item-found="onItemFound"
        :valid-items-selected="validItemsSelected()"
      >
        <template v-slot:button>
          <button @click="nextStep" class="primary-btn primary-btn--switch">
            Passer à la caisse
          </button>
        </template>
      </on-the-double-shopping-desserts>

      <on-the-double-shopping-sorted-desserts
        v-else-if="round === 4"
        @item-found="onItemFound"
        :valid-items-selected="validItemsSelected()"
      >
        <template v-slot:button>
          <button @click="nextStep" class="primary-btn primary-btn--switch">
            Passer à la caisse
          </button>
        </template>
      </on-the-double-shopping-sorted-desserts>

      <div class="empty-department" v-else></div>
    </div>
  </game-container>
</template>

<script>
import GameContainer from '@/views/games/GameContainer.vue';
import OnTheDoubleResultModal from './onTheDoubleResultModal.vue';
import ShoppingList from '@/views/games/onTheDouble/shoppingList.vue';
import OnTheDoubleShoppingIngredients from '@/views/games/onTheDouble/onTheDoubleShoppingIngredients.vue';
import OnTheDoubleShoppingDesserts from '@/views/games/onTheDouble/onTheDoubleShoppingDesserts.vue';
import OnTheDoubleShoppingSortedDesserts from '@/views/games/onTheDouble/onTheDoubleShoppingSortedDesserts.vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faClock, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import ShoppingImagesList from './shoppingImagesList.vue';

export default {
  name: 'onTheDoubleGame',

  components: {
    ShoppingImagesList,
    FontAwesomeIcon,
    GameContainer,
    OnTheDoubleResultModal,
    ShoppingList,
    OnTheDoubleShoppingIngredients,
    OnTheDoubleShoppingDesserts,
    OnTheDoubleShoppingSortedDesserts
  },

  data() {
    return {
      faClock,
      faShoppingCart,
      round: 1,
      lastRound: 0,
      inShop: false,
      itemsFound: 0,
      itemInCart: 0,
      dialog: false,
      ticks: 0,
      isChronoRun: false,
      chronoRoundOne: 0,
      chronoRoundTwo: 0,
      ingredientsList: [
        {
          nameDisplay: 'de la sauce tomate',
          name: 'Tomate',
          scratch: false
        },
        {
          nameDisplay: 'de la farine',
          name: 'Farine',
          scratch: false
        },
        {
          nameDisplay: 'de la mozarella',
          name: 'Mozzarella',
          scratch: false
        },
        {
          nameDisplay: 'des champignons',
          name: 'Champignon',
          scratch: false
        }
      ],
      dessertsList: [
        {
          nameDisplay: 'un dessert à moins de 5€',
          name: 'Donuts-6',
          scratch: false
        }
      ],
      itemsToShow: []
    };
  },

  methods: {
    nextStep() {
      switch (this.round) {
        case 1:
        case 3:
          if (this.inShop) {
            this.lastRound = this.round;
            this.round++;
            this.itemsFound = 0;
            this.isChronoRun = true;
          } else {
            this.inShop = true;
            this.isChronoRun = true;
          }
          break;
        default:
          if (this.round === 2) {
            this.chronoRoundOne = this.ticks;
          } else if (this.round === 4) {
            this.chronoRoundTwo = this.ticks;
          }
          this.dialog = true;
          this.lastRound = this.round - 1;
          break;
      }
    },
    replay() {
      this.reset();
      switch (this.round) {
        case 4:
          this.round--;
          this.lastRound = 4;
          break;
        default:
          this.round = 1;
          this.lastRound = 2;
          break;
      }
    },
    goToAlternativeGame() {
      this.reset();
      this.round = 3;
      this.lastRound = 2;
    },
    reset() {
      this.dialog = false;
      this.itemsFound = 0;
      this.itemInCart = 0;
      this.inShop = false;
      this.itemsToShow = [];
      this.unScratchListItems();
      this.resetChrono();
    },
    goToPrinciplePage() {
      this.$router.push('/principles/proximity-Law');
    },
    onTick() {
      if (this.isChronoRun) this.ticks++;
    },
    resetChrono() {
      this.ticks = 0;
      this.isChronoRun = false;
    },
    clock() {
      const minutes = Math.floor(this.ticks / 60).toLocaleString('fr-FR', {
        minimumIntegerDigits: 2
      });
      const seconds = (this.ticks % 60).toLocaleString('fr-FR', {
        minimumIntegerDigits: 2
      });
      return `${minutes}:${seconds}`;
    },
    onItemFound(item) {
      this.itemsFound++;
      this.itemInCart++;

      const listItem = [...this.ingredientsList, ...this.dessertsList].find(
        i => i.name === item.name
      );
      if (listItem) {
        listItem.scratch = true;
        this.itemsToShow.push(item.name);
      }
    },
    unScratchListItems() {
      this.ingredientsList.map(i => {
        i.scratch = false;
        return i;
      });
      this.dessertsList.map(i => {
        i.scratch = false;
        return i;
      });
    },
    validItemsSelected() {
      switch (this.round) {
        case 1:
        case 3:
          if (this.itemsFound === 4) {
            this.isChronoRun = false;
            return true;
          }
          break;
        case 2:
        case 4:
          if (this.itemsFound === 1) {
            this.isChronoRun = false;
            return true;
          }
          break;
      }
      return 0;
    }
  },
  mounted() {
    this.tickInterval = setInterval(this.onTick, 1000);
  }
};
</script>

<style lang="scss" scoped>
.game-header {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.header__col {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  text-align: left;
  max-width: 173px;

  @media (max-width: 575px) {
    max-width: 126px;
    padding: 12px 0;
  }

  &--timer {
    max-width: 123px;
    margin-right: 20px;

    @media (max-width: 575px) {
      max-width: 100px;
      margin-right: 20px;
    }
  }
}

.col__content {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0 2px;
}

.header__items-found {
  background-color: #f14040;
  border-radius: 50%;
  min-width: 30px;
  text-align: center;

  &--empty {
    min-width: 30px;
  }
}

.game-section {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  gap: 100px;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 30px;

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
  }

  @media (max-width: 575px) {
    margin: 0;
    gap: 0;
  }
}

.shopping-list-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  gap: 50px;
  transition: ease-out 1s;

  @media (min-width: 2560px) {
    gap: 320px;
  }
  @media (max-width: 2560px) and (min-width: 1920px) {
    gap: 200px;
  }

  @media (max-width: 575px) {
    margin-top: 55%;
  }

  &--in-shop {
    @media (max-width: 575px) {
      margin-top: 5%;
    }
  }
}

.empty-department {
  width: 600px;

  @media (min-width: 2560px) {
    width: 900px;
  }

  @media (max-width: 2560px) and (min-width: 1920px) {
    width: 750px;
  }
}

.primary-btn--switch {
  background: #ffce00;
  color: #0a5e5c;
  width: 230px;
}

.game-content-background {
  background-image: url('../../../assets/img/onTheDouble/desktopBackGround.svg');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #cdf6fb;

  @media (max-width: 600px) {
    background-image: url('../../../assets/img/onTheDouble/mobileBackGroundShop.svg');
    background-size: 100%;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}

.game-section-background {
  @media (max-width: 600px) {
    background-image: url('../../../assets/img/onTheDouble/mobileBackGroundPizza.svg');
    background-size: 100%;
    background-position: top;
    background-repeat: no-repeat;
  }
}
</style>
