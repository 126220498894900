<template>
  <div class="fullscreen">
      <v-dialog ref="gameEnd" v-on:hidden="hideGameEnd" centered hide-footer hide-header no-fade no-close-on-backdrop no-close-on-esc>
        <slot name="result" />
      </v-dialog>

      <div class="game-counter">
        <div class="header-jeu">
          <div class="text-left header-btn">
            <button type="button" class="btn back-link" aria-label="Close" @click="back()">
              Retour
            </button>
          </div>
          <slot name="header"/>
        </div>
      </div>

      <div class="game-content" id="main-content">
        <slot></slot>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    resultHidden: Function,
  },
  methods: {
    hideGameEnd() {
      return this.resultHidden && this.resultHidden();
    },
    showResult() {
      this.$refs.gameEnd.show();
    },
    hideResult() {
      this.$refs.gameEnd.hide();
    },
    back() {
      if (this.$route.name === 'Quiz') {
        this.$router.push({ name: 'home' });
      } else {
        window.history.back();
      }
    }
  }
};
</script>


<style lang="scss">
@import "../../assets/scss/colors.scss";
.fullscreen {
  width: 100%;
  height: 100%;
}
.header-jeu {
  width: 100%;
  margin: 0 !important;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
  position: fixed;
  background-color: $neutral-blue;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.game-counter {
  position:fixed;
  min-height: 80px;
  z-index: 1000;
  background-color: $neutral-blue;
  padding-bottom: 10px;
  box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
  font-weight: 500;
  color: white;
  font-size: 1.4em;
  @media screen and (max-width: 576px) {
    font-size: 1.2em;
  }

  .back-link {
    color: $ultra-light-grey;
    font-weight: 400;
    background-color: transparent;
    &:hover {
      text-decoration: underline;
      color: $ultra-light-grey;
    }
  }
}

.game-content{
  height: 100%;
  padding-top: 60px;
  z-index:100;
}

.header-btn {
  padding: 12px;
  @media screen and (max-width: 576px) {
    width: fit-content !important;
    flex: 0 0 0 !important;
  }
}
</style>
