export default {
  oven: {
    id: 'oven',
    size: { width: 2, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/four.svg'),
  },

  fridge: {
    id: 'fridge',
    size: { width: 2, height: 2 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/refrigirateur.svg'),
  },

  workplan: {
    id: 'workplan',
    size: { width: 1, height: 2 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/plan-travail.svg'),
  },

  shelf: {
    id: 'shelf',
    size: { width: 4, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/etagere-horizontale.svg'),
  },
hatch: {
    id: 'hatch',
    size: { width: 1, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/passeplat.svg'),
  },
  pizza: {
    id: 'pizza',
    size: { width: 1, height: 1 },
    obstruct: true,
    look: 'doughBall',
    assets: {
      doughBall: require('@/assets/img/pizzas/pate-pizza-boule.png'),
      dough: require('@/assets/img/pizzas/pate-pizza.svg'),
      tomato: require('@/assets/img/pizzas/pate-tomate.svg'),
      mozza: require('@/assets/img/pizzas/pizza.svg'),
      profile: require('@/assets/img/pizzas/pizza-plat.svg'),
    },
  },

  mozza: {
    id: 'mozza',
    size: { width: 1, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/pot-mozzarela.svg'),
  },

  tomato: {
    id: 'tomato',
    size: { width: 1, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/sauce-tomate.svg'),
  },

  meiko: {
    id: 'meiko',
    size: { width: 1, height: 1 },
    obstruct: true,
    direction: 'below',
    look: 'normal',
    assets: {
      normal: {
        below: require('@/assets/img/pizzas/meiko-face.svg'),
        right: require('@/assets/img/pizzas/meiko-droite.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
      mozza: {
        below: require('@/assets/img/pizzas/meiko-face-mozza.svg'),
        right: require('@/assets/img/pizzas/meiko-droite-mozza.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche-mozza.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
      sauce: {
        below: require('@/assets/img/pizzas/meiko-face-sauce.svg'),
        right: require('@/assets/img/pizzas/meiko-droite-sauce.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche-sauce.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
      pizza: {
        below: require('@/assets/img/pizzas/meiko-face-pizza.svg'),
        right: require('@/assets/img/pizzas/meiko-droite-pizza.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche-pizza.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
    }
  },
};
