<template>
  <div>
    <div class="row content justify-content-center m-auto" v-if="!isAlternative">
      <div v-if="this.wasSuccess && (this.round === 6 || this.round === 0)">
        <h1>Bravo&nbsp;!</h1>
        <h2>Il t'a fallu {{ score }} pour détecter tous les gâteaux à la cerise.</h2>
        <p class="margin-bot">
          Dina est ravie, elle mange son goûter calmement&nbsp;!
          <br/>Elle aime tellement ces gâteaux qu'elle a sauté sur une bonne affaire sur Souris-Privée, un autre lot de 6 paquets de gâteaux.
          <br/>Tu veux bien l'aider à trier ce second lot&nbsp;?
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Rejouer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="next" class="primary-btn">{{nextButtonText()}}</button>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <h1>Ouch&nbsp;!</h1>
        <h2>Il t'a fallu {{ score }} pour détecter tous les gâteaux à la cerise.</h2>
        <p>
          Dina ne s'est pas empoisonnée, mais elle trépigne pour obtenir son goûter...
          <br/>Si tu le souhaites, tu peux réessayer avant de passer à la suite.
        </p>
        <p class="margin-bot">
          Malgré cela, Dina aime tellement ces gâteaux qu'elle a sauté sur une bonne affaire sur Souris-Privée, un autre lot de 6 paquets de gâteaux.
          <br/>Tu veux bien l'aider à trier ce second lot&nbsp;?
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Rejouer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="next" class="primary-btn">{{nextButtonText()}}</button>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="row content justify-content-center m-auto" v-else>
      <div v-if="this.isSecondStepSuccess()">
        <h1>Bravo&nbsp;!</h1>
        <h2>Il t'a fallu {{ score }} pour détecter tous les gâteaux à la cerise.</h2>
        <p class="margin-bot">
          Dina est ravie, elle mange son goûter calmement&nbsp;!
          <br />Découvre maintenant le principe d'ergonomie qui se cache derrière.
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Rejouer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="next" class="primary-btn">{{nextButtonText()}}</button>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <h1>Ouch&nbsp;!</h1>
        <h2>Il t'a fallu {{ score }} pour détecter tous les gâteaux à la cerise.</h2>
        <p class="margin-bot">
          Dina ne s'est pas empoisonnée, mais elle trépigne pour avoir son goûter...
          <br />Découvre maintenant le principe d'ergonomie qui se cache derrière.
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Rejouer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="next" class="primary-btn">{{nextButtonText()}}</button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CherryResult",
  props: {
    alternative: Boolean,
    score: String,
    replay: Function,
    gameAlternative: Function,
    principle: Function,
    ticks: Number,
    isBetterScore: Boolean,
    wasSuccess: Boolean,
    round: Number,
    lastRound: Number
  },

  data() {
    return {
      isAlternative: null,
    };
  },

  mounted() {
    this.isAlternative = this.alternative;
  },

  methods: {
    isSecondStepSuccess() {
      return this.isBetterScore && this.wasSuccess;
    },
    next() {
      if (this.alternative && this.round === 6) {
        this.principle();
      } else {
        this.gameAlternative();
      }
    },
    nextButtonText() {
      if (this.alternative && this.round === 6) {
        return "Voir le principe";
      } else {
        return "Trier le 2nd lot";
      }
    },
  },
};
</script>

<style scoped>
.margin-bot {
  margin-bottom: 40px !important;
}
</style>