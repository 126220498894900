<template>
  <div>
    <div class="row content justify-content-center m-auto">
      <h1 class="m-0">La cerise sur le gâteau&nbsp;!</h1>
    </div>
    <div class="content m-auto pt-0">
      <p>
        <b>Aide Dina qui est gourmande... </b> mais allergique aux cerises&nbsp;! 
      </p>
      <p>
        Il y a 6 paquets gâteaux à inspecter.  <b> Clique sur les gâteaux à la cerise pour les retirer du lot. </b> 
      </p>
      <p>
        Ouvre l'oeil et sois rapide, car Dina a très très faim...
        Bonne chance&nbsp;!
      </p>
      <v-row class=" text-center">
        <v-col class="text-center">
          <img src="../../../assets/img/rule-cherry.png" alt="" height=100/>
        </v-col>
        <v-col class="texte">
          <p>À toi de jouer&nbsp;! Prêt, feu, partez&nbsp;!</p>
          <button @click="play" class="primary-btn">Jouer</button>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "cherryRule",
  methods: {
    play() {
      this.$router.push("/game/CherryOnTheCake");
    }
  },
};
</script>
<style lang="scss" scoped>
.illust{
  margin: 1.25rem auto auto;
}

</style>