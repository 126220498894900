<template>
  <game-container
    ref="container"
    class="backgroundpoulpe"
    :resultHidden="undefined"
  >
    <template>
      <div>
        <v-dialog v-model="dialog" persistent>
          <v-card class="rounded-dialog">
            <poulpe-result
              :round="this.round"
              :timeRoundOne="this.timeRoundOne"
              :timeCurrentRound="this.ticks"
              :replay="this.replay"
              :nextRound="this.nextRound"
            ></poulpe-result>
          </v-card>
        </v-dialog>
      </div>
    </template>   
    <template v-slot:header>
      <v-col>
        <v-row class="header-row">
          <v-col class="animalsCount">
            {{ 11 - displayedAnimalId }} animaux à sauver</v-col>
          <v-col class="animalsCount animalsCount--timer">
            <font-awesome-icon :icon="faClock"></font-awesome-icon>
            {{ clock }}
          </v-col>
        </v-row>
      </v-col>
    </template>
    <v-container v-if="imagesLoaded" class="game-layout" :style="{height: gameLayoutHeight}">
      <div :class="`rescue-area round${this.round}`">
        <img id="coraux-img" :src="this.coraux[0].src" />
        <div id="drop-zone" />
        <img
          v-if="animalSaved"
          id="animal-saved-img"
          :class="`animal round${this.round}`"
          :src="getPreviouslySavedAnimalSrc()"
        />
      </div>
      <img
        :class="`rubbish pneu round${this.round}`"
        :src="getRubbish('pneu').src"
        :style="getRubbishStyle('pneu')"
      />
      <img
        :class="`rubbish round${this.round}`"
        :src="getRubbish('sac').src"
        :style="getRubbishStyle('sac')"
      />
      <img
        :class="`rubbish round${this.round}`"
        :src="getRubbish('bouteille').src"
        :style="getRubbishStyle('bouteille')"
      />
      <div
        v-if="!allAnimalsSaved"
        id="animal-in-trouble"
        :class="`animal round${this.round}`"
        :style="getDisplayedAnimalStyle()"
      >
        <div id="animal-halo" :class="`round${this.round}`"></div>
        <img id="animal-img" :src="getDisplayedAnimalSrc()" />
        <div id="game-instruction" v-if="round == 1 && displayedAnimalId == 1 && chronoStarted == false">Fais moi glisser jusqu'à l'eau claire pour me sauver</div>
      </div>
    </v-container>
    <v-container
      class="d-flex justify-content-center align-items-center spinner-container"
      v-else
    >
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </v-container>
  </game-container>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

import interact from "interactjs";

import GameContainer from "../GameContainer.vue";
import PoulpeResult from "./PoulpeResult.vue";

import animals from "./animals";
import rubbish from "./rubbish";

export default {
  name: "Poulpe-Game",
  components: {
    GameContainer,
    PoulpeResult,
    FontAwesomeIcon,
  },
  data() {
    return {
      gameEnded: false,
      imagesCache: {},
      imagesLoaded: false,
      imagesLoadedCount: 0,
      faClock,
      round: 1,
      timeRoundOne: 10, // on initialise le temps mis au round 1 à 10 secondes
      chronoStarted: false,
      ticks: 0,
      displayedAnimalId: 1,
      animalSaved: false, // flag pour l'affichage de l'animal sauvé
      previouslySavedAnimalId: 0,
      animalTranslatePosition: { x: 0, y: 0 },
      animals: animals,
      rubbish: rubbish,
      coraux: [{ src: require(`@/assets/img/poulpe/coraux.svg`) }],
      window: { height: 0 },
      dialog: false,
    };
  },
  methods: {
    cacheImage(src) {
      if (!this.imagesCache[src]) {
        const image = new Image();
        image.src = src;
        this.imagesCache[src] = image;
        image.onload = () => {
          this.imagesLoadedCount++;
          this.imagesLoaded =
            this.imagesLoadedCount === [...this.images].length;
        };
      }
    },
    previousAnimal() {
      this.displayedAnimalId--;
    },
    nextAnimal() {
      this.resetAnimalPosition();

      this.previouslySavedAnimalId = this.displayedAnimalId;
      this.displayedAnimalId++;
    },
    resetAnimalPosition() {
      document.getElementById("animal-in-trouble").style.transform = "none";
      this.animalTranslatePosition = { x: 0, y: 0 };
    },
    displaySavedAnimal() {
      // on affiche l'animal pendant une seconde (1000 ms) puis on le fait disparaitre
      this.animalSaved = true;
      setTimeout(() => (this.animalSaved = false), 1000);
    },
    getDisplayedAnimal() {
      return this.animals.find((animal) => animal.id == this.displayedAnimalId);
    },
    getDisplayedAnimalSrc() {
      const animal = this.getDisplayedAnimal()[`round${this.round}`];
      return animal.src.triste;
    },
    getPreviouslySavedAnimalSrc() {
      const previousAnimal = this.animals.find(
        (animal) => animal.id == this.previouslySavedAnimalId
      )[`round${this.round}`];
      return previousAnimal.src.heureux;
    },
    getDisplayedAnimalStyle() {
      const animal = this.getDisplayedAnimal()[`round${this.round}`];
      const top = animal.initialPosition.y;
      const left = animal.initialPosition.x;
      return `
        top: ${top}%;
        left: ${left}%;
      `;
    },
    getRubbish(rubbishId) {
      return this.rubbish.find((rubbish) => rubbish.id == rubbishId);
    },
    getRubbishStyle(rubbishId) {
      const rubbish = this.getRubbish(rubbishId);
      const top = rubbish.position[`round${this.round}`].y;
      const right = rubbish.position[`round${this.round}`].x;
      return `
        top: ${top}%;
        right: ${right}%;
      `;
    },
    onAnimalDragStart() {
      this.chronoStarted = true;
    },
    onAnimalMove(event) {
      this.animalTranslatePosition.x += event.dx;
      this.animalTranslatePosition.y += event.dy;

      event.target.style.transform = `translate(${this.animalTranslatePosition.x}px, ${this.animalTranslatePosition.y}px)`;
    },
    onAnimalDropped() {
      this.nextAnimal();
      this.displaySavedAnimal();

      if (this.displayedAnimalId === 11) {
        this.gameEnded = true;
        this.dialog = true;
      }
    },
    onTick() {
      // Toutes les secondes, on incrémente la valeur du chrono (variable ticks), lorsque :
      //   - le jeu n'est pas terminé,
      //   - et que le top départ a déjà été déclenché (via onAnimalDragStart).
      if (!this.gameEnded && this.chronoStarted) {
        this.ticks++;
      }
    },
    replay() {
      this.dialog = false;
      this.resetChrono();
      this.displayedAnimalId = 1;
      this.gameEnded = false;
    },
    nextRound() {
      this.timeRoundOne = this.ticks;
      this.replay();
      this.round = 2;
    },
    resetChrono() {
      this.chronoStarted = false;
      this.ticks = 0;
    },
    handleResize() {
      this.window.height = window.innerHeight;
    }
  },
  computed: {
    images() {
      return [
        ...this.animals.map((animal) => animal.round1.src.triste),
        ...this.animals.map((animal) => animal.round1.src.heureux),
        ...this.animals.map((animal) => animal.round2.src.triste),
        ...this.animals.map((animal) => animal.round2.src.heureux),
        ...this.rubbish.map((rubbish) => rubbish.src),
        ...this.coraux.map((coraux) => coraux.src),
      ];
    },
    clock() {
      // Tu divises le nombre de secondes par 60 ce qui donne le nombre de minutes.
      // Bien penser à tronquer, car le reste de la division correspond aux secondes à afficher (c'est la ligne qui suit)
      // Le toLocaleString permet juste de s'assurer d'avoir les chiffres affichés toujours par 2
      // même lorsque inférieur à 10 (donc on a 01:09 au lieu de 1:9)
      const minutes = Math.floor(this.ticks / 60).toLocaleString("fr-FR", {
        minimumIntegerDigits: 2,
      });
      const seconds = (this.ticks % 60).toLocaleString("fr-FR", {
        minimumIntegerDigits: 2,
      });
      return `${minutes}:${seconds}`;
    },
    allAnimalsSaved() {
      return this.displayedAnimalId == 11;
    },
    gameLayoutHeight() {
      return `${this.window.height - 58}px`;
    }
  },
  created() {
    // cache de toutes les images
    this.images.map(this.cacheImage);

    // setInterval est une fonction javascript, elle va appeler la fonction `onTick` toutes les X millisecondes, ici 1000
    this.tickInterval = setInterval(this.onTick, 1000);

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  mounted() {
    interact("#animal-in-trouble").draggable({
      inertia: false,
      modifiers: [
        interact.modifiers.restrictRect({
          restriction: "parent",
          endOnly: false,
        }),
      ],
      listeners: {
        start: this.onAnimalDragStart,
        move: this.onAnimalMove,
      },
      cursorChecker: (action, interactable, element, interacting) => {
        return interacting ? "grabbing" : "grab";
      },
    });

    interact("#drop-zone").dropzone({
      overlap: 0.5, // 50% de la surface de l'animal doit couvrir la zone des coraux pour considérer le drop valide
      ondrop: this.onAnimalDropped,
    });
  },
  beforeUnmount() {
    // Et on supprime la fonction qui tourne toutes les secondes lorsqu'on quitte l'écran/le jeu
    // ça évite que ça continue indéfiniment même quand on revient à la page d'accueil
    window.clearInterval(this.tickInterval);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
// Paramètres d'affichage des éléments du jeu customisables
$header-height: 80px;
$game-layout-margin: 20px;

$game-layout-height: calc(
  100vh - #{$header-height} - 2 * #{$game-layout-margin}
);
$game-layout-width: 100vw;

$rescue-area-size-round1: calc(#{$game-layout-height} * 0.25);
$rescue-area-size-round2: calc(#{$game-layout-height} * 0.45);

$animal-size-round1: calc(#{$game-layout-height} * 0.06);
$animal-size-round2: calc(#{$game-layout-height} * 0.25);

$rubbish-size: calc(#{$game-layout-height} * 0.2);

// Definition des styles
.game-layout {
  width: $game-layout-width;

  background: #02192d;
  padding: 0 !important; // override the .container padding
  position: relative;

  .animal {
    &.round1 {
      height: $animal-size-round1;
      width: $animal-size-round1;
    }

    &.round2 {
      height: $animal-size-round2;
      width: $animal-size-round2;
    }
  }

  #animal-in-trouble {
    touch-action: none; // prevent the browser from panning when the user drags with a touch pointer
    user-select: none; // disable text selection

    &:hover {
      cursor: grab;
    }

    position: absolute;

    display: flex;
    align-items: center;

    #animal-halo {
      position: absolute;
      top: 50%;
      left: 50%;

      &.round1 {
        // box-shadow parameters: offset-x | offset-y | blur-radius | spread-radius | color
        box-shadow: 0 0 40px 20px #fff;
      }

      &.round2 {
        box-shadow: 0 0 120px 60px #fff;
      }
    }

    #animal-img {
      margin: auto 0;
      position: absolute;
      z-index: 2;
      background-repeat: no-repeat !important;
    }

    #game-instruction {
      width: 150px !important;
      position: absolute;
      left: 300%;
      color: white;
      text-align: center;
    }
  }

  .rescue-area {
    position: absolute;
    bottom: 0;
    right: 0;

    &.round1 {
      height: $rescue-area-size-round1;
      width: $rescue-area-size-round1;
    }
    &.round2 {
      height: $rescue-area-size-round2;
      width: $rescue-area-size-round2;
    }

    #coraux-img {
      width: 100%;
    }

    #drop-zone {
      position: absolute;
      bottom: 0;
      right: 0;

      height: 55%;
      width: 55%;
    }

    #animal-saved-img {
      position: absolute;
      bottom: 5%;
      right: 5%;
    }
  }

  .rubbish {
    position: absolute;

    &.round1 {
      width: $rubbish-size;
    }

    &.round2 {
      width: calc(0.5 * #{$rubbish-size});
    }

    &.pneu {
      $pneu-rubbish-size: calc(#{$rubbish-size}* 2);

      &.round1 {
        width: $pneu-rubbish-size;
      }

      &.round2 {
        width: calc(0.5 * #{$pneu-rubbish-size});
      }
    }
  }
}

.spinner-container {
  height: calc(100vh - #{$header-height});

  .spinner-border {
    width: 6rem;
    height: 6rem;
  }
}

.header-row {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.animalsCount {
  max-width: 260px !important;
  text-align: center;

  @media screen and (max-width: 575px) {
    max-width: 140px !important;
  }

  &--timer {
    max-width: 130px !important;
    margin-right: 20px;
    text-align: center;

    @media screen and (max-width: 575px) {
      max-width: 80px !important;
    }
  }
}
</style>
