<template>
  <principle
    principle="Groupement par la localisation"
    game="onTheDouble"
    gameName="Au pas de course !"
  >
    <template v-slot:main-content>
      <div class="introduction">
        <h2 class="introduction__title">
          D’après Bastien & Scapin (1993)*&nbsp;:
        </h2>
        <p>
          <i>
            "Les utilisateurs auront
            <strong
              >plus de facilité à repérer les différents items si ils sont
              présentés de façon organisée</strong
            >
            (ex.: alphabétique, fréquence d’utilisation, etc.). De même, ils
            pourront mieux les apprendre et s’en rappeler. "
          </i>
        </p>
        <p class="m-0">
          Ce principe rejoint la loi de proximité, issue des principes de la
          Gestalt*, qui stipule que des objets ou des formes proches les uns des
          autres semblent former des groupes. Bien que les objets soient
          différents de par leurs formes et leurs tailles,
          <strong
            >ils apparaîtront comme un groupe s'ils sont proches les uns des
            autres.</strong
          >
        </p>
      </div>

      <div class="section">
        <h2 class="section__title">Application de la loi</h2>
        <p class="section__highlight">
          Quand on conçoit un écran, les éléments qui forment un
          <b>ensemble cohérent</b> d’un point de vue fonctionnel ou sémantique
          doivent être <b>proches les uns des autres</b>.
        </p>
        <p>
          En revanche, deux éléments qui ne sont pas reliés devront être séparés
          visuellement l’un de l’autre (en laissant du blanc, ou en séparant les
          blocs à l’aide d’un trait ou d’un fond coloré sur chacun d’entre eux).
        </p>
        <div>
          Respecter ce principe permet de&nbsp;:
          <ul class="section__list">
            <li>
              <b>faire gagner du temps</b>, en accélérant la recherche d’un
              élément
            </li>
            <li>
              <b>réduire les erreurs</b>, en facilitant la compréhension de
              l’interface
            </li>
          </ul>
        </div>
      </div>

      <principle-practices>
        <template v-slot:header>
          <p class="m-0">
            Dans l'exemple "Bonne pratique" que nous vous proposons ici, les
            collections sont groupées par catégorie (Mes collections vs
            Collections partagées).
            <b>Ce groupement est renforcé par un fond coloré</b>.
          </p>
        </template>
        <template v-slot:good-practice>
          <div class="practice-container">
            <div class="practice-container__illustration good-illustration">
              <div class="collection collection--good">
                <p class="collection__title">MES COLLECTIONS</p>
                <ul class="collection__list">
                  <li>Vacances</li>
                  <li>Travail</li>
                  <li>Famille</li>
                </ul>
              </div>
              <div class="collection collection--good">
                <p class="collection__title">COLLECTIONS PARTAGÉES</p>
                <ul class="collection__list">
                  <li>Club de Badminton</li>
                </ul>
              </div>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="rightIcon"
                class="valid-icon"
                size="2x"
              />
              <span>Bonne pratique</span>
            </p>
          </div>
        </template>
        <template v-slot:bad-practice>
          <div class="practice-container">
            <div class="practice-container__illustration bad-illustration">
              <div class="collection">
                <ul class="collection__list">
                  <li>Mes collections</li>
                  <li>Vacances</li>
                  <li>Travail</li>
                  <li>Famille</li>
                  <li>Collections partagées</li>
                  <li>Club de Badminton</li>
                </ul>
              </div>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="wrongIcon"
                class="invalid-icon"
                size="2x"
              />
              <span> Mauvaise pratique </span>
            </p>
          </div>
        </template>
        <template v-slot:footer>
          <p class="m-0">
            <b>Bonus</b> : pour faciliter encore plus la compréhension, le
            principe d’homogénéité est également renforcé dans cet exemple en
            distinguant visuellement des concepts différents (catégories /
            collections) et en affichant de la même manière des concepts
            identiques (les collections entre elles, les catégories entre
            elles).
          </p>
        </template>
      </principle-practices>

      <div class="section">
        <p>
          Malheureusement, il n'est pas rare que ce principe soit enfreint, par
          exemple&#8239;:
        </p>
        <ul class="section__list">
          <li>
            de nombreux éléments en liste (alors qu’on pourrait les regrouper
            par thématique)
          </li>
          <li>
            un titre plus proche du paragraphe précédent que du paragraphe
            suivant
          </li>
        </ul>
      </div>
      <p class="section">
        <strong>
          Le principe semble simple et pourtant combien de fois avez-vous
          cherché une fonctionnalité au mauvais endroit car le principe de
          groupement n’était pas respecté&nbsp;?
        </strong>
      </p>
    </template>

    <template v-slot:game-explanation>
      <div class="game-explanation mb-4">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <div class="img-display">
                <img
                  src="../../assets/img/principles/First-Step-img1.png"
                  alt="capture d'écran du premier jeu partie ingredients"
                  class="images"
                />
                <img
                  src="../../assets/img/principles/First-Step-img2.png"
                  alt="capture d'écran du premier jeu partie desserts"
                  class="images"
                />
              </div>
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="wrongIcon"
                  class="invalid-icon"
                  size="2x"
                />
                <span> Mauvaise pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Dans le 1er jeu, le critère de groupement n’est pas respecté
              car&nbsp;:
            </p>
            <ul>
              <li>
                les produits ne sont <b>pas rangés par catégorie</b> dans la
                première partie
              </li>
              <li>
                les prix des produits sont
                <b>plus proches des produits du rayon au dessus</b> que des
                produits concernés
              </li>
            </ul>
          </div>
        </v-col>
      </div>
      <div class="game-explanation">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <div class="img-display">
                <img
                  src="../../assets/img/principles/Second-Step-img1.png"
                  alt="capture d'écran du deuxieme jeu partie ingredients"
                  class="images"
                />
                <img
                  src="../../assets/img/principles/Second-Step-img2.png"
                  alt="capture d'écran du deuxieme jeu partie desserts"
                  class="images"
                />
              </div>
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="rightIcon"
                  class="valid-icon"
                  size="2x"
                />
                <span> Bonne pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Pour simplifier la compréhension et augmenter la vitesse
              d’exécution des tâches, comme c’est le cas dans le 2nd jeu, il
              suffit&nbsp;:
            </p>
            <ul>
              <li>de <b>rapprocher les éléments de même catégorie</b></li>
              <li>de <b>séparer les catégories</b> les unes des autres</li>
              <li>
                pour chaque dessert, de
                <b>rapprocher le prix de l’image du produit</b>, et de le
                séparer visuellement des autres produits
              </li>
            </ul>
          </div>
        </v-col>
      </div>
    </template>
    <template v-slot:note>
      <p class="note">
        * Référence&#8239;:
        <a href="https://www.wikiwand.com/fr/Wolfgang_K%C3%B6hler"
          >Wolfgang Köhler</a
        >
        (trad. de l'anglais par Serge Bricianer), Psychologie de la forme :
        introduction à de nouveaux concepts en psychologie [«&nbsp;Gestalt
        psychology&nbsp;»], Paris,
        <a href="https://www.wikiwand.com/fr/%C3%89ditions_Gallimard"
          >Gallimard</a
        >, coll. «&nbsp;Idées&nbsp;», 2000 (1re éd. 1929)
        <br />
        * Référence&nbsp;: Critères Ergonomiques pour l'Évaluation d'Interfaces
        Utilisateurs (version 2.1), J. M. Christian Bastien and Dominique L.
        Scapin, INRIA, Technical report N° 156 Mai 1993
        <a
          href="https://hal.inria.fr/inria-00070012"
          target="_blank"
          rel="noopener noreferrer"
          >[Accès document complet - Site web INRIA]</a
        >
      </p>
    </template>
  </principle>
</template>

<script>
import Principle from './layout/PrinciplePage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PrinciplePractices from './layout/PrinciplePractices.vue';

export default {
  name: 'ProximityLawPrinciple',
  components: {
    Principle,
    PrinciplePractices,
    FontAwesomeIcon
  },
  data() {
    return {
      rightIcon: faCheck,
      wrongIcon: faTimes,
      windowWidth: window.innerWidth
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.updateWindowWidth);
    });
  },
  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }
};
</script>

<style lang="scss" scoped>
@import './layout/principle-page-content.scss';

.good-illustration {
  padding: 0;
}

.bad-illustration {
  padding: 2rem 1.5rem;
}

.collection {
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: auto;

  &--good {
    background-color: #1edede26;
    border-radius: 10px;
    padding: 15px;
    margin: 0.5rem;
  }

  &__title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    margin: 0;
    color: #07c2c2;
  }

  &__list {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    color: #3b3f3f;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;

    li {
      margin-top: 0.5rem;
    }
  }
}

.img-display {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 1rem;

  @media (max-width: 1280px) and (min-width: 960px) {
    gap: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.images {
  width: 50%;

  @media (max-width: 960px) {
    width: 35%;
  }

  @media (max-width: 768px) {
    width: 70%;
  }
}
</style>
