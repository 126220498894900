// x : positionnement par rapport au bord gauche
// y : positionnement par rapport au haut
export default [
  {
    id: 1,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/meduse-triste.svg`),
        heureux: require(`@/assets/img/poulpe/meduse-heureuse.svg`),
      },
      initialPosition: {
        x: 10,
        y: 20,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/calmar-triste.svg`),
        heureux: require(`@/assets/img/poulpe/calmar-heureux.svg`),
      },
      initialPosition: {
        x: 50,
        y: 30,
      },
    },
  },
  {
    id: 2,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/poisson1-triste.svg`),
        heureux: require(`@/assets/img/poulpe/poisson1-heureux.svg`),
      },
      initialPosition: {
        x: 40,
        y: 10,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/dauphin-triste.svg`),
        heureux: require(`@/assets/img/poulpe/dauphin-heureux.svg`),
      },
      initialPosition: {
        x: 40,
        y: 45,
      },
    },
  },
  {
    id: 3,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/bernard-triste.svg`),
        heureux: require(`@/assets/img/poulpe/bernard-heureux.svg`),
      },
      initialPosition: {
        x: 10,
        y: 40,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/raie-triste.svg`),
        heureux: require(`@/assets/img/poulpe/raie-heureuse.svg`),
      },
      initialPosition: {
        x: 50,
        y: 30,
      },
    },
  },
  {
    id: 4,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/etoile-triste.svg`),
        heureux: require(`@/assets/img/poulpe/etoile-heureuse.svg`),
      },
      initialPosition: {
        x: 10,
        y: 70,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/thon-triste.svg`),
        heureux: require(`@/assets/img/poulpe/thon-heureux.svg`),
      },
      initialPosition: {
        x: 50,
        y: 35,
      },
    },
  },
  {
    id: 5,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/tortue-triste.svg`),
        heureux: require(`@/assets/img/poulpe/tortue-heureuse.svg`),
      },
      initialPosition: {
        x: 10,
        y: 20,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/espadon-triste.svg`),
        heureux: require(`@/assets/img/poulpe/espadon-heureux.svg`),
      },
      initialPosition: {
        x: 40,
        y: 40,
      },
    },
  },
  {
    id: 6,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/poissonglobe-triste.svg`),
        heureux: require(`@/assets/img/poulpe/poissonglobe-heureux.svg`),
      },
      initialPosition: {
        x: 30,
        y: 10,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/requin-marteau-triste.svg`),
        heureux: require(`@/assets/img/poulpe/requin-marteau-heureux.svg`),
      },
      initialPosition: {
        x: 50,
        y: 30,
      },
    },
  },
  {
    id: 7,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/poissonlanterne-triste.svg`),
        heureux: require(`@/assets/img/poulpe/poissonlanterne-heureux.svg`),
      },
      initialPosition: {
        x: 10,
        y: 70,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/baleine-triste.svg`),
        heureux: require(`@/assets/img/poulpe/baleine-heureuse.svg`),
      },
      initialPosition: {
        x: 45,
        y: 20,
      },
    },
  },
  {
    id: 8,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/nemo-triste.svg`),
        heureux: require(`@/assets/img/poulpe/nemo-heureux.svg`),
      },
      initialPosition: {
        x: 10,
        y: 40,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/baleinebosse-triste.svg`),
        heureux: require(`@/assets/img/poulpe/baleinebosse-heureuse.svg`),
      },
      initialPosition: {
        x: 60,
        y: 40,
      },
    },
  },
  {
    id: 9,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/hippocampe-triste.svg`),
        heureux: require(`@/assets/img/poulpe/hippocampe-heureux.svg`),
      },
      initialPosition: {
        x: 10,
        y: 10,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/cachalot-triste.svg`),
        heureux: require(`@/assets/img/poulpe/cachalot-heureux.svg`),
      },
      initialPosition: {
        x: 50,
        y: 25,
      },
    },
  },
  {
    id: 10,
    round1: {
      src: {
        triste: require(`@/assets/img/poulpe/crabe-triste.svg`),
        heureux: require(`@/assets/img/poulpe/crabe-heureux.svg`),
      },
      initialPosition: {
        x: 40,
        y: 10,
      },
    },
    round2: {
      src: {
        triste: require(`@/assets/img/poulpe/orque-triste.svg`),
        heureux: require(`@/assets/img/poulpe/orque-heureux.svg`),
      },
      initialPosition: {
        x: 45,
        y: 45,
      },
    },
  },
];
