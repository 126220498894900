<template>
  <div class="content rule-container">
    <div class="rule__title">
      <h1 class="title__h">Au pas de course&nbsp;!</h1>
      <h2 class="title__h title__h--second">La pizza de Meiko a mis l’eau à la bouche de Stella.</h2>
    </div>

    <p class="rule__text">
      Aide-là à faire ses courses !
      <br> Dans la première partie du magasin, tu l’aideras à <b> trouver les ingrédients </b>
      pour refaire la pizza de Meiko. Ensuite, il faudra <b> trouver un dessert adapté à son budget </b> (inférieur à
      5€).
      <br>
    </p>
    <p class="rule__text">
      Et <b> le tout au pas de course </b> bien sûr : le magasin est sur le point de fermer&nbsp;!!!
    </p>
    <v-row class="rule__v-row">
      <div class="col-xs-12 col-md-6 col-lg-7">
        <img class="rule__illustration" src="../../../assets/img/rule-on-the-double.png" alt="">
      </div>
      <div class="col-xs-12 col-md-6 col-lg-5">
        <button @click="play" class="primary-btn">Jouer</button>
      </div>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "on-the-double-rule-modal",

  methods: {
    play() {
      this.$router.push("/game/OnTheDouble");
    }
  }
}
</script>

<style lang="scss" scoped>
.rule-container {
  width: 952px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.rule__title {
  margin-bottom: 30px;
}

.title__h {
  color: black;
  text-align: center;
  margin: 0 !important;
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
}

.title__h--second {
  font-size: 24px;
  font-weight: 400;
}

.rule__text {
  color: black;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}

.rule__illustration {
  margin: 1.25rem auto 1.5rem;
  width: 100%;
}

.rule__v-row {
  display: flex;
  align-items: center;
}

</style>