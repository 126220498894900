import {
  createEntity,
  addEntities,
} from "../entity_system";

import artifacts from "./artifacts";
import * as steps from "./steps";
import { rollOutDough } from "./actions";

const BOARD_SIZE = 6;

export default {
  BOARD_SIZE,

  stepLabels: steps.labels,

  initialStore: addEntities([], [
    createEntity({ id: 'running', value: true }),
    createEntity({ id: 'constants', boardSize: BOARD_SIZE }),
    createEntity({ id: 'ticks', value: 0 }),
    createEntity({ id: 'currentStep', value: steps.STEP_ROLL_OUT_DOUGH }),
    createEntity({ id: 'halo', size: { width: 1, height: 1 } }),
    createEntity({ id: 'stepCount', value: 0 }),
    createEntity({ id: 'haloNextMoveRight', size: { width: 1, height: 1 } }),
    createEntity({ id: 'haloNextMoveLeft', size: { width: 1, height: 1 } }),
    createEntity({ id: 'haloNextMoveTop', size: { width: 1, height: 1 } }),
    createEntity({ id: 'haloNextMoveBottom', size: { width: 1, height: 1 } }),

    createEntity({ id: 'grabbedPesto', value: false }),
    createEntity({ id: 'grabbedMushr', value: false }),

    createEntity(artifacts.oven, { position: { x: 3, y: 1 } }),
    createEntity(artifacts.hatch, { position: { x: 5, y: 1 } }),
    createEntity(artifacts.fridge, { position: { x: 3, y: 5 } }),
    createEntity(artifacts.workplan, {
      id: 'workplan1',
      position: { x: 1, y: 1 },
    }),
    createEntity(artifacts.workplan, {
      id: 'workplan2',
      position: { x: 6, y: 1 },
    }),
    createEntity(artifacts.shelf, {
      id: 'shelf1',
      position: { x: 1, y: 3 },
    }),
    createEntity(artifacts.shelf, {
      id: 'shelf2',
      position: { x: 6, y: 3 },
    }),
    createEntity(artifacts.mushr, { position: { x: 6, y: 3 } }),
    createEntity(artifacts.pesto, { position: { x: 4, y: 5 } }),
    createEntity(artifacts.meiko, { position: { x: 4, y: 3 } }),
    createEntity(artifacts.pizza, {
      position: { x: 6, y: 2 },
      exec: rollOutDough,
      tip: 'Étaler',
    }),
  ]),
}
