<template>
  <div class="d-flex justify-content-between align-items-center m-4">
    <span :class="clue ? '' : 'font-weight-bold'"><slot></slot></span>
    <clue v-if="clue" :clue="clue">Indice</clue>
  </div>
</template>

<script>
import Clue from './CharradeClue.vue';

export default {
  props: {
    clue: String
  },
  components: {
    Clue,
  }
}
</script>

