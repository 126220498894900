<template>
  <principle
    principle="Homogénéité"
    game="catchThemAll"
    gameName="Attrapez les tous !"
    nextGame="charade"
  >
    <template v-slot:main-content>
      <div class="introduction">
        <h2 class="introduction__title">
          D'après Bastien & Scapin (1993)&#8239;*&#8239;:
        </h2>
        <div class="m-0 font-italic">
          Le critère Homogénéité/Cohérence se réfère à la façon avec laquelle
          <b> les choix de conception de l’interface </b> (codes, dénominations,
          formats, procédures, etc.) sont&#8239;:
          <ul class="section__list">
            <li><b>conservés pour des contextes identiques,</b></li>
            <li><b>différents pour des contextes différents.</b></li>
          </ul>
        </div>
      </div>

      <div class="section">
        <h2 class="section__title">Application de la loi</h2>
        <p class="section__highlight">
          Les éléments d'une interface sont d’autant mieux reconnus, localisés
          et utilisés, que leur format, localisation, ou syntaxe sont
          <b>stables d’un écran à l’autre</b> et d’une session à l’autre.
          <br />
          Ce principe touche à la fois le visuel des éléments, leur appelation
          (label), les réactions du système, les procédures...
        </p>
        <p>
          Dans ces conditions le système est davantage <b>prévisible</b> et les
          apprentissages plus généralisables, ce qui <b>réduit les erreurs</b>.
          Le manque d’homogénéité peut augmenter considérablement le temps de
          recherche et est une raison importante de refus d’utilisation.
        </p>
      </div>

      <principle-practices>
        <template v-slot:good-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <ul class="list-unstyled illustration">
                <li class="right">Lien 1</li>
                <li class="right">Lien 2</li>
                <li class="right">Lien 3</li>
                <li class="right">Lien 4</li>
              </ul>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="rightIcon"
                class="valid-icon"
                size="2x"
              />
              <span>Bonne pratique</span>
            </p>
          </div>
        </template>
        <template v-slot:bad-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <ul class="list-unstyled illustration">
                <li class="right">Lien 1</li>
                <li class="wrong-1">Lien 2</li>
                <li class="wrong-2">Lien 3</li>
                <li class="wrong-3">Lien 4</li>
              </ul>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="wrongIcon"
                class="invalid-icon"
                size="2x"
              />
              <span> Mauvaise pratique </span>
            </p>
          </div>
        </template>
      </principle-practices>

      <div class="section">
        <p>
          Malheureusement, il n'est pas rare que ce principe soit enfreint, par
          exemple&#8239;:
        </p>
        <ul class="section__list">
          <li>
            un site web qui change soudain d'aspect après avoir cliqué sur un
            lien (en réalité, il s'agit souvent de 2 sites web de la marque qui
            n'ont pas été homogénéïsés entre eux)&nbsp;;
          </li>
          <li>
            un langage qui alterne entre la 1ère à la 3ème personne ("Mon mot de
            passe" puis "Votre panier")&nbsp;;
          </li>
          <li>
            le bouton pour fermer ou revenir en arrière qui change d'aspect ou
            de position selon les écrans&nbsp;;
          </li>
          <li>
            un label dont le nom n'est pas le même dans le menu et dans
            l'en-tête de l'écran correspondant ("Votre historique" dans le menu
            et "Mes précédents achats" en en-tête).
          </li>
        </ul>
      </div>

      <p class="section">
        <strong>
          Le principe semble simple et pourtant, combien de fois vous est-il
          arrivé de cliquer sur un lien qui s'est révélé ne pas en être
          un&nbsp;?
        </strong>
      </p>
    </template>

    <template v-slot:game-explanation>
      <div class="game-explanation mb-4">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/petit_homo_test.jpg"
                alt="extrait des oeuf à cliquer dans le premier jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="wrongIcon"
                  class="invalid-icon"
                  size="2x"
                />
                <span> Mauvaise pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            Dans le 1er jeu, le critère d’homogénéité n’est pas respecté
            car&nbsp;:
            <ul class="section__list">
              <li>
                les œufs de Rosalyne ont 3 formes différentes de chapeaux&nbsp;:
                haut de forme, chapeau pirate et chapeau mexicain (un peu comme
                si des liens n'étaient pas toujours affichés de la même manière,
                certains en bleu, d'autres soulignés, d'autres en bleu
                souligné...)&nbsp;;
              </li>
              <li>
                les œufs qui ne sont pas à Rosalyne ont des couvre-chefs qui
                ressemblent à des chapeaux&nbsp;: casque viking, bonnet
                péruvien, Mickey et casquette (comme si les liens étaient en
                vert ou bleu alors que d'autres textes en violet n'étaient eux
                pas des liens)&nbsp;;
              </li>
              <li>
                l’un des œufs avec un chapeau pirate n’est pas un œuf de
                Rosalyne, bien qu’il soit identique&nbsp;! (comme si un texte
                souligné était parfois un lien, et parfois pas&nbsp;!)
              </li>
            </ul>
          </div>
        </v-col>
      </div>
      <div class="game-explanation">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/petit_homo_controle.jpg"
                alt="extrait des oeufs à cliquer dans le second jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="rightIcon"
                  class="valid-icon"
                  size="2x"
                />
                <span> Bonne pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Pour fluidifier l’expérience, comme c’est le cas dans le 2nd jeu,
              il suffit que les œufs de Rosalyne soient tous identiques, et que
              les autres œufs soient tous identiques entre eux. Transposé à une
              situation réelle, il faut qu’un élément (un lien, un titre...) ait
              toujours le même aspect pour que les utilisateurs l’identifient
              comme tel et ne se sentent pas perdus ou frustrés.
            </p>
          </div>
        </v-col>
      </div>
      <p class="p-0 mt-4">
        <b
          >Il est impossible d’identifier les œufs de Rosalyne dans ces
          conditions&nbsp;! Désolée... l'expérience a dû être frustrante&nbsp;?
          Comme toujours lorsque le critère d'homogénéïté n'est pas respecté.</b
        >
      </p>
    </template>

    <template v-slot:note>
      <p class="note">
        * Référence&nbsp;: Critères Ergonomiques pour l'Évaluation d'Interfaces
        Utilisateurs (version 2.1), J. M. Christian Bastien and Dominique L.
        Scapin, INRIA, Technical report N° 156 Mai 1993
        <a
          href="https://hal.inria.fr/inria-00070012"
          target="_blank"
          rel="noopener noreferrer"
          >[Accès document complet - Site web INRIA]</a
        >
      </p>
    </template>
  </principle>
</template>

<script>
import Principle from './layout/PrinciplePage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PrinciplePractices from './layout/PrinciplePractices.vue';

export default {
  name: 'HomogeneityPrinciple',
  components: {
    Principle,
    PrinciplePractices,
    FontAwesomeIcon
  },
  computed: {
    rightIcon() {
      return faCheck;
    },
    wrongIcon() {
      return faTimes;
    }
  }
};
</script>

<style lang="scss">
@import './layout/principle-page-content.scss';

.right {
  color: blue;
  text-decoration: underline;
}
.wrong-1 {
  color: grey;
  font-weight: bold;
}
.wrong-2 {
  color: red;
  text-decoration: underline;
}
.wrong-3 {
  color: green;
}
</style>
