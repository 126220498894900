<template>
  <div class="ml-3">
    <button class="clue-button btn-outline-secondary" @click="show = !show">
      <font-awesome-icon :icon="clueIcon" /> <span class="d-none d-md-inline"> Tricher </span>
    </button>
    <v-tooltip v-model="show" location="right">
      <template v-slot:activator="{ props }">
        <span v-bind="props"> </span>
      </template>
      <span>{{clue}} </span>
    </v-tooltip>
  </div>

</template>

<script setup>
import { ref } from 'vue'

const show = ref(false)
</script>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';

export default {
  props: {
    clue: String,
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    clueIcon() {
      return faUserSecret;
    }
  }
}
</script>

<style lang="scss">
.clue-button {
  text-transform: inherit;
  padding: 4px 8px;
  border: 1px solid #6c757d;
  border-radius: .2rem;

  svg {
    margin-right: 7px !important;

    @media screen and (max-width: 575px) {
      margin-right: 0 !important;
    }
  }
}

.popover {
  z-index: 1 !important;
}
</style>

