<template>
  <v-app-bar id="ergogames-navbar" class="header shadow">
    <v-app-bar-title class="ml-3 header-title">
      <router-link to="/#home" class="logo">
        <v-container class="text-center">
          <div class="logo_first_line">Les</div>
          <div class="logo_second_line">Ergogames&nbsp;!</div>
        </v-container>
      </router-link>
    </v-app-bar-title>
    <div v-if="isDesktop" class="mt-2">
      <router-link to="/#home" class="header-item">
        <span>Jeux</span>
      </router-link>
      <router-link to="/principles" class="header-item">
        <span>Principes</span>
      </router-link>
      <button ref="quiz" class="header-item">Quiz</button>
      <router-link to="/projet" class="header-item">
        <span>Projet</span>
      </router-link>
    </div>
    <div v-else class="header-drawer">
      <button id="toggle" @click="drawer = !drawer" class="navbar-toggler">
        Menu
      </button>
      <v-expand-transition>
        <div v-show="drawer" class="top-drawer">
          <router-link
            class="top-drawer__link header-item"
            to="/#home"
            @click="drawer = false"
            >Jeux</router-link
          >
          <router-link
            class="top-drawer__link header-item"
            to="/principles"
            @click="drawer = false"
            >Principes</router-link
          >

          <button
            ref="quiz"
            class="top-drawer__link header-item"
            @click="drawer = false"
          >
            Quiz
          </button>
          <router-link
            class="top-drawer__link header-item"
            to="/projet"
            @click="drawer = false"
            >Projet</router-link
          >
        </div>
      </v-expand-transition>
    </div>

    <v-dialog :activator="$refs.quiz">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button
            type="button"
            class="close ml-auto"
            aria-label="Close"
            @click="isActive.value = false"
          >
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <quiz-rule></quiz-rule>
        </v-card>
      </template>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import QuizRule from '@/views/Quiz/QuizRule.vue';

export default {
  components: {
    QuizRule
  },
  data() {
    return {
      drawer: false,
      windowWidth: window.innerWidth
    };
  },

  computed: {
    isDesktop() {
      return this.windowWidth > 500;
    }
  },

  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.updateWindowWidth);
    });
  },

  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }
};
</script>

<style lang="scss">
@import '../assets/scss/colors.scss';

#toggle {
  font-size: 1rem;
  font-weight: 500;
  color: #515254;
  border-color: $neutral-yellow;
  border-width: 0.1em;
  margin-right: 25px;

  &:focus {
    border-color: $neutral-yellow;
  }

  &:hover {
    background-color: $neutral-yellow;
  }
}

.header {
  position: sticky !important;
  background-color: white;
  padding: 8px;

  &.header-minised {
    padding: 0.5rem 0 !important;
  }

  .logo {
    display: flex;
    flex-direction: column;
    font-weight: 700;
    width: fit-content;

    .logo_first_line,
    .logo_second_line {
      background: linear-gradient(90deg, $neutral-blue 1%, $light-blue 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .logo_first_line {
      font-size: 0.55em;
      margin-bottom: -10px;
      @media screen and (max-width: 575px) {
        margin-bottom: -8px;
      }
    }

    .logo_second_line {
      text-transform: uppercase;
      font-size: 1em;
      @media screen and (max-width: 575px) {
        font-size: 0.95em;
      }
    }
  }

  & > div {
    height: auto !important;
    display: flex;
    align-items: start;
  }

  .header-item {
    text-align: end;
    font-weight: 700;
    color: #515254;
    padding-right: 25px;
    line-height: 2;

    &.router-link-active {
      text-decoration: underline;
      text-decoration-color: $dark-blue;
      color: $dark-blue;
    }
  }
}

.text-center {
  padding: 0 5px !important;
}

.header-title {
  display: flex;
  align-items: start;
}

.header-drawer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
}

.top-drawer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  .top-drawer__link {
    font-weight: 700;
    color: #515254;
    line-height: 2;
  }
}
</style>
