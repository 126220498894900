<template>
  <div>
    <!-- ROUND 1 -->
    <div v-if="currentRound === 1" class="row content justify-content-center m-auto">
      <div>
        <h1>Ouch&nbsp;!</h1>
        <h2>Il t'a fallu {{ formatTime(currentChronoFixed) }} pour sauver les 10 amis de Colin.</h2>
        <p>
          J’espère qu’ils n’auront pas trop souffert de leur séjour dans ces eaux douteuses...
          <br />Si tu le souhaites, tu peux réessayer avant de passer à la suite.
        </p>
        <p class="margin-bot">
          Eh oui, Colin a 10 autres amis plus loin dans la mer !
          <br />Tu veux bien l'aider à nouveau&nbsp;?
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Réessayer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="nextRound" class="primary-btn">Sauver les autres amis</button>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- ROUND 2 -->
    <div v-if="currentRound === 2" class="row content justify-content-center m-auto">
      <div>
        <h1>{{ (isSuccess) ? "Bravo&nbsp;!" : "Ouch&nbsp;!" }}</h1>
        <h2>Il t'a fallu {{ formatTime(currentChronoFixed) }} pour sauver les 10 amis de Colin.</h2>
        <p v-if="isSuccess" class="margin-bot">
          Il t'avait fallu {{ formatTime(timeRoundOne) }} pour sauver les 10 amis du 1er groupe.
          <br />Colin est ravi et ses amis n'ont pas souffert&nbsp;!
          <br />Découvre maintenant le principe d'ergonomie qui se cache derrière.
        </p>
        <p v-else class="margin-bot">
          J’espère qu’ils n’auront pas trop souffert de leur séjour dans ces eaux douteuses...
          <br />Découvre maintenant le principe d'ergonomie qui se cache derrière.
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Réessayer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="goToPrinciple" class="primary-btn">Voir le principe</button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PoulpeResult",
  props: {
    round: Number,
    timeRoundOne: Number, // in seconds
    timeCurrentRound: Number, // in seconds
    replay: Function,
    nextRound: Function,
  },

  data() {
    return {
      currentRound: null,
      currentChronoFixed: null,
    };
  },
  methods: {
    goToPrinciple() {
      this.$router.push("/principles/fitts");
    },
    formatTime(timeInSeconds) {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      const minutesLabel =
        minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
      const secondsLabel =
        seconds > 1 ? `${seconds} secondes` : `${seconds} seconde`;

      return minutes === 0
        ? secondsLabel
        : `${minutesLabel} et ${secondsLabel}`;
    },
  },
  computed: {
    isSuccess() {
      return this.currentRound === 2 && this.timeCurrentRound < this.timeRoundOne;
    },
  },

  mounted() {
    this.currentRound = this.round;
    this.currentChronoFixed = this.timeCurrentRound;
  },
};
</script>

<style scoped>
.margin-bot {
  margin-bottom: 40px !important;
}
</style>