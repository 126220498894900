<template>
  <div class="container-fluid home_zone_1">
    <div class="container text-center">
      <div class="title_level_2">Les</div>
      <h1 class="title_level_1">Ergogames&nbsp;!</h1>
      <p>Découvrez les principes fondamentaux d'ergonomie.</p>
    </div>
  </div>
</template>

<style lang="scss">
@import '../../assets/scss/colors';
@import '../../assets/scss/zones';
.home_zone_1 {
  @extend %title-zone;
  padding-top: 20px;
  padding-bottom: 20px;
  @media screen and (max-width: 767px) {
    padding-top: 30px;
  }
  @media screen and (max-width: 575px) {
    padding-bottom: 0px;
  }

  .title_level_1 {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 4.2em;
    background: linear-gradient(90deg, $neutral-blue 1%, $light-blue 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      font-size: 3.7em;
    }
    @media screen and (min-width: 384px) and (max-width: 991px) {
      font-size: 2.7em;
    }
    @media screen and (max-width: 383px) {
      font-size: 2.3em;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .title_level_2 {
    font-weight: 900;
    font-size: 2em;
    margin-bottom: -23px;
    margin-left: 3px;
    padding-top: 10px;
    background: linear-gradient(90deg, $neutral-blue 1%, $light-blue 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media screen and (max-width: 991px) {
      margin-bottom: -18px;
      margin-left: 1px;
    }
    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }
}
</style>
