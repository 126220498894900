<template>
  <game-container ref="container">
    <v-dialog v-model="dialog" persistent class="dialog-illustration">
      <v-card class="rounded-dialog">
        <button
          v-show="end"
          type="button"
          class="close ml-auto"
          aria-label="Close"
          @click="goHome"
        >
          <p aria-hidden="true" class="text-h4 m-0">&times;</p>
        </button>
        <quiz-result
          :isAnswerRight="this.isAnswerRight"
          :rightAnswer="question.rightAnswer"
          :wrongAnswer="question.wrongAnswer"
          :details="question.details"
          :replay="replay"
          :next="next"
          :score="totalScore"
          :nbQuestions="questions.length"
          :end="end"
        />
      </v-card>
    </v-dialog>

    <template v-slot:header>
      <v-col class="header__col header__col--phrase">
        <p class="m-0">Quel principe est enfreint ici&nbsp;?</p>
      </v-col>

      <v-col class="header__col header__col--number-question">
        <p class="m-0">
          {{ Number(questionIndex) + 1 }}/{{ questions.length }}
        </p>
      </v-col>
    </template>

    <v-dialog v-model="dialogIllustration" class="dialog-illustration">
      <template v-slot:default="{ isActive }">
        <v-card class="rounded-dialog">
          <button
            type="button"
            class="close ml-auto"
            aria-label="Close"
            @click="isActive.value = false"
          >
            <p aria-hidden="true" class="text-h4 m-0">&times;</p>
          </button>
          <img
            class="illustration__image-zoom"
            :src="question.wrongAnswer.imgSrc"
          />
        </v-card>
      </template>
    </v-dialog>

    <div class="quiz-content">
      <div class="quiz-content__question">
        <div class="question__context">
          <p class="m-0 question__context-text"><b>Contexte&nbsp;</b></p>
          <hr />
          <p class="question__context-text">
            {{ question.context }}
          </p>
        </div>

        <div class="quiz-content__question-illustration">
          <button
            v-if="isDesktop"
            @click="showIllustrationDialog"
            class="image-container"
          >
            <span class="magnifying-glass">
              <font-awesome-icon :icon="magnifyingGlass" /> Zoom
            </span>
            <img
              class="illustration__image"
              :src="question.wrongAnswer.imgSrc"
              alt="homogénéité"
            />
          </button>
          <img
            v-else
            class="illustration__image"
            :src="question.wrongAnswer.imgSrc"
            alt="homogénéité"
          />
        </div>
      </div>

      <div class="quiz-content__answer">
        <div class="quiz-content__answer-list">
          <button
            v-for="principle in principles.filter(
              p => p.name !== 'Loi de Hick'
            )"
            :key="principle.name"
            @click="() => handleResponse(principle.name, question)"
            class="answer__button"
          >
            <img :src="principle.img" :alt="principle.imgAlt" />
            <p>{{ principle.name }}</p>
          </button>
        </div>

        <div>
          <button
            id="principle-button"
            @click="togglePrinciples"
            class="principle__button"
            :class="{ 'principle__button--expend': isExplanationVisible }"
          >
            <font-awesome-icon
              :icon="circleInfo"
              class="principle__button-info"
              :class="{
                'principle__button-info--expend': isExplanationVisible
              }"
              size="2x"
            />
            Rappel des principes
            <span v-if="isExplanationVisible">
              <font-awesome-icon :icon="angleUp" class="mr-1" />
            </span>
            <span v-else>
              <font-awesome-icon :icon="angleDown" class="mr-1" />
            </span>
          </button>
        </div>

        <v-slide-y-transition>
          <v-card class="principle-toggle" v-show="isExplanationVisible">
            <div
              v-for="principle in principles"
              :key="principle.name"
              class="reminder"
            >
              <div class="reminder__title">
                <img
                  :src="principle.img"
                  :alt="principle.imgAlt"
                  class="reminder__title-image"
                />
                <p class="reminder__title-text">{{ principle.name }} :</p>
              </div>
              <p class="reminder__text" v-html="principle.reminder"></p>
            </div>
          </v-card>
        </v-slide-y-transition>
      </div>
    </div>

    <main role="main">
      <router-view />
    </main>
  </game-container>
</template>

<script>
import minimumActionsImg from '../../assets/img/ico_bouton_jeu_3.png';
import homogeneityImg from '../../assets/img/poule.png';
import errorHandlingImg from '../../assets/img/tortue.png';
import proximityImg from '../../assets/img/onTheDouble/renard.svg';
import hickImg from '../../assets/img/souris.png';
import FittsImg from '../../assets/img/poulpe/poulpy.svg';
import GameContainer from '../games/GameContainer.vue';
import QuizResult from './QuizResult.vue';

import Q3Bad from '../../assets/img/quiz/Q3-bad.png';
import Q8bad from '../../assets/img/quiz/Q8-bad.png';

import Q3Good from '../../assets/img/quiz/Q3-good.png';
import Q8Good from '../../assets/img/quiz/Q8-good.png';

import Homogeneite1Good from '../../assets/img/quiz/homogeneite-1-good.png';
import Homogeneite1Bad from '../../assets/img/quiz/homogeneite-1-bad.png';
import Homogeneite2Good from '../../assets/img/quiz/homogeneite-2-good.png';
import Homogeneite2Bad from '../../assets/img/quiz/homogeneite-2-bad.png';
import ActionsMinimales1Good from '../../assets/img/quiz/actions-minimales-1-good.png';
import ActionsMinimales1Bad from '../../assets/img/quiz/actions-minimales-1-bad.png';
import ActionsMinimales2Good from '../../assets/img/quiz/actions-minimales-2-good.png';
import ActionsMinimales2Bad from '../../assets/img/quiz/actions-minimales-2-bad.png';
import GroupementGood from '../../assets/img/quiz/groupement-good.png';
import GroupementBad from '../../assets/img/quiz/groupement-bad.png';
import GestionDesErreursGood from '../../assets/img/quiz/gestion-des-erreurs-good.png';
import GestionDesErreursBad from '../../assets/img/quiz/gestion-des-erreurs-bad.png';
import LoiDeFittsGood from '../../assets/img/quiz/loi-de-fitts-good.png';
import LoiDeFittsBad from '../../assets/img/quiz/loi-de-fitts-bad.png';
import LoiDeFitts2Bad from '../../assets/img/quiz/fitts-2-bad.png';
import LoiDeFitts2Good from '../../assets/img/quiz/fitts-2-good.png';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons';

export default {
  components: {
    FontAwesomeIcon,
    GameContainer,
    QuizResult
  },
  data: () => ({
    principles: [
      {
        name: 'Homogénéité',
        page: '/principles/homogeneity',
        img: homogeneityImg,
        imgAlt: 'afficher le détail du principe Homogénéité',
        reminder:
          "Les éléments d'une interface sont d’autant mieux reconnus, localisés et utilisés, que leur format, localisation, ou syntaxe sont stables tout au long de l'expérience utilisateur."
      },
      {
        name: 'Actions minimales',
        page: '/principles/minimum-actions',
        img: minimumActionsImg,
        imgAlt: 'afficher le détail du principe Actions minimales',
        reminder:
          'Le critère Actions Minimales concerne la charge de travail quant aux actions nécessaires à l’atteinte d’un but, à l’accomplissement d’une tâche. Il s’agit ici de limiter autant que possible les étapes par lesquelles doivent passer les utilisateurs.'
      },
      {
        name: 'Gestion des erreurs',
        page: '/principles/error-handling',
        img: errorHandlingImg,
        imgAlt: 'afficher le détail du principe Gestion des erreurs',
        reminder:
          "Moyens permettant d'une part d'éviter ou de réduire les erreurs, d'autre part de les corriger lorsqu'elles surviennent."
      },
      {
        name: 'Loi de Fitts',
        page: '/principles/fitts',
        img: FittsImg,
        imgAlt: 'afficher le détail du principe Loi de Fitts',
        reminder:
          'Quand on conçoit un écran, les fonctions les plus utilisées doivent être rapidement accessibles et donc de taille respectable et proches de l’action précédente.'
      },
      {
        name: 'Groupement',
        page: '/principles/proximity-law',
        img: proximityImg,
        imgAlt: 'afficher le détail du principe Groupement',
        reminder:
          'Quand on conçoit un écran, les éléments qui forment un ensemble cohérent d’un point de vue fonctionnel ou sémantique doivent être proches les uns des autres.'
      },
      {
        name: 'Loi de Hick',
        img: hickImg,
        imgAlt: 'afficher le détail du principe Loi de Hick',
        reminder:
          "Plus l'utilisateur a de choix et plus il lui faudra de temps pour prendre une décision.<br>Note : <strong>ce choix n'est jamais proposé dans le quiz</strong>, car ce n'est pas directement un principe à appliquer, et souvent la solution pour avoir moins de choix et prendre moins de temps pourrait être d'appliquer le principe de groupement en regroupant les items par catégories (ce qui créerait une confusion dans le quiz : est-ce que c'est Hick ou groupement ?!?). "
      }
    ],
    questions: [
      {
        index: 0,
        answers: ['Gestion des erreurs'],
        context: "Erreur s'affichant après avoir essayé d'uploader un fichier.",
        details:
          "En effet, le message d'erreur n'est <b>pas du tout explicite</b>, il contient <b>des informations inutiles pour l'utilisateur</b>, et n'explique pas clairement comment corriger l'erreur.",
        rightAnswer: {
          title:
            "Bien joué, c'était bien <b>le principe de gestion des erreurs !</b>",
          imgSrc: Q3Good,
          imgCaption:
            'Version <b>respectant</b> le principe de gestion des erreurs'
        },
        wrongAnswer: {
          title:
            "Et non, c'était surtout <b>le principe de gestion des erreurs !</b>",
          imgSrc: Q3Bad,
          imgCaption:
            'Version <b>enfreignant</b> le principe de gestion des erreurs'
        }
      },
      {
        index: 1,
        answers: ['Homogénéité'],
        context:
          'Page recrutement du site internet d’une société dans le développement logiciel.',
        details:
          'En effet, dans la première version, les boutons avaient des styles, couleurs et libellés différents d’une vignette à l’autre. Il est recommandé d’utiliser <strong>le même style sur chaque bouton qui porte la même fonctionnalité, et la même organisation des informations</strong> au sein de chacune des vignettes.',
        rightAnswer: {
          title: "Bien joué, c'était bien <b>le principe d'homogénéité !</b>",
          imgSrc: Homogeneite1Good,
          imgCaption: "Version <b>respectant</b> le principe d'homogénéité"
        },
        wrongAnswer: {
          title: "Et non, c'était surtout <b>le principe d'homogénéité !</b>",
          imgSrc: Homogeneite1Bad,
          imgCaption: "Version <b>enfreignant</b> le principe d'homogénéité"
        }
      },
      {
        index: 2,
        answers: ['Actions minimales'],
        context: "Formulaire d'inscription à un centre de loisirs.",
        details:
          "En effet, demander la date de la naissance puis la tranche d'âge est <b>redondant</b>, la seconde pouvant être déduite de la première.",
        rightAnswer: {
          title:
            "Bien joué, c'était bien <b>le principe d'actions minimales !</b>",
          imgSrc: ActionsMinimales1Good,
          imgCaption:
            "Version <b>respectant</b> le principe d'actions minimales"
        },
        wrongAnswer: {
          title:
            "Et non, c'était surtout <b>le principe d'actions minimales !</b>",
          imgSrc: ActionsMinimales1Bad,
          imgCaption:
            "Version <b>enfreignant</b> le principe d'actions minimales"
        }
      },
      {
        index: 3,
        answers: ['Homogénéité'],
        context:
          'Application pour faire sa liste de Noël et l’envoyer au Père Noël.',
        details:
          'En effet, les noms des produits et le terme utilisé pour faire référence à la liste de Noël changent d’un écran à l’autre, de même pour le style des boutons. Il est <strong>recommandé de garder les mêmes termes pour faire référence à des choses identiques, et de conserver le style des boutons</strong> d’un écran à l’autre.',
        rightAnswer: {
          title: "Bien joué, c'était bien <b>le principe d'homogénéité !</b>",
          imgSrc: Homogeneite2Good,
          imgCaption: "Version <b>respectant</b> le principe d'homogénéité"
        },
        wrongAnswer: {
          title: "Et non, c'était surtout <b>le principe d'homogénéité !</b>",
          imgSrc: Homogeneite2Bad,
          imgCaption: "Version <b>enfreignant</b> le principe d'homogénéité"
        }
      },
      {
        index: 4,
        answers: ['Groupement'],
        context: 'Menu latéral d’un logiciel de conception graphique.',
        details:
          'En effet, les sections étaient séparées uniquement par des titres en majuscule, ce qui réduit la compréhension de l’interface. Il est aisé de rendre plus claire l’organisation de l’écran en renforçant visuellement le  groupement des sections avec de l’espacement et un fond coloré.',
        rightAnswer: {
          title:
            "Bien joué, c'était bien <b>le principe de groupement par la localisation !</b>",
          imgSrc: GroupementGood,
          imgCaption:
            'Version <b>respectant</b> le principe de groupement par la localisation'
        },
        wrongAnswer: {
          title:
            "Et non, c'était surtout <b>le principe de groupement par la localisation !</b>",
          imgSrc: GroupementBad,
          imgCaption:
            'Version <b>enfreignant</b> le principe de groupement par la localisation'
        }
      },
      {
        index: 5,
        answers: ['Gestion des erreurs'],
        context:
          'Modale de confirmation avant suppression définitive de fichiers.',
        details:
          "En effet, les libellés des boutons d'action “Annuler” et “Ok” <b>sont non spécifiques</b>.\n <br>Pour limiter les erreurs, <b>il est recommandé de rappeler l'action dans le libellé</b>. Ainsi, même si l'utilisateur ne lit pas ou lit mal le message au dessus, <b>il connaît l'impact de son action</b>.\n <br>Il est possible d'améliorer l'ergonomie avec <b>des libellés plus explicites</b>, et <b>un design différent entre les 2 boutons</b> :\n - le bouton <b>principal</b> (= action attendue dans le parcours) plus gros et rouge pour alerter sur le caractère dangereux de l'action.\n - le bouton <b>secondaire</b> (= action de retour en arrière dans le parcours) plus petit et de couleur neutre.",
        rightAnswer: {
          title:
            "Bien joué, c'était bien <b>le principe de gestion des erreurs !</b>",
          imgSrc: GestionDesErreursGood,
          imgCaption:
            'Version <b>respectant</b> le principe de gestion des erreurs'
        },
        wrongAnswer: {
          title:
            "Et non, c'était surtout <b>le principe de gestion des erreurs !</b>",
          imgSrc: GestionDesErreursBad,
          imgCaption:
            'Version <b>enfreignant</b> le principe de gestion des erreurs'
        }
      },
      {
        index: 6,
        answers: ['Loi de Fitts'],
        context: 'Modale permettant de choisir le format d’export d’une image.',
        details:
          "En effet, <strong>les boutons d'action sont très éloignés du centre de l'écran et très petits</strong>, ce qui risque de générer un grand mouvement de souris et une difficulté à atteindre la cible. Il est possible d'améliorer l'ergonomie en modifiant cette version avec <strong>des boutons plus proches du centre</strong>, et des <strong>boutons d’action plus grands</strong>.",
        rightAnswer: {
          title: "Bien joué, c'était bien <b>la loi de Fitts !</b>",
          imgSrc: LoiDeFittsGood,
          imgCaption: 'Version <b>respectant</b> la loi de Fitts'
        },
        wrongAnswer: {
          title: "Et non, c'était surtout <b>la loi de Fitts !</b>",
          imgSrc: LoiDeFittsBad,
          imgCaption: 'Version <b>enfreignant</b> la loi de Fitts'
        }
      },
      {
        index: 7,
        answers: ['Groupement'],
        context:
          'Je suis au centre commercial et je recherche dans quelle direction aller pour trouver McDonald’s.',
        details:
          'En effet, en regardant vite on pouvait être amené à croire qu’il faut tourner à gauche pour trouver McDonald, car <strong>la flèche ← de la pharmacie était très proche du logo de McDonalds</strong>. \n' +
          'Il suffit de <strong>rapprocher les flèches des noms des enseignes</strong> pour régler le problème. \n\n' +
          'Pour un panneau encore <b>plus lisible</b>, on peut également regrouper la porte Oxygène des autres lieux pour lesquels il faut se diriger vers la gauche.',
        rightAnswer: {
          title:
            "Bien joué, c'était bien <b>le principe de groupement par la localisation !</b>",
          imgSrc: Q8Good,
          imgCaption:
            'Version <b>respectant</b> le principe de groupement par la localisation'
        },
        wrongAnswer: {
          title:
            "Et non, c'était surtout <b>le principe de groupement par la localisation !</b>",
          imgSrc: Q8bad,
          imgCaption:
            'Version <b>enfreignant</b> le principe de groupement par la localisation'
        }
      },
      {
        index: 8,
        answers: ['Actions minimales'],
        context:
          "Espace d'administration d'un outil permettant de construire des questionnaires en ligne.",
        details:
          "En effet, il est beaucoup <b>plus efficace de faire apparaître un lien vers la page permettant d'exporter les données</b> plutôt que d'indiquer à l'utilisateur comment se rendre dans cet espace.\nL'utilisateur <b>gagne ainsi du temps de recherche</b>, et <b>réduit sa charge de travail</b> en n'ayant pas besoin de mémoriser les étapes de navigation nécessaires.",
        rightAnswer: {
          title:
            "Bien joué, c'était bien <b>le principe d'actions minimales !</b>",
          imgSrc: ActionsMinimales2Good,
          imgCaption:
            "Version <b>respectant</b> le principe d'actions minimales"
        },
        wrongAnswer: {
          title:
            "Et non, c'était surtout <b>le principe d'actions minimales !</b>",
          imgSrc: ActionsMinimales2Bad,
          imgCaption:
            "Version <b>enfreignant</b> le principe d'actions minimales"
        }
      },
      {
        index: 9,
        answers: ['Loi de Fitts', 'Groupement'],
        context:
          'Application de rencontre permettant de consulter des profils et de rejeter ou liker chacun d’entre eux avant de passer au suivant.',
        details:
          'En effet, les deux boutons qui sont utilisés de manière successive et rapide (la croix et le cœur) devraient être <b>proches l’un de l’autre pour simplifier les déplacements de souris</b> entre les deux (Loi de Fitts) et car ce sont deux <b>boutons liés d’un point de vue fonctionnel</b> (principe de groupement par la localisation).',
        wrongAnswer: {
          title:
            'Et non, c’était surtout <b>la loi de Fitts, et le principe de groupement par la localisation&nbsp;!</b>',
          imgSrc: LoiDeFitts2Bad,
          imgCaption:
            'Version <b>enfreignant</b> la loi de Fitts et le principe de groupement par la localisation'
        },
        rightAnswer: {
          title:
            'Bien joué, que tu aies cliqué sur <b>Loi de Fitts</b> ou <b>Groupement</b>, c’est juste&nbsp;!',
          imgSrc: LoiDeFitts2Good,
          imgCaption:
            'Version <b>respectant</b> la loi de Fitts et le principe de groupement par la localisation'
        }
      }
    ],
    isAnswerRight: false,
    end: false,
    dialog: false,
    circleInfo: faInfoCircle,
    angleUp: faAngleUp,
    angleDown: faAngleDown,
    magnifyingGlass: faSearchPlus,
    isExplanationVisible: false,
    dialogIllustration: false,
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    questionAnswered: {}
  }),
  computed: {
    questionIndex() {
      return Number(this.$route.params.id);
    },
    question() {
      return this.questions[this.questionIndex];
    },
    isDesktop() {
      return this.windowWidth > 1000 && this.windowHeight > 500;
    },
    totalScore() {
      let s = 0;
      Object.values(this.questionAnswered).forEach(value => (s += value));
      return s;
    }
  },
  methods: {
    handleResponse(userAnswer, question) {
      const answer = question.answers.includes(userAnswer);
      this.questionAnswered[question.index] = answer ? 1 : 0;
      this.isAnswerRight = answer;
      document.activeElement.blur();
      this.dialog = true;
    },
    replay() {
      this.dialog = false;
      setTimeout(() => {
        this.end = false;
        this.questionAnswered = {};
        this.$router.push('/quiz/0');
      }, 150);
    },
    next() {
      const nextIndex = this.questionIndex + 1;
      if (this.questions[nextIndex]) {
        this.dialog = false;
        this.$router.push('/quiz/' + nextIndex);
      } else {
        this.end = true;
      }
    },
    togglePrinciples() {
      this.isExplanationVisible = !this.isExplanationVisible;

      if (this.isExplanationVisible) {
        window.setTimeout(() => {
          const element = document.getElementById('principle-button');
          window.scroll({
            top: element.offsetTop - 80,
            left: 0,
            behavior: 'smooth'
          });
        }, 1);
      } else {
        window.setTimeout(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }, 1);
      }
    },
    goHome() {
      this.$router.push('/');
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateWindowWidth);
    },
    showIllustrationDialog() {
      this.dialogIllustration = true;
    }
  },

  mounted() {
    this.$nextTick(function () {
      window.addEventListener('resize', this.updateWindowWidth);
    });
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors';

.col__header {
  display: flex;
  justify-content: end;
}

.game-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header__col {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  margin-right: 20px;

  &--phrase {
    max-width: 365px !important;
    margin: 0;
    @media (max-width: 575px) {
      font-size: 0.9rem;
    }
  }

  &--number-question {
    max-width: 60px;
    @media (max-width: 575px) {
      max-width: 50px;
      font-size: 0.9rem;
    }
  }
}

.quiz-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.quiz-content__question {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  flex-grow: 1;
  gap: 20px;
  height: auto;
  width: 90%;
  padding: 20px;
  margin: 0;
  transition: all 0.5s ease-out;

  @media (min-width: 575px) {
    height: calc(100vh - 290px);
  }

  @media (min-width: 2000px) {
    width: 60% !important;
  }

  @media (min-width: 1600px) {
    width: 80%;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 100%;
  }

  &-text {
    font-size: 1rem;
    color: black;
    margin: 0;

    @media (max-width: 700px) {
      font-size: 0.85rem;
    }
  }
}

.question__context {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  min-width: 250px;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
  background-color: white;

  @media (max-width: 700px) {
    padding: 20px;
    width: 100%;
  }

  &-text {
    font-size: 1rem;
    margin: 0;

    @media (max-width: 700px) {
      font-size: 0.85rem;
    }
  }

  hr {
    border: 2px solid #ffce00;
    border-radius: 3px;
    margin: 10px 0;

    @media (max-width: 575px) {
      margin: 5px 0;
    }
  }
}

.dialog-illustration {
  max-height: 100%;
  max-width: 100%;
  margin: 0 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;

  @media (max-width: 700px) {
    margin: 0;
  }
}

.quiz-content__question-illustration {
  position: relative;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 10px;

  @media (max-width: 1000px) {
    margin: 0;
  }
}

.magnifying-glass {
  position: absolute;
  top: -10px;
  right: -10px;
  color: white;
  padding: 0.5em 1em;
  background-color: $neutral-blue;
  border-radius: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.image-container {
  max-height: 100%;
  max-width: 100%;
  position: relative;
}

.illustration__image {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.1);

  @media (max-width: 575px) {
    width: 100%;
  }
}
.illustration__image-zoom {
  object-fit: contain;
  max-height: calc(100vh - 200px);
}

.quiz-content__answer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  width: 100%;
  gap: 30px;
  background-color: $neutral-blue;
  transition: height 0.5s ease-out;
}

.quiz-content__answer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 70%;
  height: 100%;

  @media (max-width: 575px) {
    width: 100%;
    height: 100%;
  }
}

.answer__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 220px;
  transition: transform 0.2s;

  @media (max-width: 700px) {
    width: 175px;
  }

  @media (max-width: 410px) {
    width: 150px;
  }

  &:hover {
    transform: scaleY(0.97);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  }

  p {
    color: black;
    font-weight: 600;
    margin: 0;

    @media (max-width: 700px) {
      font-size: 0.7rem;
    }

    @media (max-width: 410px) {
      font-size: 0.6rem;
    }
  }

  img {
    margin-right: 7px;
    height: 25px;

    @media (max-width: 575px) {
      height: 20px;
    }
  }
}

.principle__button {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 10px 15px;
  background-color: $neutral-yellow;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #0a5e5c;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  transition: color 0.5s, background-color 0.5s, transform 0.5s;

  @media (max-width: 575px) {
    font-size: 0.7rem;
  }

  @media (max-width: 410px) {
    font-size: 0.6rem;
  }

  &--expend {
    background-color: white;
  }
}

.principle__button-info {
  width: 10%;

  &--expend {
    color: $neutral-yellow;
  }
}

.result-modal__divider {
  background: white;
}

.principle-toggle {
  overflow: inherit;
  padding: 2rem 2rem 0;
  max-width: 900px;

  @media (max-width: 575px) {
    padding: 1rem 1rem 0;
  }
}

.reminder {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  &__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #0a5e5c;
    gap: 10px;

    @media (max-width: 575px) {
      font-size: 1rem;
      margin-bottom: 16px;
    }

    &-image {
      height: 30px;
    }

    &-text {
      margin: 0;
    }
  }

  &__text {
    font-size: 1rem;
    color: black;
    margin: 0;

    @media (max-width: 575px) {
      font-size: 0.8rem;
    }
  }
}
</style>
