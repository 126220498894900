<template>
  <div>
    <div class="row content justify-content-center m-auto rule-title">
      <h1>Sauve qui peut&nbsp;!</h1>
      <h2 class="m-0">
        Aide Colin le poulpe à sauver ses amis&nbsp;!
      </h2>
    </div>
    <div class="row content m-auto rule-content pt-0">
      <p>
        Les 10 amis de Colin sont en train de mourir à cause de la pollution aquatique. <br> Aide-le à faire passer ses amis <b>le plus vite possible </b> de l'autre côté de la barrière de corail en les faisant glisser jusqu'à l'eau claire (drag & drop).<br>
      </p>
      <p>
      Attention au chronomètre, tu ne voudrais pas terminer trop tard !
      </p>
      <v-row class="align-items-center">
        <div class="align-items-center col-xs-12 col-md-6 col-lg-7">
          <img class="illust w-100" src="../../../assets/img/rule-poulpe.png" alt=""/>
        </div>
        <div class="align-items-center col-xs-12 col-md-6 col-lg-5">
          <button @click="play" class="primary-btn">Jouer</button>
        </div>
      </v-row >
    </div>
  </div>
</template>
<script>
export default {
  name: "poulpeRule",
  methods: {
    play() {
      this.$router.push("/game/poulpe");
    },
  },
};
</script>
<style lang="scss" scoped>
.poulpe-rule-img {
  width: 100%;
}

.modal-dialog .modal-body .content.rule-content{
  padding-top:0;
}

.illust{
  margin: auto;
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
}
</style>
