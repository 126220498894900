<template>
  <div class="principles">
    <home-header></home-header>
    <div class="title">
      <v-container>
        <h1>Principes</h1>
        <p>Explications des principes démontrés dans les jeux</p>
      </v-container>
    </div>
    <div class="list">
      <v-container>
        <v-row>
          <v-col
            class="element"
            lg="4"
            md="4"
            sm="6"
            v-for="principle in principles"
            :key="principle.name"
          >
            <button
              @click="openPrinciple(principle.page)"
              class="btn-principle m-auto"
            >
              <div class="btn-div">
                <center>
                  <img :src="principle.img" :alt="principle.imgAlt" />
                </center>
                <p>{{ principle.name }}</p>
              </div>
            </button>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="soon">
      <v-container>
        <p>Bientôt de nouveaux principes...</p>
        <router-link class="contact-link" to="/#contact"
          >Je veux être informé&#xB7;e&nbsp;!</router-link
        >
      </v-container>
    </div>
    <main role="main">
      <router-view />
      <credits></credits>
    </main>
  </div>
</template>

<script>
import Credits from '../CreditsFooter.vue';
import homogeneityImg from '../../assets/img/principles/homogeneity.png';
import errorHandlingImg from '../../assets/img/principles/Error-Principle.png';
import hickImg from '../../assets/img/principles/hick2.png';
import HomeHeader from '../AppHeader.vue';
import minimumActionsImg from '../../assets/img/principles/minimum-action-home.png';
import FittsImg from '../../assets/img/principles/Fitts-principle.png';
import groupingImg from '../../assets/img/principles/grouping.png';

export default {
  name: 'Home-view',
  components: {
    HomeHeader,
    Credits
  },
  data: () => ({
    principles: [
      {
        name: 'Homogénéité',
        page: '/principles/homogeneity',
        img: homogeneityImg,
        imgAlt: 'afficher le détail du principe Homogénéité'
      },
      {
        name: 'Gestion des erreurs',
        page: '/principles/error-handling',
        img: errorHandlingImg,
        imgAlt: 'afficher le détail du principe Gestion des erreurs'
      },
      {
        name: 'Loi de Hick',
        page: '/principles/hick',
        img: hickImg,
        imgAlt: 'afficher le détail du principe Loi de Hick'
      },
      {
        name: 'Actions minimales',
        page: '/principles/minimum-actions',
        img: minimumActionsImg,
        imgAlt: 'afficher le détail du principe Actions minimales'
      },
      {
        name: 'Loi de Fitts',
        page: '/principles/fitts',
        img: FittsImg,
        imgAlt: 'afficher le détail du principe Loi de Fitts'
      },
      {
        name: 'Groupement par la localisation',
        page: '/principles/proximity-law',
        img: groupingImg,
        imgAlt: 'afficher le détail du principe Groupement par proximité'
      }
    ]
  }),
  methods: {
    openPrinciple(principle) {
      this.$router.push(principle);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/colors';
@import '../../assets/scss/zones';
@import '../../assets/scss/buttons';

.principles {
  .title {
    @extend %title-zone;
    min-height: 150px;
    padding-top: 100px;
    padding-bottom: 30px;
    text-align: center;

    h1 {
      font-weight: 700;
    }
  }

  .list {
    @extend %content-zone;
    padding-top: 30px;
    padding-bottom: 50px;

    .element {
      margin-top: 20px;
      @media screen and (max-width: 576px) {
        margin-top: 50px;
      }

      .btn-principle {
        width: 220px;
        padding: 15px 12px 6px 12px;
        @extend %content-button;

        p {
          margin-top: 10px;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    img {
      max-width: 100%;
    }
  }

  .soon {
    @extend %third-zone;

    p {
      font-weight: 500;
      font-size: 1.25rem;
      text-align: center;
      margin-bottom: 1.25rem;
    }

    padding-top: 50px;
    padding-bottom: 20px;
    margin-bottom: 90px;

    .contact-link {
      @extend %action-button;
      width: 230px;
    }
  }
}
</style>
