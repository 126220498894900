<template>
  <div class="legal-terms">
    <home-header></home-header>
    <div class="title">
      <v-container>
        <h1>Mentions légales</h1>
      </v-container>
    </div>
    <div class="content">
      <v-container>
        <h1></h1>
        <h2>
          Ce site web est édité par&nbsp;:
        </h2>
        <div class="indent">
          <p>Sogilis<br>
            45 Rue Sainte-Geneviève,<br>
            69006 Lyon<br>
            France
          </p>
          <p>
          N° SIRET&nbsp;: 50201207300040<br>
          Immatriculée au Registre du Commerce et des Sociétés de Grenoble sous le n° RCS 502 012 073<br>
          Numéro de TVA intra-communautaire&nbsp;: FR69502012073<br>
          SAS au capital de 11736 €
          </p>
        </div>
        <h2>
          Contact&nbsp;:
        </h2>
        <div class="indent">
          <p>
            Email&nbsp;: <a class="basic_link" href="mailto:contact@uxshadow.com">contact@uxshadow.com</A><br>
            Numéro de téléphone&nbsp;: <a class="basic_link" href="tel:+33768256095">+33 (0)7 68 25 60 95</a><br>
            Directeur légal de la publication&nbsp;: Laurent Mangue<br>
          </p>
        </div>
        <h2>Hébergeur&nbsp;: 
        </h2>
        <div class="indent">
          <p><a class="basic_link" href="https://www.netlify.com" target="_blank" rel="noopener noreferrer">Netlify</a><br>
            MakerLoop, Inc - 2325 3rd Street, Suite 215 - San Francisco, CA. 94107
          </p>
        </div>
        <h2>Licence&nbsp;: 
        </h2>
        <div class="indent">
          <div class="img-licence">
            <a rel="license" class="basic_link" href="http://creativecommons.org/licenses/by-nc-sa/4.0/"><img alt="Licence Creative Commons" style="border-width:0" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png" /></a>
          </div>
          <p>
            <a class="basic_link" href="https://www.ergogames.fr" target="_blank" rel="noopener noreferrer">Les Ergogames</a> de <a class="basic_link" href="https://sogilis.com/" target="_blank" rel="noopener noreferrer">Sogilis</a> et <a class="basic_link" href="https://uxshadow.com/" target="_blank" rel="noopener noreferrer">uxShadow</a> sont mis à disposition selon les termes de la <a class="basic_link" rel="license noopene noreferrer" target="_blank" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Licence Creative Commons Attribution - Pas d’Utilisation Commerciale - Partage dans les Mêmes Conditions 4.0 International</a>.
          </p>
        </div>
        
      </v-container>
    </div>
  </div>
</template>

<script>
import HomeHeader from './AppHeader.vue';
export default {
  components: {
    HomeHeader,
  },
  mounted() {
    window.scrollTo({ top: 0 });
  }
}
</script>


<style lang="scss">
@import '../assets/scss/zones';


.legal-terms {
  padding-bottom: 50px;
  .title {
  @extend %title-zone;
  min-height: 150px;
    padding-top: 100px;
    padding-bottom: 30px;
    text-align: center;
    h1 {
      font-weight: 700;
    }
  }
  h2 {
    font-size: 1.3em;
    color: #0a5e5c;
    margin-top: 2em;
  }
  .content{
    .indent {
      margin-left: 1em;
    }
    .img-licence {
    margin: 0.8em 0 0.8em 0;
    }
  }
} 

</style>

