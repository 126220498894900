import {
  createEntity,
  addEntity,
  getEntity,
  setEntityComponent,
  removeEntityComponent,
  removeEntity,
  setEntityExec,
  removeEntityExec,
} from "../entity_system";

import artifacts from "./artifacts";

import {
  STEP_GRAB_PESTO_AND_MUSHR,
  STEP_SPREAD_PESTO_AND_MUSHR,
  STEP_GRAB_PIZZA_1,
  STEP_PUT_PIZZA_IN_OVEN,
  STEP_BAKE_PIZZA,
  STEP_GRAB_PIZZA_2,
  STEP_DELIVER_PIZZA,
  STEP_FINISH,
} from "./steps";

export function rollOutDough(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_GRAB_PESTO_AND_MUSHR });
  updatedStore = setEntityExec(updatedStore, 'pesto', grabPesto, 'Attraper');
  updatedStore = setEntityExec(updatedStore, 'mushr', grabMushr, 'Attraper');
  updatedStore = setEntityComponent(updatedStore, 'pizza', { look: 'dough' });
  updatedStore = removeEntityExec(updatedStore, 'pizza');
  return updatedStore;
}

export function grabPesto(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'grabbedPesto', { value: true });
  updatedStore = removeEntity(updatedStore, 'pesto');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'pesto' });

  const grabbedMushr = getEntity(store, 'grabbedMushr').value;
  if (grabbedMushr) {
    updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'pestoMushr' });
    updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_SPREAD_PESTO_AND_MUSHR });
    updatedStore = setEntityExec(updatedStore, 'pizza', spreadPestoAndMushr, 'Étaler');
  }

  return updatedStore;
}

export function grabMushr(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'grabbedMushr', { value: true });
  updatedStore = removeEntity(updatedStore, 'mushr');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'mushr' });

  const grabbedPesto = getEntity(store, 'grabbedPesto').value;
  if (grabbedPesto) {
    updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'pestoMushr' });
    updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_SPREAD_PESTO_AND_MUSHR });
    updatedStore = setEntityExec(updatedStore, 'pizza', spreadPestoAndMushr, 'Étaler');
  }

  return updatedStore;
}

export function spreadPestoAndMushr(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_GRAB_PIZZA_1 });
  updatedStore = setEntityComponent(updatedStore, 'pizza', { look: 'mushr' });
  updatedStore = setEntityExec(updatedStore, 'pizza', grabUncookedPizza, 'Attraper');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'normal' });
  return updatedStore;
}

export function grabUncookedPizza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_PUT_PIZZA_IN_OVEN });
  updatedStore = setEntityExec(updatedStore, 'oven', putPizzaInOven, 'Mettre au four');
  updatedStore = removeEntity(updatedStore, 'pizza');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'pizza' });
  return updatedStore;
}

export function putPizzaInOven(store, position) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_BAKE_PIZZA });
  updatedStore = setEntityComponent(updatedStore, 'oven', {
    tickCount: 5,
    label: '5',
    tick: tickOven,
  });
  updatedStore = removeEntityExec(updatedStore, 'oven');
  updatedStore = addEntity(updatedStore, createEntity(artifacts.pizza, {
    position,
    look: 'profile',
  }));
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'normal' });
  return updatedStore;
}

export function tickOven(store) {
  const oven = getEntity(store, 'oven');
  const newTickCount = oven.tickCount - 1;

  let updatedStore = store;
  if (newTickCount === 0) {
    updatedStore = setEntityComponent(updatedStore, 'oven', { tickCount: 0 });
    updatedStore = removeEntityComponent(updatedStore, 'oven', 'tick');
    updatedStore = removeEntityComponent(updatedStore, 'oven', 'label');
    updatedStore = setEntityExec(updatedStore, 'pizza', grabCookedPizza, 'Attraper');
    updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_GRAB_PIZZA_2 });
  } else {
    updatedStore = setEntityComponent(updatedStore, 'oven', {
      tickCount: newTickCount,
      label: `${newTickCount}`,
    });
  }
  return updatedStore;
}

export function grabCookedPizza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_DELIVER_PIZZA });
  updatedStore = setEntityExec(updatedStore, 'hatch', deliverPizza, 'Livrer');
  updatedStore = removeEntity(updatedStore, 'pizza');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'pizza' });
  return updatedStore;
}

export function deliverPizza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_FINISH });
  updatedStore = setEntityComponent(updatedStore, 'running', { value: false });
  return updatedStore;
}
