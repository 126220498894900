<template>
  <social-media-icon>
    <svg version="1.1"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
      x="0px" y="0px" width="30px" height="30px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50; fill: #0a5e5c; margin: 15px 10px 0px 10px;" xml:space="preserve">
      <defs>
      </defs>
      <path id="linkedin" class="st0" d="M42.7,7.3C38.2,2.8,31.9,0,25,0S11.8,2.8,7.3,7.3S0,18.1,0,25s2.8,13.2,7.3,17.7S18.1,50,25,50
        s13.2-2.8,17.7-7.3S50,31.9,50,25S47.2,11.8,42.7,7.3z M19,24.3v11.9h-5.3v-11v-4H19V24.3z M16.3,19c-1.5,0-2.6-1.2-2.6-2.6
        c0-1.5,1.2-2.6,2.6-2.6c1.5,0,2.6,1.2,2.6,2.6C19,17.8,17.8,19,16.3,19z M36.3,36.3h-4.5V29c0-1.8-0.2-4-2.6-4s-2.8,1.9-2.8,3.9v7.5
        H22V24.1v-2.9h4.2v2h0.1c0.6-1.2,2.2-2,4.4-2c3.8,0,5.1,1.8,5.5,4.6c0.1,0.7,0.1,1.4,0.1,2.2V36.3z"/>
    </svg>
  </social-media-icon>
</template>

<script>
import SocialMediaIcon from './SocialMediaIcon.vue';

export default {
  components: {
    SocialMediaIcon,
  }  
}
</script>
