<template>
  <principle
    principle="Gestion des erreurs"
    game="charade"
    gameName="Huuum Charade"
    nextGame="CherryOnTheCake"
    bigTitle="true"
  >
    <template v-slot:main-content>
      <div class="introduction">
        <h2 class="introduction__title">
          Le critère "Gestion des erreurs" est décrit ainsi par Bastien & Scapin
          (1993)&#8239;*&#8239;:
        </h2>
        <p class="m-0 font-italic">
          Moyens permettant d'une part d&apos;<b
            >éviter ou de réduire les erreurs</b
          >, d&apos;autre part de <b>les corriger lorsqu'elles surviennent.</b>
        </p>
      </div>

      <div class="section">
        <h2 class="section__title">Application de la loi</h2>
        <p>La gestion des erreurs s'articule autour de trois axes&#8239;:</p>
        <ul class="section__list">
          <li>
            <b>Protection des erreur&nbsp;:</b> des éléments de guidage doivent
            être mis en place pour prévenir les erreurs, et celles-ci doivent
            être détectées le plus tôt possible.
          </li>
          <li>
            <b> Qualité des messages d'erreurs&nbsp;:</b> le message informant
            l'utilisateur qu'une erreur s'est produite doit être pertinent,
            facile à lire et exact.
          </li>
          <li>
            <b>Correction des erreurs&nbsp;:</b> une erreur doit toujours
            pouvoir se corriger facilement.
          </li>
        </ul>
      </div>

      <principle-practices>
        <template v-slot:good-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <label for="inputLiveRight">Date de naissance (jj/mm/aaaa)</label>
              <v-text-field
                class="input-exemple"
                id="inputLiveRight"
                type="text"
                :state="false"
                aria-describedby="inputLiveFeedback"
                value="12 janvier 1982"
                disabled
              >
              </v-text-field>
              <span class="error">
                Le format attendu est le suivant&#8239;: jj/mm/aaaaa, par
                exemple 30/10/1955
              </span>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="rightIcon"
                class="valid-icon"
                size="2x"
              />
              <span>Bonne pratique</span>
            </p>
          </div>
        </template>
        <template v-slot:bad-practice>
          <div class="practice-container">
            <div class="practice-container__illustration">
              <label for="inputLiveWrong">Date de naissance </label>
              <v-text-field
                class="input-exemple input-exemple--second"
                id="inputLiveWrong"
                type="text"
                value="12 janvier 1982"
                disabled
              >
              </v-text-field>
              <span class="error error--second"> Error </span>
            </div>
            <p class="practice-container__caption">
              <font-awesome-icon
                :icon="wrongIcon"
                class="invalid-icon"
                size="2x"
              />
              <span> Mauvaise pratique </span>
            </p>
          </div>
        </template>
      </principle-practices>

      <div class="section">
        <p>
          Malheureusement, il n'est pas rare d'être confronté à un formulaire
          qui gère mal les erreurs, par exemple&#8239;:
        </p>
        <ul class="section__list">
          <li>Restrictions non indiquées quand on choisit un mot de passe.</li>
          <li>
            Messages d'erreur composés de mots incompréhensibles pour les
            utilisateurs, tels que "request", "XML", ou encore "Javascript".
          </li>
          <li>
            Manque de visibilité sur la localisation de l'erreur dans le
            formulaire.
          </li>
        </ul>
      </div>

      <p class="section">
        <strong>
          Le principe semble simple et pourtant, combien de fois avez-vous dû
          chercher un champ obligatoire que vous aviez oublié de remplir dans un
          formulaire&nbsp;?
        </strong>
      </p>
    </template>

    <template v-slot:game-explanation>
      <div class="game-explanation mb-4">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/erreur1.png"
                alt="extrait du champ à saisir dans le premier jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="wrongIcon"
                  class="invalid-icon"
                  size="2x"
                />
                <span> Mauvaise pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            Dans le 1er jeu, le critère gestion des erreurs n’est pas respecté
            car&#8239;:
            <ul class="section__list">
              <li>aucun élément ne permet de prévenir l'erreur,</li>
              <li>
                le message d'erreur ne permet pas de comprendre l'origine de
                l'erreur,
              </li>
              <li>
                la correction est impossible&#8239;: on ne peut modifier la
                réponse.
              </li>
            </ul>
          </div>
        </v-col>
      </div>
      <div class="game-explanation">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/erreur2.png"
                alt="extrait du champ à saisir dans le second jeu"
              />
              <div
                class="d-flex justify-content-center align-items-center mt-2"
              >
                <font-awesome-icon
                  :icon="rightIcon"
                  class="valid-icon"
                  size="2x"
                />
                <span> Bonne pratique </span>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Pour fluidifier l’expérience, comme c’est le cas dans le 2nd jeu,
              il suffit&#8239;:
            </p>
            <ul class="section__list">
              <li>
                d'indiquer le format attendu et de procéder à une vérification
                automatique du format pour prévenir l'erreur,
              </li>
              <li>
                de détailler l'origine du problème en cas d'erreur, dans notre
                cas en donnant un exemple du format attendu,
              </li>
              <li>d'autoriser la correction une fois l'erreur expliquée.</li>
            </ul>
          </div>
        </v-col>
      </div>
    </template>

    <template v-slot:note>
      <div class="note">
        <p>
          * Référence&#8239;: Critères Ergonomiques pour l'Évaluation
          d'Interfaces Utilisateurs (version 2.1), J. M. Christian Bastien and
          Dominique L. Scapin, INRIA, Technical report N° 156 Mai 1993
          <a
            href="https://hal.inria.fr/inria-00070012"
            target="_blank"
            rel="noopener noreferrer"
            >[Accès document complet - Site web INRIA]</a
          >
        </p>
        <p>
          Dans cet article, les éléments de guidage permettant de réduire le
          risque d'erreur (format de date par exemple) ne font pas partie du
          sous-critère Protection contre les erreurs mais du sous-critère
          Incitation. Néanmoins, nous estimons que cela participe à la
          protection contre les erreurs, et avons donc choisi de le présenter
          ici.
        </p>
      </div>
    </template>
  </principle>
</template>

<script>
import Principle from './layout/PrinciplePage.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import PrinciplePractices from './layout/PrinciplePractices.vue';

export default {
  name: 'ErrorHandlingPrinciple',
  components: {
    Principle,
    PrinciplePractices,
    FontAwesomeIcon
  },
  computed: {
    rightIcon() {
      return faCheck;
    },
    wrongIcon() {
      return faTimes;
    }
  }
};
</script>

<style scoped lang="scss">
@import './layout/principle-page-content.scss';

@import '../../assets/scss/colors';
@import '../../assets/scss/zones';

#inputLiveWrong:disabled,
#inputLiveRight:disabled {
  background-color: white;
}

.input-exemple {
  height: 58px;
  border: 2px solid #dc3545;

  &--second {
    border-color: #515254;
  }
}

.error {
  display: block;
  color: #dc3545;
  border-color: #f5c6cb;

  &--second {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    border-radius: 0.25rem;
    text-align: center;
  }
}
</style>
