<template>
  <div class="container-fluid home_zone_4">
    <div class="container">
      <h2 class="title_level_3">À l'origine du projet</h2>
      <div class="row justify-content-center">
        <div class="col-6 col-sm-6 col-md-6 col-lg-3">
          <center>
            <div class="image margaux"></div>
            <div class="team-name">Margaux Perrin</div>
            <a href="https://twitter.com/margauxlergo?lang=fr" target="_blank" rel="noopener noreferrer" aria-label="twitter Margaux">
              <twitter></twitter>
            </a>
            <a href="https://www.linkedin.com/in/margaux-perrin-264aa250/?locale=fr_FR" target="_blank" rel="noopener noreferrer" class="social_icons" aria-label="linkedin Margaux">
              <linkedin></linkedin>
            </a>
          </center>
        </div>

        <div class="col-6 col-sm-6 col-md-6 col-lg-3">
          <center>
            <div class="image marion"></div>
            <div class="team-name">Marion Georges</div>
            <a href="https://twitter.com/mgeorges_?lang=fr" target="_blank" rel="noopener noreferrer" aria-label="twitter Marion">
              <twitter></twitter>
            </a>
            <a href="https://www.linkedin.com/in/marion-georges-2a42b9107/" target="_blank" rel="noopener noreferrer" class="social_icons" aria-label="linkedin Marion">
              <linkedin></linkedin>
            </a>
          </center>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Twitter from './footer/TwitterIcon.vue';
import Linkedin from './footer/LinkedinIcon.vue';

export default {
  components: {
    Twitter,
    Linkedin,
  }
}
</script>


<style lang="scss">
@import "../../assets/scss/colors.scss";
.home_zone_4 {
  background-color: $ultra-light-grey;
  padding-bottom: 30px;

  .title_level_3 {
    color: $neutral-grey;
    text-align: center;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .title_level_3::after {
    background-color: $neutral-grey;
  }

  .image {
    background-size: cover;
    width: 130px;
    height: 130px;
    border-radius: 100%;
    margin-bottom: 30px;
    @media screen and (max-width: 767px) {
      width: 80px;
      height: 80px;
      margin-bottom: 15px;
    }

    &.margaux {
      background-image: url("../../assets/img/margaux.png");
    }
    &.marion {
      background-image: url("../../assets/img/marion.jpg");
    }
  }

  .team-name {
    font-weight: 600;
    color: $neutral-grey;
    font-size: 1em;
  }
}
</style>
