<template>
  <div v-if="isDesktop" class="cash-register">
    <img src="@/assets/img/onTheDouble/illustrations/shopping-list-illustration.svg" alt="cash-register-image" class="cash-register__image"/>
    <div class="cash-register__items">
      <img v-for="(item, index) in itemsToShow" :key="index" :src="retrieveImage(item)" :alt="item" @load="isImageLoaded[index] = true" class="item__image" :class="{ 'image-loaded': isImageLoaded[index] }"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "shoppingImagesList",

  props: {
    itemsToShow: Array,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      isImageLoaded: [],
    };
  },

  computed: {
    isDesktop() {
      return this.windowWidth > 1050;
    },
  },

  methods: {
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    retrieveImage(imageName) {
      return require(`@/assets/img/onTheDouble/illustrations/${imageName.toLowerCase()}-illustration.svg`);
    },
  },

  mounted() {
    window.addEventListener('resize', this.updateWindowWidth);
  },
};
</script>

<style lang="scss" scoped>
.cash-register {
  max-width: 320px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cash-register__image {
  width: 100%;
  height: auto;
}

.cash-register__items {
  position: absolute;
  top: 30px;
  left: 145px;
  display: flex;
  flex-direction: row;
  gap: 10px;


}

.item__image {
  height: 30px;
}

.cash-register__items .item__image.image-loaded {
  animation: slideInFromRight 1s forwards;
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>