<template>
  <div>
    <div class="row content content--padding-top m-auto pb-0">
      <h1>
        Bienvenue sur le Quiz Ergogames &nbsp;!
      </h1>
    </div>

    <div class="row content m-auto pt-0 rule-content">
      <p>
        Dans ce quiz, 10 écrans de solutions numériques réelles ou fictives te seront présentés, et il
        te faudra <strong>identifier le principe enfreint</strong> !<br>
        <strong>Il n'y a aucune interaction</strong>, ce ne sont que des images d'écrans à analyser.<br><br>

        Prêt à relever le défi ?
      </p>

      <p class="mobile-only">
        L'expérience est meilleure sur desktop, n'hésite pas à zoomer sur les images
        ou à tourner ton téléphone en mode paysage.
      </p>

      <v-row class="m-auto">
        <button @click="play" class="primary-btn">C'est parti !</button>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  name: "QuizRule",
  methods: {
    play() {
      this.$router.push("/quiz/0");
    },
  },
};
</script>
<style lang="scss">
.content--padding-top {
  padding-top: 10px !important;
}

.poulpe-rule-img {
  width: 100%;
}

.modal-dialog .modal-body .content.rule-content {
  padding-top: 0;
}

.mobile-only {
  @media screen and (min-width: 700px) {
    display: none;
  }
}
</style>
  