<template>
  <div id="home">
    <home-header></home-header>
    <home-title></home-title>
    <home-games></home-games>
    <main role="main">
      <router-view/>
      <credits></credits>
    </main>
  </div>
</template>

<script>
import HomeHeader from '../AppHeader.vue';
import HomeTitle from './HomeTitle.vue';
import HomeGames from './HomeGames.vue';
import Credits from '../CreditsFooter.vue'

export default {
  name: "Home",
  components: {
    Credits,
    HomeHeader,
    HomeTitle,
    HomeGames,
  }
};
</script>
