<template>
  <div>
    <div class="row content justify-content-center m-auto rule-title">
      <h1>À vous les pizzas&nbsp;!</h1>
      <h2 class="m-0">Aide Meiko à gagner le concours de pizzaïolo&nbsp;!</h2>
    </div>
    <div class="row content m-auto rule-content pt-0">
      <p>
        Le gagnant est celui qui réussira la recette le plus rapidement possible.
      </p>
      <p>
        Clique sur une des cases adjacentes à Meiko pour le déplacer de case en case afin de récupérer les ingrédients.
        Clique sur un élément lorsqu’il se trouve à proximité pour l’attraper ou effectuer une action dessus.
      </p>
      <p>
        Attention au chronomètre, tu ne voudrais pas terminer dernier&nbsp;!
      </p>
      <v-row class="align-items-center w-100">
        <div class="col-xs-12 col-md-6 text-center rule-illustration illust">
          <img src="@/assets/img/rule-pizza.png" height=200 alt=""/>
        </div>
        <div class="texte col-xs-12 col-md-6">
          <button @click="play" class="primary-btn">Jouer</button>
        </div>
      </v-row >
    </div>
  </div>
</template>

<script>
export default {
  name: "PizzasRule",

  methods: {
    play() {
      this.$router.push("/game/pizzas");
    },
  },
};
</script>

<style scoped>
  .illust{
    margin: 1.25rem auto 1.5rem;
  }
</style>
