export default {
  oven: {
    id: 'oven',
    size: { width: 2, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/four.svg'),
  },

  hatch: {
    id: 'hatch',
    size: { width: 1, height: 0.25 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/passeplat.svg'),
  },

  fridge: {
    id: 'fridge',
    size: { width: 2, height: 2 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/refrigirateur.svg'),
  },

  workplan: {
    id: 'workplan',
    size: { width: 1, height: 2 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/plan-travail.svg'),
  },

  shelf: {
    id: 'shelf',
    size: { width: 1, height: 4 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/etagere-verticale.svg'),
  },

  pizza: {
    id: 'pizza',
    size: { width: 1, height: 1 },
    obstruct: true,
    look: 'doughBall',
    assets: {
      doughBall: require('@/assets/img/pizzas/pate-pizza-boule.png'),
      dough: require('@/assets/img/pizzas/pate-pizza.svg'),
      pesto: require('@/assets/img/pizzas/pate-pesto.svg'),
      mushr: require('@/assets/img/pizzas/pizza2.svg'),
      profile: require('@/assets/img/pizzas/pizza2-plat.svg'),
    },
  },

  mushr: {
    id: 'mushr',
    size: { width: 1, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/pot-champignons.svg'),
  },

  pesto: {
    id: 'pesto',
    size: { width: 1, height: 1 },
    obstruct: true,
    asset: require('@/assets/img/pizzas/sauce-pesto.svg'),
  },

  meiko: {
    id: 'meiko',
    size: { width: 1, height: 1 },
    obstruct: true,
    direction: 'below',
    look: 'normal',
    assets: {
      normal: {
        below: require('@/assets/img/pizzas/meiko-face.svg'),
        right: require('@/assets/img/pizzas/meiko-droite.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
      mushr: {
        below: require('@/assets/img/pizzas/meiko-face-champi.svg'),
        right: require('@/assets/img/pizzas/meiko-droite-champi.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche-champi.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
      pesto: {
        below: require('@/assets/img/pizzas/meiko-face-pesto.svg'),
        right: require('@/assets/img/pizzas/meiko-droite-pesto.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche-pesto.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
      pestoMushr: {
        below: require('@/assets/img/pizzas/meiko-face-pesto-champi.svg'),
        right: require('@/assets/img/pizzas/meiko-droite-pesto-champi.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche-pesto-champi.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
      pizza: {
        below: require('@/assets/img/pizzas/meiko-face-pizza2.svg'),
        right: require('@/assets/img/pizzas/meiko-droite-pizza2.svg'),
        left: require('@/assets/img/pizzas/meiko-gauche-pizza2.svg'),
        top: require('@/assets/img/pizzas/meiko-dos.svg'),
      },
    }
  },
};
