<template>
  <v-row class="row content justify-content-center m-auto">
    <v-col class="texte d-flex flex-column align-items-center col-xs-12 col-md-6">
      <h1>Huuum Charade&nbsp;!</h1>
      <h2>Connais-tu bien Nestor&nbsp;?</h2>
      <p>
        Résous les charades pour le découvrir.
      </p>
      <div class="text-center col-xs-12 col-md-10 charade-rule-button">
        <p>À toi de jouer&nbsp;!</p>
        <button @click="play" class="primary-btn">Jouer</button>
      </div>
    </v-col>
    <v-col class="col-xs-12 col-md-6 text-center rule-img">
      <img src="../../../assets/img/tortue.png" height=250 alt=""/>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "CatchThemAllRule",
  methods: {
    play() {
      this.$router.push("/game/charade");
    }
  }
};
</script>

<style lang="scss">
.rule-img {
  @media screen and (max-width: 576px) {
    padding-top: 3rem;
  }
}

.charade-rule-button {
  height: 50px !important;
}
</style>

