import {
  getEntity,
  searchEntitiesAt,
  setEntityComponent,
  removeEntityComponent,
} from "./entity_system";

export function onTick(store) {
  const ticks = getEntity(store, 'ticks').value;
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'ticks', { value: ticks + 1 });
  store.forEach((entity) => {
    if (typeof entity.tick === 'function') {
      updatedStore = entity.tick(updatedStore);
    }
  });
  return updatedStore;
}

export function onHover(e, store) {
  let updatedStore = removeHaloNextMoves(store);

  e.preventDefault();
  const { boardSize } = getEntity(updatedStore, 'constants');
  const cellSize = e.target.offsetWidth / boardSize;
  const x = Math.ceil(e.layerX / cellSize);
  const y = Math.ceil(e.layerY / cellSize);
  return showHaloAt(updatedStore, { x, y });
}

function removeHalo(store) {
  let updatedStore = store;
  updatedStore = removeEntityComponent(updatedStore, 'halo', 'position');
  updatedStore = removeEntityComponent(updatedStore, 'halo', 'label');
  return updatedStore;
}

function removeHaloNextMoves(store) {
  let updatedStore = store;
  updatedStore = removeEntityComponent(updatedStore, 'haloNextMoveTop', 'position');
  updatedStore = removeEntityComponent(updatedStore, 'haloNextMoveRight', 'position');
  updatedStore = removeEntityComponent(updatedStore, 'haloNextMoveBottom', 'position');
  updatedStore = removeEntityComponent(updatedStore, 'haloNextMoveLeft', 'position');
  return updatedStore;
}

function showHaloAt(store, position) {
  // First, we check the position is inside the board.
  const { boardSize } = getEntity(store, 'constants');
  if (
    position.x < 1 ||
    position.x > boardSize ||
    position.y < 1 ||
    position.y > boardSize
  ) {
    return removeHalo(store);
  }

  // Then, that the position is accessible to Meiko
  const meiko = getEntity(store, 'meiko');
  if (
    !positionIsAccessibleFor(meiko, position) ||
    (meiko.position.x === position.x && meiko.position.y === position.y)
  ) {
    return removeHalo(store);
  }

  // And that no entities is obstructing the way (but it's ok if an entity has
  // an `exec` function)
  const entitiesAtPosition = searchEntitiesAt(store, position);
  if (
    entitiesAtPosition.some((entity) => entity.obstruct) &&
    !entitiesAtPosition.some((entity) => typeof entity.exec === 'function')
  ) {
    return removeHalo(store);
  }

  const tip = entitiesAtPosition
    .filter((entity) => entity.tip != null)
    .map((entity) => entity.tip)
    .join('. ');

  return setEntityComponent(store, 'halo', { position, label: tip });
}

export function onClick(e, store) {
  e.preventDefault();
  const { boardSize } = getEntity(store, 'constants');
  const cellSize = e.target.offsetWidth / boardSize;
  const x = Math.ceil(e.layerX / cellSize);
  const y = Math.ceil(e.layerY / cellSize);

  let updatedStore = store;
  updatedStore = performActionAt(updatedStore, { x, y });
  updatedStore = showHaloAt(updatedStore, { x, y });
  return updatedStore;
}

function isOutsideBoard(store, position) {
  const { boardSize } = getEntity(store, 'constants');
  return (
    position.x < 1 ||
    position.x > boardSize ||
    position.y < 1 ||
    position.y > boardSize
  )
}

function performActionAt(store, position) {
  // First, we check the position is inside the board.
  if (isOutsideBoard(store, position)) {
    return store;
  }

  // Then we check our player can access this position (i.e. it is not too
  // far).
  const meiko = getEntity(store, 'meiko');
  if (!positionIsAccessibleFor(meiko, position)) {
    return showHaloAvailablePositions(store);
  }

  // Then, we run all actions for entities present at the position.
  let updatedStore = store;
  const entitiesAtPosition = searchEntitiesAt(store, position);
  entitiesAtPosition.forEach((entity) => {
    if (typeof entity.exec === 'function') {
      updatedStore = entity.exec(updatedStore, position);
    }
  });

  // Change direction of Meiko
  let direction = meiko.direction
  if (position.x > meiko.position.x) { direction = 'right' }
  else if (position.x < meiko.position.x) { direction = 'left' }
  else if (position.y < meiko.position.y) { direction = 'top' }
  else if (position.y > meiko.position.y) { direction = 'below' }
  updatedStore = setEntityComponent(updatedStore, meiko.id, { direction });

  // Finally, we try to move the playable entity only if there is no
  // obstructing entity
  if (entitiesAtPosition.some((entity) => entity.obstruct)) {
    return updatedStore;
  }
  updatedStore = setEntityComponent(updatedStore, 'stepCount', { value: getEntity(store, 'stepCount').value + 1 });
  return setEntityComponent(updatedStore, meiko.id, { position });
}

export function positionIsAccessibleFor(entity, position) {
  if (entity.position == null) {
    return false;
  }
  const diff_x = Math.abs(entity.position.x - position.x);
  const diff_y = Math.abs(entity.position.y - position.y);
  return (
    (diff_x == 1 && diff_y == 0) ||
    (diff_x == 0 && diff_y == 1) ||
    (diff_x == 0 && diff_y == 0)
  );
}

export function showHaloAvailablePositions(store) {
  let updatedStore = removeHaloNextMoves(store);

  const meiko = getEntity(updatedStore, 'meiko');

  const allPossibleMoves = [
    {
      direction: 'Bottom',
      position: { x: meiko.position.x, y: meiko.position.y + 1 }
    },
    {
      direction: 'Top',
      position: { x: meiko.position.x, y: meiko.position.y - 1 }
    },
    {
      direction: 'Left',
      position: { x: meiko.position.x - 1, y: meiko.position.y }
    },
    {
      direction: 'Right',
      position: { x: meiko.position.x + 1, y: meiko.position.y }
    }
  ];

  allPossibleMoves.forEach(move => {
    const entitiesAtPosition = searchEntitiesAt(updatedStore, move.position);

    if (
      !isOutsideBoard(updatedStore, move.position) &&
      !entitiesAtPosition.some(entity => entity.obstruct)
    ) {
      updatedStore = setEntityComponent(
        updatedStore,
        `haloNextMove${move.direction}`,
        {
          position: move.position
        }
      );
    }
  });

  return updatedStore;
}
