<template>
  <v-container class="products-department">
    <div class="products-department__list">
      <ProductItem
        v-for="(dessert, index) in desserts"
        :key="`${index}`"
        :product="dessert"
        :has-border="isLastOfType(index)"
        @click="() => selectItem(dessert)"
      ></ProductItem>
    </div>
    <v-fade-transition>
      <div v-if="validItemsSelected" class="products-department__finish">
        <slot name="button" />
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import ProductItem from './ProductItem.vue';

const images = require.context('@/assets/img/onTheDouble', true, /\.svg$/);

export default {
  name: 'onTheDoubleShoppingSortedDesserts',
  components: { ProductItem },

  props: {
    validItemsSelected: Boolean
  },

  data() {
    const religieusePrice = [9.9, 9.7, 9.5, 9.3, 9.1, 8.9];
    const gateauxPrice = [8.9, 8.7, 8.5, 8.3, 8.1, 7.9];
    const roulePrice = [7.9, 7.7, 7.5, 7.3, 7.1, 6.9];
    const macaronPrice = [6.9, 6.7, 6.5, 6.3, 6.1, 5.9];
    const donutsPrice = [5.9, 5.7, 5.5, 5.3, 5.1, 4.9];
    return {
      desserts: [],
      items: [
        images
          .keys()
          .filter(item => item.includes('desserts/Religieuse'))
          .map((file, index) => ({
            type: 'religieuse',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false,
            price: religieusePrice[index] || 9.9
          })),
        images
          .keys()
          .filter(item => item.includes('desserts/Gateau'))
          .map((file, index) => ({
            type: 'gateaux',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false,
            price: gateauxPrice[index] || 8.9
          })),
        images
          .keys()
          .filter(item => item.includes('desserts/Roule'))
          .map((file, index) => ({
            type: 'Roule',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false,
            price: roulePrice[index] || 7.9
          })),
        images
          .keys()
          .filter(item => item.includes('desserts/Macaron'))
          .map((file, index) => ({
            type: 'Macaron',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false,
            price: macaronPrice[index] || 6.9
          })),
        images
          .keys()
          .filter(item => item.includes('desserts/Donuts'))
          .map((file, index) => ({
            type: 'donuts',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false,
            price: donutsPrice[index] || 5.9
          }))
      ]
    };
  },
  methods: {
    selectItem(item) {
      if (item.isSelected && item.isValid) {
        return;
      }

      item.isSelected = !item.isSelected;

      if (item.isValid) {
        this.$emit('item-found', item);
      }
    },
    isLastOfType(index) {
      const dessertStart = 0;
      const dessertEnd = 23;

      if (index >= dessertStart && index <= dessertEnd) {
        return true;
      }
    }
  },

  mounted() {
    this.desserts = createDesserts(this.items);
  }
};

function createDesserts(items) {
  let desserts = [];

  items.flat().reduce((acc, dessert) => {
    if (dessert.name === 'Donuts-6') {
      dessert.isValid = true;
    }
    desserts.push(dessert);
    return acc;
  }, desserts);

  return desserts;
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/products-department.scss';
</style>
