<template>
  <div class="shopping-list" lg="10" md="9" sm="8">
    <p class="shopping-list__title">🛒 Liste de courses</p>

    <div class="shopping-list__lists">
      Pour la pizza

      <ul v-for="(ingredient) in ingredientsList" :key="ingredient.name" class="shopping-list__ingredients-list">
        <li class="shopping-list__list-item" :class="{ 'shopping-list__list-item--scratch': ingredient.scratch }">
          {{ ingredient.nameDisplay }}
        </li>
      </ul>

      <hr/>

      <ul v-for="(dessert) in dessertsList" :key="dessert.name" class="shopping-list__desserts-list">
        <li class="shopping-list__list-item" :class="{ 'shopping-list__list-item--scratch': dessert.scratch }">
          {{ dessert.nameDisplay }}
        </li>
      </ul>
    </div>

    <button v-if="!inShop" @click="nextStep" class="primary-btn">
      Entrer dans le magasin
    </button>
  </div>
</template>

<script>
export default {
  name: "shoppingList",
  props: {
    ingredientsList: Array,
    dessertsList: Array,
    inShop: Boolean,
  },

  methods: {
    nextStep() {
      this.$emit("next-step");
    },
  },
}
</script>

<style scoped lang="scss">
.shopping-list {
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  height: auto;
  width: 20rem;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.1);
  background-color: white;
  margin-bottom: 60px;

  @media (max-width: 575px) {
    padding-bottom: 20px;
    margin-bottom: 0;
  }

  &__title {
    font-size: 1.4rem;
    font-weight: bold;

    @media (max-width: 575px) {
      font-size: 1.2rem;
    }
  }

  &__lists {
    font-size: 1rem;
    color: black;
    margin: 0;

    @media (max-width: 575px) {
      font-size: 0.8rem;
    }

    hr {
      border: 2px solid #FFCE00;
      border-radius: 3px;
      margin: 10px 0;

      @media (max-width: 575px) {
        margin: 5px 0;
      }
    }
  }

  &__ingredients-list,
  &__desserts-list {
    margin: 0 20px;

    @media (max-width: 575px) {
      margin: 0 10px;
    }
  }

  &__list-item {
    font-size: 1rem;

    &--scratch {
      text-decoration: line-through;
    }

    @media (max-width: 575px) {
      font-size: 0.8rem;
    }
  }
}

.primary-btn {
  margin: 20px 0 0 0;
}
</style>