<template>
  <v-container class="products-department">
    <div class="products-department__list">
      <ProductItem
        v-for="(dessert, index) in desserts"
        :key="`${index}`"
        :product="dessert"
        @click="() => selectItem(dessert)"
      ></ProductItem>
    </div>
    <v-fade-transition>
      <div v-if="validItemsSelected" class="products-department__finish">
        <slot name="button" />
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import ProductItem from './ProductItem.vue';

export default {
  name: 'onTheDoubleShoppingDesserts',
  components: { ProductItem },

  props: {
    validItemsSelected: Boolean
  },

  data() {
    return {
      desserts: [
        {
          type: 'Roule',
          name: 'Roule-3',
          img: require('@/assets/img/onTheDouble/dessert/Roule-3.svg'),
          isSelected: false,
          isValid: false,
          price: 7.5
        },
        {
          type: 'Gateau',
          name: 'Gateau-3',
          img: require('@/assets/img/onTheDouble/dessert/Gateau-3.svg'),
          isSelected: false,
          isValid: false,
          price: 8.5
        },
        {
          type: 'Donuts',
          name: 'Donuts-1',
          img: require('@/assets/img/onTheDouble/dessert/Donuts-1.svg'),
          isSelected: false,
          isValid: false,
          price: 5.9
        },
        {
          type: 'Religieuse',
          name: 'Religieuse-1',
          img: require('@/assets/img/onTheDouble/dessert/Religieuse-1.svg'),
          isSelected: false,
          isValid: false,
          price: 9.9
        },
        {
          type: 'Macaron',
          name: 'Macaron-2',
          img: require('@/assets/img/onTheDouble/dessert/Macaron-2.svg'),
          isSelected: false,
          isValid: false,
          price: 6.7
        },
        {
          type: 'Roule',
          name: 'Roule-4',
          img: require('@/assets/img/onTheDouble/dessert/Roule-4.svg'),
          isSelected: false,
          isValid: false,
          price: 7.3
        },
        {
          type: 'Macaron',
          name: 'Macaron-6',
          img: require('@/assets/img/onTheDouble/dessert/Macaron-6.svg'),
          isSelected: false,
          isValid: false,
          price: 5.9
        },
        {
          type: 'Macaron',
          name: 'Macaron-1',
          img: require('@/assets/img/onTheDouble/dessert/Macaron-1.svg'),
          isSelected: false,
          isValid: false,
          price: 5.9
        },
        {
          type: 'Gateau',
          name: 'Gateau-2',
          img: require('@/assets/img/onTheDouble/dessert/Gateau-2.svg'),
          isSelected: false,
          isValid: false,
          price: 8.7
        },
        {
          type: 'Donuts',
          name: 'Donuts-2',
          img: require('@/assets/img/onTheDouble/dessert/Donuts-2.svg'),
          isSelected: false,
          isValid: false,
          price: 5.7
        },
        {
          type: 'Macaron',
          name: 'Macaron-5',
          img: require('@/assets/img/onTheDouble/dessert/Macaron-5.svg'),
          isSelected: false,
          isValid: false,
          price: 6.1
        },
        {
          type: 'Roule',
          name: 'Roule-5',
          img: require('@/assets/img/onTheDouble/dessert/Roule-5.svg'),
          isSelected: false,
          isValid: false,
          price: 7.1
        },
        {
          type: 'Roule',
          name: 'Roule-1',
          img: require('@/assets/img/onTheDouble/dessert/Roule-1.svg'),
          isSelected: false,
          isValid: false,
          price: 7.9
        },
        {
          type: 'Gateau',
          name: 'Gateau-5',
          img: require('@/assets/img/onTheDouble/dessert/Gateau-3.svg'),
          isSelected: false,
          isValid: false,
          price: 8.1
        },
        {
          type: 'Macaron',
          name: 'Macaron-4',
          img: require('@/assets/img/onTheDouble/dessert/Macaron-4.svg'),
          isSelected: false,
          isValid: false,
          price: 6.3
        },
        {
          type: 'Religieuse',
          name: 'Religieuse-2',
          img: require('@/assets/img/onTheDouble/dessert/Religieuse-2.svg'),
          isSelected: false,
          isValid: false,
          price: 9.7
        },
        {
          type: 'Donuts',
          name: 'Donuts-4',
          img: require('@/assets/img/onTheDouble/dessert/Donuts-4.svg'),
          isSelected: false,
          isValid: false,
          price: 5.3
        },
        {
          type: 'Macaron',
          name: 'Macaron-3',
          img: require('@/assets/img/onTheDouble/dessert/Macaron-3.svg'),
          isSelected: false,
          isValid: false,
          price: 6.5
        },
        {
          type: 'Donuts',
          name: 'Donuts-3',
          img: require('@/assets/img/onTheDouble/dessert/Donuts-3.svg'),
          isSelected: false,
          isValid: false,
          price: 5.5
        },
        {
          type: 'Religieuse',
          name: 'Religieuse-3',
          img: require('@/assets/img/onTheDouble/dessert/Religieuse-3.svg'),
          isSelected: false,
          isValid: false,
          price: 9.5
        },
        {
          type: 'Roule',
          name: 'Roule-2',
          img: require('@/assets/img/onTheDouble/dessert/Roule-2.svg'),
          isSelected: false,
          isValid: false,
          price: 7.7
        },
        {
          type: 'Religieuse',
          name: 'Religieuse-4',
          img: require('@/assets/img/onTheDouble/dessert/Religieuse-4.svg'),
          isSelected: false,
          isValid: false,
          price: 9.3
        },
        {
          type: 'Donuts',
          name: 'Donuts-6',
          img: require('@/assets/img/onTheDouble/dessert/Donuts-6.svg'),
          isSelected: false,
          isValid: true,
          price: 4.9
        },
        {
          type: 'Gateau',
          name: 'Gateau-1',
          img: require('@/assets/img/onTheDouble/dessert/Gateau-1.svg'),
          isSelected: false,
          isValid: false,
          price: 8.9
        },
        {
          type: 'Religieuse',
          name: 'Religieuse-4',
          img: require('@/assets/img/onTheDouble/dessert/Religieuse-4.svg'),
          isSelected: false,
          isValid: false,
          price: 9.3
        },
        {
          type: 'Gateau',
          name: 'Gateau-4',
          img: require('@/assets/img/onTheDouble/dessert/Gateau-3.svg'),
          isSelected: false,
          isValid: false,
          price: 8.3
        },
        {
          type: 'Religieuse',
          name: 'Religieuse-2',
          img: require('@/assets/img/onTheDouble/dessert/Religieuse-2.svg'),
          isSelected: false,
          isValid: false,
          price: 9.7
        },
        {
          type: 'Donuts',
          name: 'Donuts-5',
          img: require('@/assets/img/onTheDouble/dessert/Donuts-5.svg'),
          isSelected: false,
          isValid: false,
          price: 5.1
        },

        {
          type: 'Gateau',
          name: 'Gateau-6',
          img: require('@/assets/img/onTheDouble/dessert/Gateau-2.svg'),
          isSelected: false,
          isValid: false,
          price: 7.9
        },
        {
          type: 'Roule',
          name: 'Roule-6',
          img: require('@/assets/img/onTheDouble/dessert/Roule-6.svg'),
          isSelected: false,
          isValid: false,
          price: 6.9
        }
      ]
    };
  },
  methods: {
    selectItem(item) {
      if (item.isSelected && item.isValid) {
        return;
      }

      item.isSelected = !item.isSelected;

      if (item.isValid) {
        this.$emit('item-found', item);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/products-department.scss';
</style>
