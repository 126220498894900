export const STEP_ROLL_OUT_DOUGH = 1;
export const STEP_GRAB_TOMATO = 2;
export const STEP_SPREAD_TOMATO = 3;
export const STEP_GRAB_MOZZA = 4;
export const STEP_SPREAD_MOZZA = 5;
export const STEP_GRAB_PIZZA_1 = 6;
export const STEP_PUT_PIZZA_IN_OVEN = 7;
export const STEP_BAKE_PIZZA = 8;
export const STEP_GRAB_PIZZA_2 = 9;
export const STEP_DELIVER_PIZZA = 10;
export const STEP_FINISH = 11;

export const labels = [
  { id: STEP_ROLL_OUT_DOUGH, label: 'Étaler la pâte avec les doigts' },
  { id: STEP_GRAB_TOMATO, label: 'Aller chercher la sauce tomate sur l’étagère' },
  { id: STEP_SPREAD_TOMATO, label: 'Verser la sauce tomate sur la pizza' },
  { id: STEP_GRAB_MOZZA, label: 'Aller chercher la mozzarella dans le frigo' },
  { id: STEP_SPREAD_MOZZA, label: 'Disposer la mozzarella sur la sauce tomate' },
  { id: STEP_GRAB_PIZZA_1, label:'Prendre la pizza' }, 
  { id: STEP_PUT_PIZZA_IN_OVEN, label: 'Mettre la pizza au four' },
  { id: STEP_BAKE_PIZZA, label: 'Cuire 5 secondes' },
  { id: STEP_GRAB_PIZZA_2, label: 'Prendre la pizza' },
  { id: STEP_DELIVER_PIZZA, label: 'Emporter la pizza jusqu’au passe-plat' },
  { id: STEP_FINISH },
]
