export function addEntity(store, entity) {
  if (entity.id == null) {
    throw new TypeError('entity object must have an id');
  }
  if (getEntity(store, entity.id) != null) {
    return store;
  }
  return [
    ...store,
    entity,
  ];
}

export function addEntities(store, entities) {
  let updatedStore = store;
  entities.forEach((entity) => {
    updatedStore = addEntity(updatedStore, entity);
  });
  return updatedStore;
}

export function createEntity() {
  return Array.from(arguments).reduce((entity, componentList) => {
    return {
      ...entity,
      ...componentList,
    };
  }, {});
}

export function setEntityComponent(store, id, component) {
  return store.map((entity) => {
    if (entity.id !== id) {
      return entity;
    }
    return {
      ...entity,
      ...component,
    };
  });
}

export function removeEntityComponent(store, id, componentName) {
  return store.map((entity) => {
    if (entity.id !== id) {
      return entity;
    }
    const newEntity = { ...entity };
    delete newEntity[componentName];
    return newEntity;
  });
}

export function getEntity(store, id) {
  return store.find((entity) => entity.id === id) || null;
}

export function searchEntitiesAt(store, position) {
  return store.filter((entity) => {
    if (entity.position == null) {
      return false;
    }

    if (entity.size == null) {
      return entity.position.x === position.x && entity.position.y === position.y;
    }

    return getCoveredPositionsBy(entity).some((coveredPosition) => (
      coveredPosition.x === position.x &&
      coveredPosition.y === position.y
    ));
  });
}

export function removeEntity(store, id) {
  return store.filter((entity) => entity.id !== id);
}

function getCoveredPositionsBy(entity) {
  const coveredPositions = [];
  for (const x of Array(Math.ceil(entity.size.width)).keys()) {
    for (const y of Array(Math.ceil(entity.size.height)).keys()) {
      coveredPositions.push({
        x: entity.position.x + x,
        y: entity.position.y + y,
      });
    }
  }
  return coveredPositions;
}
