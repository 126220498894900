<template>
  <div v-if="!this.end">
    <div class="content result-modal">
      <div>
        <h1 class="result-modal__title">
          <font-awesome-icon
            :icon="isAnswerRight ? rightIcon : wrongIcon"
            class="quiz-icon"
            :class="isAnswerRight ? 'valid-icon' : 'invalid-icon'"
            size="2x"
          />
          <span
            class="result-modal__title__text"
            v-html="isAnswerRight ? rightAnswer.title : wrongAnswer.title"
          ></span>
        </h1>
        <p class="result-modal__details" v-html="details"></p>
        <hr class="result-modal__divider" />
        <h3 class="result-modal__version">{{ versionSentence }}&thinsp;:</h3>
        <div class="result-modal__img">
          <img
            @click="toggleImage"
            class="result-modal__img-solution"
            :src="imgSrc"
            :alt="imgCaption"
          />
          <p class="font-italic" v-html="imgCaption"></p>
        </div>
        <v-row>
          <v-col sm>
            <button @click="next" class="primary-btn result-modal__next-btn">
              Suivant
            </button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>

  <div v-else>
    <div class="row content content--padding-top justify-content-center m-auto">
      <div>
        <div v-if="score < 4">
          <h1>Oupsy !</h1>
          <p>{{ scoreMessage }}</p>
          <p>
            Heureusement, tu peux (re)jouer à tous les jeux ou (re)lire les
            pages principes pour mieux comprendre tout ça !
          </p>
        </div>

        <div v-if="score >= 4 && score <= 6">
          <h1>Pas si mal !</h1>
          <p>{{ scoreMessage }}</p>
          <p>
            N’hésite pas à (re)jouer à tous les jeux ou (re)lire les pages
            principes pour approfondir tout ça !
          </p>
        </div>

        <div v-if="score > 6">
          <h1>Bravo !</h1>
          <p>{{ scoreMessage }}</p>
          <p>
            N’hésite pas à challenger tes collègues pour voir s’ils font aussi
            bien ! ;)
          </p>
        </div>

        <v-row class="action-row">
          <v-col sm>
            <button @click="home" class="retry-btn">
              Retour à l&apos;accueil
            </button>
          </v-col>

          <v-col sm>
            <button @click="replay" class="retry-btn">Refaire le quiz</button>
          </v-col>

          <v-col sm>
            <button @click="principles" class="primary-btn">
              Relire les principes
            </button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'QuizResult',
  components: { FontAwesomeIcon },
  props: {
    isAnswerRight: Boolean,
    wrongAnswer: {
      title: String,
      imgSrc: String,
      imgCaption: String
    },
    rightAnswer: {
      title: String,
      imgSrc: String,
      imgCaption: String
    },
    details: String,

    replay: Function,
    next: Function,
    score: Number,
    nbQuestions: Number,
    end: Boolean
  },

  data() {
    return {
      currentCorrectAnswer: null,
      isCorrectImageShowed: false
    };
  },

  mounted() {
    this.currentCorrectAnswer = this.isAnswerRight;
  },

  methods: {
    principles() {
      this.$router.push('/principles');
    },
    home() {
      this.$router.push('/');
    },
    toggleImage() {
      this.isCorrectImageShowed = !this.isCorrectImageShowed;
    }
  },

  computed: {
    rightIcon() {
      return faCheck;
    },
    wrongIcon() {
      return faTimes;
    },
    imgSrc() {
      return this.isCorrectImageShowed
        ? this.rightAnswer.imgSrc
        : this.wrongAnswer.imgSrc;
    },
    imgCaption() {
      return this.isCorrectImageShowed
        ? this.rightAnswer.imgCaption
        : this.wrongAnswer.imgCaption;
    },
    versionSentence() {
      return `Clique sur l'image pour voir une version ${
        this.isCorrectImageShowed
          ? 'enfreignant le principe'
          : 'plus ergonomique'
      }`;
    },
    scoreMessage() {
      return `Tu as obtenu un score de ${this.score}/${this.nbQuestions}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/colors.scss';

.content--padding-top {
  padding-top: 10px !important;
}

.result-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;

  &__title {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 30px !important;

    &__text {
      font-weight: 500;

      * {
        color: $neutral-blue !important;
      }
    }

    @media (max-width: 700px) {
      font-size: 20px !important;
    }
  }

  &__version {
    color: $neutral-blue;
    margin-bottom: 10px;
    font-size: 17px;

    @media (max-width: 700px) {
      font-size: 13px;
    }
  }

  &__divider {
    border: 2px solid #ffce00;
    border-radius: 3px;
    margin: 20px 0;
    width: 100%;
    @media (max-width: 575px) {
      margin: 10px 0;
    }
  }

  &__details {
    white-space: pre-wrap;
  }

  &__next-btn {
    min-width: 100px !important;
  }

  &__img {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }

  &__img-solution {
    max-width: 95% !important;
    cursor: pointer;
    margin: 10px 0;
    border-radius: 10px;
    box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 4px 11px rgba(0, 0, 0, 0.2);
    max-height: 500px;
  }
}

.action-row {
  align-items: center;
}

.quiz-icon {
  max-width: 30px;
  max-height: 36px;
  margin: 0;

  @media (max-width: 700px) {
    max-width: 20px;
    max-height: 26px;
  }
}
</style>
