<template>
  <game-container ref="gameContainer" :resultHidden="this.resultHidden">
    <template v-slot:header>
      <v-col>
        <v-row class="header-text">
          <v-col class="align-items-center text-right header-col">
            <font-awesome-icon :icon="faShoePrints"></font-awesome-icon>
            {{stepCount}}
            pas réalisé(s)
          </v-col>
          <v-col class="align-items-center text-right header-col header-timer">
            <font-awesome-icon :icon="faClock"></font-awesome-icon>
            {{ clock }}
          </v-col>
        </v-row>
      </v-col>
    </template>
    <div class="countdown">
      <img v-if="gameStartCountdown === 1" src="@/assets/img/Chrono/Chrono1.svg" class="countdown__img" alt="">
      <img v-if="gameStartCountdown === 2" src="@/assets/img/Chrono/Chrono2.svg" class="countdown__img" alt="">
      <img v-if="gameStartCountdown === 3" src="@/assets/img/Chrono/Chrono3.svg" class="countdown__img" alt="">
    </div>
    <v-container class="pizzas-game-zone" :class="{ 'pizzas-game-zone--blur': gameStartCountdown !== 0 }">
      <div class="pizzas-steps">
        <div class="pizzas-steps-container">
          <div class="pizzas-steps-title">Recette en {{steps.length - 1}} étapes</div>
          <hr>

          <transition v-for="step in steps" :key="step.id">
            <div
              v-if="step.label&&(step.id==currentStep||step.id==currentStep+1)"
              :class="['pizzas-steps-item', { current: step.id == currentStep, }]"
            >
              <div class="pizzas-steps-id">{{step.id}}</div>
              <div class="pizzas-steps-label">{{ step.label }}</div>
            </div>
          </transition>
        </div>
      </div>

      <div :class="['pizzas-board', { stopped: !isRunning }]" ref="pizzasBoard">
        <div v-for="y in size" :key="y" class="board-line">
          <div v-for="x in size" :key="x" class="board-cell"></div>
        </div>

        <div
          v-for="entity in store"
          :key="entity.id"
          :id="entity.id"
          :class="['board-cell-entity', entity.id]"
          :style="entityStyle(entity)"
        >
          <img v-if="entity.asset" :src="entity.asset" alt class="entity-asset">
          <img v-else-if="entity.assets" :src="entityAssetImage(entity)" alt class="entity-asset">

          <div v-if="entity.label" class="entity-label">{{ entity.label }}</div>
        </div>

        <a href="#" @click="onBoardClick" @mousemove="onMouseMove" class="board-click-zone"></a>
      </div>
    </v-container>

    <template>
      <div>
        <v-dialog v-model="dialog" persistent>
          <v-card class="rounded-dialog">
            <pizzas-result
              slot="result"
              :version="version"
              :score="scoreLabel"
              :stepCount="stepCount"
              :isBetterScore="isBetterScore"
              @retry="retry"
              @next="next"
            ></pizzas-result>
          </v-card>
        </v-dialog>
      </div>
    </template>
  </game-container>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faShoePrints } from "@fortawesome/free-solid-svg-icons";
import GameContainer from "@/views/games/GameContainer.vue";

import PizzasResult from "./PizzasResult.vue";

import {
  getEntity,
  setEntityComponent,
  getEntitiesAssets
} from "./entity_system";

import * as systems from "./systems";

import v1 from "./v1";
import v2 from "./v2";

// This is used to preload assets and keep a reference in memory so the browser
// don't release it after some seconds. It has no other use.
const imagesCache = {};
function cacheImage(src) {
  if (imagesCache[src] == null) {
    const image = new Image();
    image.src = src;
    imagesCache[src] = image;
  }
}

export default {
  name: "Pizzas-Game",

  components: {
    PizzasResult,
    GameContainer,
    FontAwesomeIcon
  },

  data() {
    return {
      launchTicks: 0,
      store: v1.initialStore,
      size: v1.BOARD_SIZE,
      cellResizing: "auto",
      version: 1,
      firstScore: 0,
      faClock,
      faShoePrints,
      dialog: false,
      gameStartCountdown: 3,
    };
  },

  methods: {
    play() {
      this.launchTicks = 3;
      this.launchInterval = setInterval(() => {
        this.launchTicks = this.launchTicks - 1;
        if (this.launchTicks === 0) {
          clearInterval(this.launchInterval);
          this.store = setEntityComponent(this.store, "running", {
            value: true
          });
        }
      }, 1000);
    },
    startGameCountdown() {
      if (this.gameStartCountdown > 0) {
        setTimeout(() => {
          this.gameStartCountdown--;
          this.startGameCountdown();
        }, 1000);
      } else {
        this.play();
        this.tickInterval = setInterval(this.onTick, 1000);
      }
    },
    entityStyle(entity) {
      if (entity.size == null || entity.position == null) {
        return {};
      }
      const { BOARD_SIZE } = this.gameModule;
      const cellSize = 100 / BOARD_SIZE;
      let width = entity.size.width * cellSize + "vw";
      let height = entity.size.height * cellSize + "vw";
      if (this.cellResizing !== "auto") {
        width = entity.size.width * this.cellResizing + "px";
        height = entity.size.height * this.cellResizing + "px";
      }
      return {
        width,
        height,
        maxWidth: entity.size.width * 116 + "px",
        maxHeight: entity.size.height * 116 + "px",
        top: (entity.position.y - 1) * cellSize + "%",
        left: (entity.position.x - 1) * cellSize + "%"
      };
    },

    entityAssetImage(entity) {
      const asset = entity.assets[entity.look];
      if (entity.direction) {
        return asset[entity.direction];
      } else {
        return asset;
      }
    },

    onTick() {
      const running = getEntity(this.store, "running").value;
      if (this.store && running) {
        this.store = systems.onTick(this.store);
      }
    },

    onBoardClick(e) {
      const running = getEntity(this.store, "running").value;
      if (running) {
        this.store = systems.onClick(e, this.store);
      }
    },

    onMouseMove(e) {
      const running = getEntity(this.store, "running").value;
      if (running) {
        this.store = systems.onHover(e, this.store);
      }
    },

    retry() {
      this.store = this.gameModule.initialStore;
      this.size = this.gameModule.BOARD_SIZE;
      this.dialog = false;
    },

    next() {
      if (this.version === 1) {
        this.version = 2;
        this.firstScore = this.score;
        this.retry();
      } else if (this.version === 2) {
        this.$router.push("/principles/minimum-actions");
      }
    },

    resultHidden() {
      window.scrollTo({ top: 0 });
    }
  },

  computed: {
    isRunning() {
      return getEntity(this.store, "running").value;
    },

    currentStep() {
      return getEntity(this.store, "currentStep").value;
    },

    steps() {
      return this.gameModule.stepLabels;
    },

    stepCount() {
      return getEntity(this.store, "stepCount").value;
    },

    clock() {
      const ticks = getEntity(this.store, "ticks").value;
      const minutes = Math.floor(ticks / 60).toLocaleString("fr-FR", {
        minimumIntegerDigits: 2
      });
      const seconds = (ticks % 60).toLocaleString("fr-FR", {
        minimumIntegerDigits: 2
      });
      return `${minutes}:${seconds}`;
    },

    score() {
      return getEntity(this.store, "ticks").value;
    },

    scoreLabel() {
      const minutes = Math.floor(this.score / 60);
      const seconds = this.score % 60;
      const minutesLabel =
        minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
      const secondsLabel =
        seconds > 1 ? `${seconds} secondes` : `${seconds} seconde`;
      if (minutes === 0) {
        return secondsLabel;
      } else {
        return `${minutesLabel} et ${secondsLabel}`;
      }
    },

    isBetterScore() {
      return this.score <= this.firstScore;
    },

    gameModule() {
      if (this.version === 2) {
        return v2;
      } else {
        return v1;
      }
    }
  },

  watch: {
    store: {
      handler(newStore, oldStore) {
        const wasRunning = getEntity(oldStore, "running").value;
        const isRunning = getEntity(newStore, "running").value;
        if (wasRunning && !isRunning) {
          this.dialog = true;
        }
      },
      deep: true
    },
  },

  mounted() {
    window.scrollTo({ top: 0 });

    const assetsV1 = getEntitiesAssets(v1.initialStore);
    const assetsV2 = getEntitiesAssets(v2.initialStore);
    Array.prototype.concat(assetsV1, assetsV2).forEach(asset => {
      cacheImage(asset);
    });

    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    // under a width of 700px (precisely, css $cell-max-size * 6), the cells
    // are resized according to the window width and we can't find a best
    // size. However, above 700px, if the height is too small, we can try to
    // resize the cells to fit in the screen.
    if (windowHeight < 800 && windowWidth > 700) {
      // We need to substract the height of the floating header.
      const bestHeight = windowHeight - 90;
      this.$refs.pizzasBoard.style.width = bestHeight + "px";
      this.$refs.pizzasBoard.style.height = bestHeight + "px";

      // cellResizing is also used to resized entities dynamically
      this.cellResizing = bestHeight / 6;
      document.querySelectorAll(".board-cell").forEach(boardCell => {
        // The 0.5px is just to adjust borders. It's a bit "hacky" and I'm not
        // sure if it works correctly.
        boardCell.style.width = this.cellResizing - 0.5 + "px";
        boardCell.style.height = this.cellResizing - 0.5 + "px";
      });
    }

    this.startGameCountdown();
  },

  beforeUnmount() {
    window.clearInterval(this.tickInterval);
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/buttons";

$large-screen: 1200px;

$cell-size: 100vw / 6;
$cell-max-size: 116px;

$color-floor: #fff;
$color-floor-border: #ccc;

.retry-btn {
  width: auto;
}


.header-text {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 62px;

  @media (max-width: 575px) {
    font-size: 0.9rem;
  }
}

.pizzas-game-zone {
  display: flex;
  max-width: $cell-max-size * 6;
  padding-top: 2rem;
  padding-bottom: 5rem;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;

  &--blur {
    filter: blur(20px);
    pointer-events: none;
  }
}

@media (min-width: $large-screen) {
  .pizzas-game-zone {
    max-width: none;
    flex-direction: row;
    justify-content: center;
  }
}

.pizzas-steps {
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  .pizzas-steps-container {
    position: sticky;
    top: 100px;
    background-color: $ultra-light-grey;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    height: 200px;
    .pizzas-steps-title {
      font-size: 1.2rem;
      color: #5f6d6d;
      text-align: center;
    }
    hr {
      margin-top: 5px;
    }
    .pizzas-steps-item {
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      transition: none;
      color: $neutral-grey;
      opacity: 0.7;
      padding-left: 10px;
      padding-right: 10px;
      display: flex;
      align-items: baseline;
    }
    .pizzas-steps-item.current {
      font-weight: bold;
      font-size: 1rem;
      color: black;
      opacity: 1;
      border: medium solid $light-blue;
      border-radius: 10px;
    }
    .pizzas-steps-item.current .pizzas-steps-id {
      background: $light-blue;
      color: black;
    }
    .pizzas-steps-id {
      display: flex;
      background: $neutral-grey;
      color: $ultra-light-grey;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
    .pizzas-steps-label {
      display: flex;
      margin-left: 8px;
    }

    .pizzas-start-game {
      margin-top: 2rem;
      text-align: center;
    }
    .pizzas-start-game button {
      @extend %action-button;
    }
  }
}

@media (min-width: $large-screen) {
  .pizzas-steps {
    width: 30%;
    margin-left: 1rem;
  }
}
.pizzas-board {
  position: relative;

  width: $cell-size * 6;
  max-width: $cell-max-size * 6;

  border: 2px solid #999;

  &.stopped::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: block;
    background: #000;
    content: "";
    opacity: 0.4;
  }

  img {
    max-width: 100%;
  }

  .board-line {
    display: flex;
  }

  .board-cell {
    width: $cell-size;
    height: $cell-size;
    max-width: $cell-max-size;
    max-height: $cell-max-size;
    background-color: $color-floor;
    border: 1px solid $color-floor-border;
  }

  .board-cell-entity {
    position: absolute;

    transition: all 0.1s linear;

    .entity-label {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      height: 100%;
      padding: 1rem;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
    }

    &.halo {
      z-index: 50;
      transition: none;
      border: 3px solid #ffce00;

      .entity-label {
        bottom: auto;
        height: auto;
        padding: 0.25rem;
        margin: 0.25rem;
        font-size: 0.9rem;
        background-color: rgba(20, 20, 20, 0.7);
        border: 1px solid #000;
        border-radius: 0.25rem;
      }
    }

    &.haloNextMoveTop,
    &.haloNextMoveRight,
    &.haloNextMoveBottom,
    &.haloNextMoveLeft {
      z-index: 50;
      transition: none;
      border: 3px solid red;
    }

    &.mozza img {
      max-width: 80%;
    }
  }

  .board-click-zone {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 100;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
    transform: translateY(-80px);
  }

  .fade-leave-active {
    position: absolute;
  }
}

.header-col {
  max-width: 250px !important;
}

.header-timer{
  margin-right: 20px;
  max-width: 125px !important;

  @media (max-width: 575px) {
    max-width: 100px !important;
  }
}
</style>
