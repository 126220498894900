<template>
  <game-container class="h-screen" ref="container">
    <template>
      <div>
        <v-dialog v-model="dialog" persistent>
          <v-card class="rounded-dialog">
            <catch-them-all-result
              :alternative="this.alternative"
              :nb-egg-found="this.nbValidSelected()"
              :nb-egg-selected="this.nbSelected()"
              :nb-egg-total="this.eggToFindTotal"
              :replay="this.replay"
              :gameAlternative="this.gameAlternative"
              :principle="this.principle"
            >
            </catch-them-all-result>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template v-slot:header>
      <v-col class="rebours">
        Plus que... {{this.ticks}} sec pour trouver
        {{ this.eggToFindTotal - this.nbValidSelected() }}
        œufs&nbsp;!
      </v-col>
    </template>
    <div class="countdown">
      <img v-if="gameStartCountdown === 1" src="@/assets/img/Chrono/Chrono1.svg" class="countdown__img" alt="">
      <img v-if="gameStartCountdown === 2" src="@/assets/img/Chrono/Chrono2.svg" class="countdown__img" alt="">
      <img v-if="gameStartCountdown === 3" src="@/assets/img/Chrono/Chrono3.svg" class="countdown__img" alt="">
    </div>
    <v-container class="eggs eggs__list" :class="{ 'eggs__list--blur': gameStartCountdown !== 0 }" >
      <div v-for="(egg, index) in eggs" :key="`${index}`">
        <a @click="selectEgg(egg)" class="egg__link">
          <div class="elements" :class="{ 'elements-clicked': egg.isSelected }" >
            <img class="img-egg" :src="egg.img" alt="">
            <div class="error-message" v-if="!egg.isValid">
              <div>
                <font-awesome-icon :icon="wrongIcon" class="invalid-icon" size="2x" />
              </div>
              <div>Nop</div>
            </div>
          </div>
        </a>
      </div>
    </v-container>
  </game-container>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import GameContainer from '../GameContainer.vue';
import CatchThemAllResult from './CatchThemAllResult.vue';

const rand = nb => Math.floor(Math.random() * nb);
const eggTypes = {
  first: [
    { name: 'peruvien', img: require(`@/assets/img/eggs_bonnet.png`), count: 3, valid: 0 },
    { name: 'mexicain', img: require(`@/assets/img/eggs_mexican.png`), count: 3, valid: 3 },
    { name: 'mickey', img: require(`@/assets/img/eggs_mickey.png`), count: 4, valid: 0 },
    { name: 'pirate', img: require(`@/assets/img/eggs_pirate.png`), count: 4, valid: 3 },
    { name: 'viking', img: require(`@/assets/img/eggs_viking.png`), count: 4, valid: 0 },
    { name: 'casquette', img: require(`@/assets/img/eggs_casquette.png`), count: 4, valid: 0 },
    { name: 'chapeau', img: require(`@/assets/img/eggs_hat.png`), count: 4, valid: 4 },
    { name: 'chauve', img: require(`@/assets/img/eggs_simple.png`), count: 22, valid: 0 },
  ],
  alternative: [
    { name: 'chauve', img: require(`@/assets/img/eggs_simple.png`), count: 38, valid: 0 },
    { name: 'chapeau', img: require(`@/assets/img/eggs_hat.png`), count: 10, valid: 10 },
  ]
};
const ticks = 10;

const getRandomEggType = (eggTypes, totalCount) => {
  // we take a number in totalLeftToadd
  // and then loop over eggTypes as if it was a ruban of totalLeftToAdd length
  const totalLeftToAdd = eggTypes.reduce((prev, eggType) => prev - eggType.added, totalCount);
  let indexSelected = rand(totalLeftToAdd);
  let typeIndex = 0;
  while (indexSelected >= 0) {
    const currentEggType = eggTypes[typeIndex];
    const currentTypeLeftToAdd = currentEggType.count - currentEggType.added;
    if (indexSelected < currentTypeLeftToAdd) {
      currentEggType.added++;
      return currentEggType;
    } else {
      indexSelected = indexSelected - currentTypeLeftToAdd;
      typeIndex++;
    }
  }
}

const createEggs = (round) => {
  eggTypes[round].forEach(eggType => {
    eggType.added = 0;
    eggType.selected = 0;
  });
  const totalCount = eggTypes[round].reduce((prev, eggType) => prev + eggType.count, 0);
  return Array.from({ length: totalCount }, () => {
    const type = getRandomEggType(eggTypes[round], totalCount);
    return {
      type: type.name,
      img: type.img,
      isSelected: false,
      isValid: true,
    };
  });
}

export default {
  name: "CatchThemAll",
  components: {
    GameContainer,
    CatchThemAllResult,
    FontAwesomeIcon,
  },
  computed: {
    wrongIcon() {
      return faTimes;
    }
  },
  data() {
    return {
      alternative: false,
      round: 'first',
      eggToFindTotal: 0,
      ticks,
      eggs: [],
      dialog: false,
      gameStartCountdown: 3,
    };
  },

  methods: {
    init() {
      this.round = this.alternative ? 'alternative' : 'first';
      this.eggs = createEggs(this.round);
      this.ticks = ticks;
      this.eggToFindTotal = eggTypes[this.round].reduce((prev, eggType) => prev + eggType.valid, 0);
    },
    start() {
      window.scrollTo({top: 0});
      this.$nextTick(() => {
        const nextTick = () => {
          setTimeout(
            () => (this.ticks-- > 1 ? nextTick() : this.dialog = true),
            1000
          );
        };
        nextTick();
      });
    },
    startGameCountdown() {
      if (this.gameStartCountdown > 0) {
        setTimeout(() => {
          this.gameStartCountdown--;
          this.startGameCountdown();
        }, 1000);
      } else {
        this.start();
      }
    },
    selectEgg(egg) {
      egg.isSelected = !egg.isSelected;
      const eggType = eggTypes[this.round].find(eggType => eggType.name === egg.type);
      if (egg.isSelected) {
        eggType.selected++;
        if (eggType.selected > eggType.valid) {
          egg.isValid = false;
        }
      } else {
        eggType.selected--;
        egg.isValid = true;
      }
    },
    nbSelected() {
      return eggTypes[this.round].reduce((prev, eggType) => prev + eggType.selected, 0);
    },
    nbValidSelected() {
      return eggTypes[this.round].reduce((prev, eggType) => prev + Math.min(eggType.valid, eggType.selected), 0);
    },
    replay() {
      this.dialog = false;
      this.init();
      this.start();
    },
    gameAlternative() {
      this.alternative = true;
      this.replay();
    },
    principle() {
      this.$router.push('/principles/homogeneity');
    }    
  },
  mounted() {
    this.init();
    this.startGameCountdown();
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/colors.scss";
.rebours {
  max-width: 500px !important;
  margin-right: 20px;
  text-align: center;
  @media screen and (max-width: 575px) {
    font-size: 0.9em;
  }
}
.eggs__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 10px;

  @media (max-width: 890px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 575px) {
    grid-template-columns: repeat(4, 1fr);
  }

  &--blur {
    filter: blur(20px);
    pointer-events: none;
  }
}

.eggs {
  margin-bottom: 40px;

  @media screen and (max-width: 575px) {
    margin-bottom: 20px;
  }
  .elements {
    cursor: pointer;
    position: relative;
    background-color: $light-blue;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    transition: all 0.2s;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;    
    display: flex;
    align-items: center;
    height: 90px;
    width: 60px;
    justify-content: center;
    
    .img-egg {
      position: absolute;
      vertical-align: middle;
    }

    .error-message {
      position: absolute;
      height: 100%;
      width: 100%;
      border-radius: 5px;
      padding-top: 20px;
      background-color: rgba(0,0,0,0.7);
      color: white;
      .invalid-icon {
        color: red;
        margin: 0 10px 0 10px;
      }
    }
  }
  a:hover .elements {
    background-color: $dark-blue;
    -webkit-box-shadow: inset 0 0 0 2px $neutral-grey;
    -moz-box-shadow: inset 0 0 0 2px $neutral-grey;
    box-shadow: inset 0 0 0 2px $neutral-grey;
  }

  .elements-clicked {
    background-color: $dark-blue;
  }
}

.egg__link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown {
  position: absolute;
  z-index: 1000;
  opacity: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__img {
    animation: scaling 1s infinite;
    width: 200px;
    height: 200px;
  }
}

@keyframes scaling {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
</style>


