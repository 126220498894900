<template>
  <v-dialog ref="modal" v-model="modal" centered hide-footer hide-header>
      <button type="button" class="close" aria-label="Close" @click="() => this.$refs['modal'].hide()">
        <span aria-hidden="true">&times;</span>
      </button>
      <slot></slot>
    </v-dialog>
</template>

<script>
export default {
  methods: {
    show() {
      this.$refs['modal'].show();
    }
  }
}
</script>