import {
  createEntity,
  addEntity,
  getEntity,
  setEntityComponent,
  removeEntityComponent,
  removeEntity,
  setEntityExec,
  removeEntityExec,
} from "../entity_system";

import artifacts from "./artifacts";

import {
  STEP_GRAB_TOMATO,
  STEP_SPREAD_TOMATO,
  STEP_GRAB_MOZZA,
  STEP_SPREAD_MOZZA,
  STEP_GRAB_PIZZA_1,
  STEP_PUT_PIZZA_IN_OVEN,
  STEP_BAKE_PIZZA,
  STEP_GRAB_PIZZA_2,
  STEP_DELIVER_PIZZA,
  STEP_FINISH,
} from "./steps";

export function rollOutDough(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_GRAB_TOMATO });
  updatedStore = setEntityExec(updatedStore, 'tomato', grabTomato, 'Attraper');
  updatedStore = setEntityComponent(updatedStore, 'pizza', { look: 'dough' });
  updatedStore = removeEntityExec(updatedStore, 'pizza');
  return updatedStore;
}

export function grabTomato(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_SPREAD_TOMATO });
  updatedStore = setEntityExec(updatedStore, 'pizza', spreadTomato, 'Étaler');
  updatedStore = removeEntity(updatedStore, 'tomato');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'sauce' });
  return updatedStore;
}

export function spreadTomato(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_GRAB_MOZZA });
  updatedStore = setEntityExec(updatedStore, 'mozza', grabMozza, 'Attraper');
  updatedStore = setEntityComponent(updatedStore, 'pizza', { look: 'tomato' });
  updatedStore = removeEntityExec(updatedStore, 'pizza');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'normal' });
  return updatedStore;
}

export function grabMozza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_SPREAD_MOZZA });
  updatedStore = setEntityExec(updatedStore, 'pizza', spreadMozza, 'Disposer la mozza');
  updatedStore = removeEntity(updatedStore, 'mozza');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'mozza' });
  return updatedStore;
}

export function spreadMozza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_GRAB_PIZZA_1 });
  updatedStore = setEntityComponent(updatedStore, 'pizza', { look: 'mozza' });
  updatedStore = setEntityExec(updatedStore, 'pizza', grabUncookedPizza, 'Prendre');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'normal' });
  return updatedStore;
}

export function grabUncookedPizza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_PUT_PIZZA_IN_OVEN });
  updatedStore = setEntityExec(updatedStore, 'oven', putPizzaInOven, 'Mettre au four');
  updatedStore = removeEntity(updatedStore, 'pizza');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'pizza' });
  return updatedStore;
}

export function putPizzaInOven(store, position) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_BAKE_PIZZA });
  updatedStore = setEntityComponent(updatedStore, 'oven', {
    tickCount: 5,
    label: '5',
    tick: tickOven,
  });
  updatedStore = removeEntityExec(updatedStore, 'oven');
  updatedStore = addEntity(updatedStore, createEntity(artifacts.pizza, {
    position,
    look: 'profile',
  }));
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'normal' });
  return updatedStore;
}

export function tickOven(store) {
  const oven = getEntity(store, 'oven');
  const newTickCount = oven.tickCount - 1;

  let updatedStore = store;
  if (newTickCount === 0) {
    updatedStore = setEntityComponent(updatedStore, 'oven', { tickCount: 0 });
    updatedStore = removeEntityComponent(updatedStore, 'oven', 'tick');
    updatedStore = removeEntityComponent(updatedStore, 'oven', 'label');
    updatedStore = setEntityExec(updatedStore, 'pizza', grabCookedPizza, 'Attraper');
    updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_GRAB_PIZZA_2 });
  } else {
    updatedStore = setEntityComponent(updatedStore, 'oven', {
      tickCount: newTickCount,
      label: `${newTickCount}`,
    });
  }
  return updatedStore;
}

export function grabCookedPizza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_DELIVER_PIZZA });
  updatedStore = setEntityExec(updatedStore, 'hatch', deliverPizza, 'Livrer');
  updatedStore = removeEntity(updatedStore, 'pizza');
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'pizza' });
  return updatedStore;
}

export function deliverPizza(store) {
  let updatedStore = store;
  updatedStore = setEntityComponent(updatedStore, 'currentStep', { value: STEP_FINISH });
  updatedStore = setEntityComponent(updatedStore, 'running', { value: false });
  updatedStore = setEntityComponent(updatedStore, 'meiko', { look: 'normal' });
  return updatedStore;
}
