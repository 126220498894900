<template>
  <principle
    principle="Loi de Hick"
    game="CherryOnTheCake"
    gameName="La cerise sur le gâteau"
    nextGame="pizzas"
  >
    <template v-slot:main-content>
      <div class="introduction">
        <p>
          La loi de Hick, ou loi de Hick-Hyman, est un modèle qui décrit le
          temps qu’il faut à un utilisateur pour prendre une décision en
          fonction du nombre de choix à sa disposition. Cette loi précise que
          <b
            >plus l'utilisateur a de choix et plus il lui faudra de temps pour
            prendre une décision.</b
          >*
        </p>
      </div>

      <div class="section">
        <h2 class="section__title">Application de la loi</h2>
        <p class="section__highlight">
          La principale bonne pratique à tirer de cette loi est d&apos;<b
            >éviter de proposer trop de choix</b
          >, sans quoi les utilisateurs et utilisatrices de notre outil seront
          vite perdus.
        </p>
        <v-row
          class="justify-content-center align-items-start theory-container row-margin"
        >
          <v-col lg="8" cols="12">
            <div class="m-0">
              <p>
                Le graphique montre que la relation entre le nombre de choix et
                le temps de réponse est logarithmique&nbsp;:
              </p>
              <ul class="section__list">
                <li>
                  si l'on ajoute une option à un menu de 2 options le temps va
                  être considérablement augmenté&nbsp;;
                </li>
                <li>
                  en revanche, si l'on ajoute une option à un menu qui a 30
                  options, le temps nécessaire pour prendre une décision sera
                  certes augmenté mais de peu, la relation n'étant pas linéaire.
                </li>
              </ul>
              <p class="mt-2">
                L&apos;hypothèse généralement proposée pour expliquer la forme
                logarithmique de la loi de Hick suppose que les gens divisent le
                nombre total de choix en catégories. Ainsi, ils éliminent
                environ la moitié des options restantes à chaque étape, au lieu
                de considérer chaque choix un par un (ce qui requerrait un temps
                linéaire).
              </p>
            </div>
          </v-col>
          <v-col lg="4" cols="12">
            <div class="practice-container mw-100">
              <div class="practice-container__illustration">
                <img
                  class="illustration"
                  src="../../assets/img/principles/hick.png"
                  alt="graphique de la relation : le temps de réponse T est égal à b fois le log binaire du nombre de choix plus 1, avec b une constante dépendant du contexte"
                />
              </div>
              <p class="practice-container__caption">
                Tendance logarithmique de la loi de Hick
              </p>
            </div>
          </v-col>
        </v-row>
      </div>

      <div class="section">
        <v-row
          class="justify-content-center align-items-start theory-container row-margin"
        >
          <v-col lg="8" cols="12">
            <div class="m-0">
              Ce principe s&apos;applique en ergonomie numérique dans différents
              cas de figure&nbsp;:
              <ul class="section__list">
                <li>
                  si le nombre d&apos;éléments à placer dans un menu est grand,
                  il faudra <b>privilégier une arborescence</b> pour
                  n&apos;afficher qu&apos;un faible nombre de choix à la fois
                  (on utilisera notamment la méthode de tri de cartes pour
                  construire cette arborescence)&nbsp;;
                </li>
                <li>
                  pour un site e-commerce présentant un nombre important de
                  produits, il faudra <b>prévoir des filtres</b> pour réduire la
                  liste&nbsp;;
                </li>
                <li>
                  pour un tableau de bord de commandes, si le temps de réaction
                  est important il faudra
                  <b>réduire au maximum le nombre de commandes</b> disponibles
                  pour accélérer la prise de décision et réduire le risque
                  d&apos;erreur.
                </li>
              </ul>
            </div>
          </v-col>
          <v-col lg="4" cols="12">
            <div class="practice-container mw-100">
              <div class="practice-container__illustration">
                <img
                  class="illustration"
                  src="../../assets/img/principles/hick2.png"
                  alt="Illustration d'un choix entre 8 labels dans un même menu versus un choix entre 2 menus suivi d'un choix entre 4 labels"
                />
              </div>
              <p class="practice-container__caption">
                Privilégier les arborescences
              </p>
            </div>
          </v-col>
        </v-row>
      </div>

      <p class="section">
        <strong
          >Le principe semble simple et pourtant, combien de fois avez-vous dû
          chercher de longues minutes un lien dans une longue
          liste&nbsp;?</strong
        >
      </p>
    </template>

    <template v-slot:game-explanation>
      <div class="game-explanation mb-4">
        <v-col lg="4" md="5" sm="12">
          <v-row>
            <v-col class="text-center img-example">
              <img
                src="../../assets/img/principles/cupcake-illustration-principe.png"
                alt="capture d'écran du jeu"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="8" md="7" sm="12" class="p-0">
          <div>
            <p>
              Les deux versions des jeux présentent le même nombre de pages (6)
              mais avec un nombre différent de gâteaux par page&nbsp;: 2 par
              page dans le 1er jeu et 9 dans le second.
            </p>
            <p>
              Il faut plus de temps pour trouver le gâteau à la cerise parmi 9
              gâteaux que parmi 2 car vous devez observer les gâteaux un par un
              pour le trouver.
            </p>
            <div class="section__highlight">
              <p>
                <b> Remarque&nbsp;:</b> nous n&apos;avons pas essayé ici de
                démontrer l&apos;effet logarithmique car&#8239;:<br />
              </p>
              <ul class="section__list">
                <li>
                  le fait de regrouper les choix en catégories ne fonctionne
                  peut-être pas très bien dans cet exemple&nbsp;;
                </li>
                <li>
                  il faudrait probablement un grand nombre de répétitions pour
                  montrer un tel effet&nbsp;;
                </li>
                <li>
                  l&apos;élément le plus important à démontrer pour nous est
                  l&apos;augmentation du temps avec l&apos;augmentation du
                  nombre de choix.
                </li>
              </ul>
            </div>
          </div>
        </v-col>
      </div>
    </template>

    <template v-slot:note>
      <div class="note">
        * À noter tout de même, il semble que la loi de Hick ne s&apos;applique
        qu&apos;avec des choix simples, presque automatiques. Dans un contexte
        complexe, la loi de Hick ne s&apos;applique pas car ces décisions
        impliquent en plus des lectures, des recherches et des capacités de
        réflexion et de résolution de problèmes.<br />
        Référence&#8239;:
        <ul class="section__list">
          <li>
            Hick, William E.; On the rate of gain of information. Quarterly
            Journal of Experimental Psychology, 4:11-26, 1952.
          </li>
          <a
            href="https://journals.sagepub.com/doi/pdf/10.1080/17470215208416600"
            target="_blank"
            rel="noopener noreferrer"
            >[Accès document complet]</a
          >
          <li>
            Hyman, Ray; Stimulus information as a determinant of reaction time.
            Journal of Experimental Psychology, 45:188-196, 1953.
          </li>
          <a
            href="https://pdfs.semanticscholar.org/2d69/465216148628336f4ae6de8e53a682b46da8.pdf"
            target="_blank"
            rel="noopener noreferrer"
            >[Accès document complet]</a
          >
        </ul>
      </div>
    </template>
  </principle>
</template>

<script>
import Principle from './layout/PrinciplePage.vue';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default {
  name: 'HickPrinciple',
  components: {
    Principle
  },
  computed: {
    rightIcon() {
      return faCheck;
    },
    wrongIcon() {
      return faTimes;
    }
  }
};
</script>

<style lang="scss" scoped>
@import './layout/principle-page-content.scss';
</style>
