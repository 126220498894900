<template>
  <div class="container-fluid home_zone_3">
    <div class="container">
      <div class="title">
      <v-container>
        <h1>Projet</h1>
        <p>Historique du projet et comment aller plus loin</p>
      </v-container>
    </div>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="title_level_3">Pourquoi avoir créé les Ergogames&nbsp;?</h2>
        </div>
      </div>

      <div class="row justify-content-center align-items-center flex-row-reverse">
        <div class="col-sm-10 col-md-10 col-lg-6 order-lg-1 order-md-2 order-sm-2 order-2">
          <img src="../../assets/img/img_illust_zone_3.jpg" alt =" "/>
        </div>
        <div class="col-md-12 col-lg-4 offset-lg-1 order-lg-2 order-md-1 order-sm-1 order-1">
          <p>
            Ergonomes à Sogilis, nous avons mis en place les Ergogames en 2017&nbsp;: un rendez-vous régulier ouvert à tous les membres de l'entreprise pour découvrir des notions d'ergonomie cognitive par le jeu. <br><br>
            Pour partager cet outil avec le plus grand nombre, nous avons décidé de développer une version web gratuite des Ergogames. Nous enrichissons ce site de manière incrémentale, en proposant de nouveaux jeux régulièrement.
          </p>
          <p>
            <a href="https://www.margaux-perrin.com/les-ergogames-un-projet-interne-qui-prend-son-envol/" target="_blank" rel="noopener noreferrer" class="basic_link" aria-label="lien article de blog ergogames 2">Pour en savoir plus, lisez notre article de blog sur le déroulement du projet <font-awesome-icon :icon="angleRightIcon" /></a>
          </p>
        </div>
      </div>

      <div id="contact" class="row justify-content-center">
        <div class="col-lg-12">
          <h2 class="title_level_3">Comment aller plus loin&nbsp;?</h2>
        </div>
      </div>
        <div class="col-lg-12 next">
          <p>
            En complément et pour les équipes désirant aller plus loin dans la compréhension de ces sujets, nous avons construit une formation à distance payante.
            Vous apprendrez à travers des jeux, des échanges et des mises en situation à :</p>
            <div class="row justify-content-center">
              <div class=“col-lg-12>
                <p class="list">
                  <ul>
                    <li> identifier les erreurs de conception</li> 
                    <li> appliquer les bonnes pratiques de conception</li>
                    <li> suggérer des améliorations pour régler les problèmes</li>
                  </ul>
                </p>
              </div>
            </div>
            <p>
             <a href="https://uxshadow.com/formations/" target="_blank" rel="noopener noreferrer" class="basic_link" aria-label="lien uxshadow page formation">En savoir plus <font-awesome-icon :icon="angleRightIcon" /></a> 
            </p>
          <!-- <p>
            mettre le lien vers uxshadow ?
          </p> -->
        </div>

      <div id="contact" class="row justify-content-center">
        <div class="col-lg-8">
          <h2 class="title_level_3">Et après&nbsp;?</h2>
        </div>
      </div>

      <div class="row contact_zone justify-content-md-center justify-content-sm-center">
        <div class="col-md-12 col-lg-5 offset-lg-1">
          <p>
            Laissez-nous votre email pour être tenu informé&#xB7;e de la publication de nouveaux jeux, en savoir plus sur la formation, nous faire un retour utilisateur ou autre.
          </p>
        </div>

        <div class="col-12 col-sm-10 col-md-8 col-lg-6">
          <contact-form></contact-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactForm from './ContactForm.vue';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default {
  components: {
    ContactForm,
    FontAwesomeIcon
  },
  data: () => ({
    angleRightIcon: faAngleRight
  })
}
</script>

<style lang="scss">
@import '../../assets/scss/colors';
@import '../../assets/scss/zones';
.title {
    background : none !important;
    @extend %title-zone;
    min-height: 150px;
    padding-top: 50px;
    padding-bottom: 30px;
    text-align: center;
    h1 {
      font-weight: 700;
    }
  }

.home_zone_3 {
  @extend %third-zone;
  padding-top: 30px;
  padding-bottom: 50px;

  .title_level_3 {
    color: $neutral-grey;
    text-align: center;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .title_level_3::after {
    background-color: $neutral-grey;
  }

  .next{
    text-align: center;
}

.list{
  text-align: left;
}

  img {
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.2);
    @media screen and (max-width: 991px) {
      margin-top: 50px;
      width: 100%;
    }
  }

  p {
    color: $neutral-grey;
    font-size: 0.9em;
    font-weight: 500;
  }
}
</style>

