<template>
  <div>
    <div class="row content justify-content-center m-auto" v-if="this.isSuccess()">
      <div v-if="this.isKidnapping()">
        <h1>Perdu&nbsp;!</h1>
        <h2>Tu as kidnappé des œufs d'une des sœurs de Rosalyne, elle est furieuse&nbsp;!</h2>
        <p v-if="this.currentRond">
          Découvre maintenant le principe d'ergonomie qui se cache derrière ce jeu ou retente ta chance...
        </p>
        <p v-else>
          Saurais-tu faire mieux avec d'autres chapeaux&nbsp;?
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Réessayer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="next" class="primary-btn">{{nextButtonText()}}</button>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <h1>Bravo&nbsp;!</h1>
        <h2>Tu as trouvé les {{nbEggTotal}} œufs&nbsp;!</h2>
        <p v-if="this.currentRond">
          Découvre maintenant le principe d'ergonomie qui se cache derrière.
        </p>
        <p v-else>
          Sauras-tu faire aussi bien avec d'autres chapeaux&nbsp;?
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Réessayer à l'identique</button>
            </v-col>
          <v-col sm>
            <button @click="next" class="primary-btn">{{nextButtonText()}}</button>
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="row content justify-content-center m-auto" v-else>
      <div>
        <h1>Perdu&nbsp;!</h1>
        <h2>Tu as trouvé {{nbEggFound}} œuf{{nbEggFound > 1 ? 's' : ''}} chapeauté{{nbEggFound > 1 ? 's' : ''}} sur les {{nbEggTotal}} cachés.</h2>
        <p v-if="this.currentRond && nbEggFound === nbEggTotal" class="margin-bot">
          Découvre maintenant le principe d'ergonomie qui se cache derrière ce jeu, ou retente ta chance...
        </p>
        <p v-else class="margin-bot">
          Sauras-tu faire mieux avec d'autres chapeaux&nbsp;?
        </p>
        <v-row>
          <v-col sm>
            <button @click="replay" class="retry-btn">Réessayer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="next" class="primary-btn">{{nextButtonText()}}</button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>

</template>
<script>
export default {
  name: "CatchThemAllResult",
  props: {
    alternative: Boolean,
    nbEggTotal: Number,
    nbEggFound: Number,
    nbEggSelected: Number,
    replay: Function,
    gameAlternative: Function,
    principle: Function
  },

  data() {
    return {
      currentRond: null,
    };
  },

  mounted() {
    this.currentRond = this.alternative;
  },

  methods: {
    isSuccess() {
      return this.nbEggFound === this.nbEggTotal;
    },
    isKidnapping() {
      return this.nbEggSelected > this.nbEggTotal
    },
    next() {
      if (this.alternative) {
        this.principle();
      } else {
        this.gameAlternative();
      }
    },
    nextButtonText() {
      if (this.currentRond) {
        return "Voir le principe";
      } else {
        return "Tester autrement";
      }
    }
  }
};
</script>

<style scoped>
.margin-bot {
  margin-bottom: 40px !important;
}
</style>