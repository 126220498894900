<template>
  <div id="projet">
    <projet-header></projet-header>
    <projet-context></projet-context>
    <projet-footer></projet-footer>
    <main role="main">
      <router-view/>
      <credits></credits>
    </main>
  </div>
</template>

<script>
import projetHeader from '../AppHeader.vue';
import projetContext from './ContextPage.vue';
import projetFooter from './ProjetFooter.vue';
import Credits from '../CreditsFooter.vue'

export default {
  name: "projetIndex",
  components: {
    Credits,
    projetHeader,
    projetContext,
    projetFooter,
  }
};
</script>
