<template>
  <div>
    <div v-if="this.modalFirstTry" class="content result-modal">
      <h1 class="result-h1">Dommage...</h1>
      <h2 class="result-h2">
        Tu as réussi à trouver tous les éléments de la liste de courses, mais
        les caisses viennent de fermer.
      </h2>
      <p class="result-p p__margin-bot">
        Heureusement, un autre magasin ferme un tout petit peu plus tard, tu
        peux tenter ta chance là bas&nbsp;!
      </p>
      <v-row class="row-button">
        <v-col>
          <button @click="replay" class="retry-btn result__button">
            Rejouer à l'identique
          </button>
        </v-col>
        <v-col>
          <button @click="next" class="primary-btn result__button--next">
            {{ nextButtonLabel }}
          </button>
        </v-col>
      </v-row>
    </div>
    <div v-else class="content result-modal">
      <h1 class="result-h1 result-h1--second">
        {{ isSucces ? 'Bravo !' : 'Euh !' }}
      </h1>
      <div v-if="isSucces">
        <h2 class="result-h2">
          Tu as été plus rapide que l’éclair :
          {{ formatTime(this.timeCurrentRound) }} au lieu de
          {{ formatTime(this.timeRoundOne) }} dans l’autre magasin&nbsp;!
        </h2>
        <p class="result-p">
          As-tu remarqué ce qui était différent ? <br />
          pourrais-tu transposer ça dans le monde du développement logiciel ?
        </p>
        <p class="result-p p__margin-bot">
          Prend quelques secondes pour y réfléchir...
        </p>
      </div>
      <div v-else>
        <h2 class="result-h2">
          Désolé, tu as dû t’assoupir au rayon oreillers...
        </h2>
        <h3 class="result-h2">
          En théorie, tu aurais dû être plus rapide dans cette version du jeu.
        </h3>
        <p class="result-p p__margin-bot">
          Est-ce que tu veux retenter ta chance ?
          <br />Sinon, as-tu tout de même remarqué ce qui était différent ?
          <br />Comment pourrais-tu transposer ça dans le monde du développement
          logiciel ?
        </p>
      </div>
      <v-row class="row-button">
        <v-col sm>
          <button @click="replay" class="retry-btn result__button">
            Rejouer à l'identique
          </button>
        </v-col>
        <v-col sm>
          <button @click="next" class="primary-btn">
            {{ nextButtonLabel }}
          </button>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'on-the-double-result-modal',
  props: {
    round: Number,
    lastRound: Number,
    replay: Function,
    gameAlternative: Function,
    principle: Function,
    timeRoundOne: Number,
    timeCurrentRound: Number
  },

  data() {
    return {
      nextButtonLabel:
        this.round === 4 ? 'Voir le principe' : "Foncer à l'autre magasin",
      modalFirstTry:
        this.round >= 1 &&
        this.round <= 3 &&
        (this.lastRound === 1 || this.lastRound === 2),
      isSucces: this.timeCurrentRound < this.timeRoundOne
    };
  },

  methods: {
    next() {
      if (this.round === 2) {
        this.gameAlternative();
      } else {
        this.principle();
      }
    },
    formatTime(timeIsSeconds) {
      const minutes = Math.floor(timeIsSeconds / 60);
      const seconds = timeIsSeconds % 60;
      const minutesLabel =
        minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
      const secondsLabel =
        seconds > 1 ? `${seconds} secondes` : `${seconds} seconde`;

      return minutes === 0
        ? secondsLabel
        : `${minutesLabel} et ${secondsLabel}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.result-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.result-h1 {
  font-style: normal;
  line-height: 44px;
  margin-bottom: 24px !important;

  @media (max-width: 575px) {
    font-size: 30px;
  }
}

.result-h1--second {
  width: 100%;
  text-align: start;

  @media (max-width: 575px) {
    text-align: center;
  }
}

.result-h2 {
  font-style: normal;
  line-height: 28px;
  margin-bottom: 24px !important;
  font-size: 20px !important;

  @media (max-width: 575px) {
    font-size: 16px !important;
  }
}

.result-p {
  font-style: normal;
  line-height: 24px;
  margin-bottom: 24px !important;

  @media (max-width: 575px) {
    font-size: 16px;
  }
}

.row-button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @media (max-width: 575px) {
    flex-direction: column;
  }
}

.result__button {
  width: 200px !important;
}

.result__button--next {
  width: 240px;
}

.p__margin-bot {
  margin-bottom: 40px !important;

  @media (max-width: 575px) {
    margin-bottom: 10px !important;
  }
}
</style>
