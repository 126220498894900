<template>
  <div class="content">
    <h1 class="title">
      Merci
    </h1>
    <p>
      Votre message a bien été envoyé.
    </p>
    <p class="text-center">
      <router-link to="/">
        <button>Revenir à l'accueil</button>
      </router-link>
    </p>
  </div>
</template>
