// x : positionnement par rapport au bord droit
// y : positionnement par rapport au haut
export default [
  {
    id: "pneu",
    src: require(`@/assets/img/poulpe/pneu.svg`),
    position: {
      round1: { x: 35, y: 78 },
      round2: { x: 40, y: 10 },
    },
  },
  {
    id: "sac",
    src: require(`@/assets/img/poulpe/sac.svg`),
    position: {
      round1: { x: 25, y: 55 },
      round2: { x: 72, y: 22 },
    },
  },
  {
    id: "bouteille",
    src: require(`@/assets/img/poulpe/bouteille.svg`),
    position: {
      round1: { x: 10, y: 30 },
      round2: { x: 80, y: 53 },
    },
  },
];
