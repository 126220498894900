import {
  createEntity,
  addEntities,
} from "../entity_system";

import artifacts from "./artifacts";
import * as steps from "./steps";
import { rollOutDough } from "./actions";

const BOARD_SIZE = 6;

export default {
  BOARD_SIZE,

  stepLabels: steps.labels,

  initialStore: addEntities([], [
    createEntity({ id: 'running', value: true }),
    createEntity({ id: 'constants', boardSize: BOARD_SIZE }),
    createEntity({ id: 'ticks', value: 0 }),
    createEntity({ id: 'currentStep', value: steps.STEP_ROLL_OUT_DOUGH }),
    createEntity({ id: 'halo', size: { width: 1, height: 1 } }),
    createEntity({ id: 'stepCount', value: 0 }),
    createEntity({ id: 'haloNextMoveRight', size: { width: 1, height: 1 } }),
    createEntity({ id: 'haloNextMoveLeft', size: { width: 1, height: 1 } }),
    createEntity({ id: 'haloNextMoveTop', size: { width: 1, height: 1 } }),
    createEntity({ id: 'haloNextMoveBottom', size: { width: 1, height: 1 } }),

    createEntity(artifacts.oven, { position: { x: 2, y: 1 } }),
    createEntity(artifacts.hatch, { position: { x: 6, y: 1 } }),
    createEntity(artifacts.fridge, { position: { x: 1, y: 3 } }),
    createEntity(artifacts.hatch, { position: { x: 6, y: 1 } }),
    createEntity(artifacts.workplan, { position: { x: 6, y: 3 } }),
    createEntity(artifacts.shelf, { position: { x: 3, y: 6 } }),
    createEntity(artifacts.mozza, { position: { x: 2, y: 4 } }),
    createEntity(artifacts.tomato, { position: { x: 3, y: 6 } }),
    createEntity(artifacts.meiko, { position: { x: 4, y: 3 } }),
    createEntity(artifacts.pizza, {
      position: { x: 6, y: 4 },
      exec: rollOutDough,
      tip: 'Étaler',
    }),
  ]),
}
