<template>
  <social-media-icon>
    <svg version="1.1"
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:a="http://ns.adobe.com/AdobeSVGViewerExtensions/3.0/"
      x="0px" y="0px" width="30px" height="30px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;fill: #0a5e5c; margin: 15px 10px 0px 10px;" xml:space="preserve">

      <defs>
      </defs>
      <path id="twitter" class="st0" d="M25,0C11.2,0,0,11.2,0,25s11.2,25,25,25s25-11.2,25-25S38.8,0,25,0z M35.7,19.7v0.7c0,1.1-0.1,2.2-0.4,3.2
        c-1.4,6.1-6.6,11.9-15,11.9c-2.9,0-5.8-0.8-8.3-2.4c0.4,0,0.9,0.1,1.3,0.1c2.4,0,4.8-0.8,6.7-2.3c-2.3-0.1-4.3-1.5-5-3.7
        c0.3,0.1,0.7,0.1,1,0.1c0.5,0,1-0.1,1.4-0.2c-1.8-0.4-3.3-1.6-4-3.3c-0.2-0.6-0.4-1.3-0.4-1.9v-0.1c0.7,0.4,1.6,0.6,2.4,0.7
        c-2.4-1.5-3.1-4.7-1.7-7.1c2.8,3.3,6.8,5.4,11.1,5.6c-0.1-0.4-0.1-0.8-0.2-1.2c0-3,2.5-5.4,5.5-5.3c1.5,0,2.9,0.6,3.9,1.7
        c1.2-0.2,2.4-0.7,3.4-1.3c-0.4,1.2-1.3,2.3-2.4,3c1.1-0.1,2.1-0.4,3.1-0.8C37.6,18,36.7,18.9,35.7,19.7z"/>
    </svg>
  </social-media-icon>
</template>

<script>
import SocialMediaIcon from './SocialMediaIcon.vue';

export default {
  components: {
    SocialMediaIcon,
  }  
}
</script>
