<template>
  <div>
    <div class="row content justify-content-center m-auto" v-if="this.success">
      <div>
        <h1>Super&nbsp;!</h1>
        <h2>Tu as trouvé la bonne réponse.</h2>
        <div v-if="currentStep===1">
          <p>
            Essaye le jeu suivant avant de découvrir le principe qui se cache derrière.
          </p>
          <p>A toi de jouer&nbsp;!</p>
          <button @click="next" class="primary-btn">Jouer</button>
        </div>
        <div v-if="currentStep===2">
          <p>
            Découvre le principe qui se cache derrière.
          </p>
          <v-row>
            <v-col sm>
              <button @click="retry" class="retry-btn">Réessayer à l'identique</button>
            </v-col>
            <v-col sm>
              <button @click="next" class="primary-btn">Voir le principe</button>
            </v-col>
        </v-row>
          
        </div>
      </div>
    </div>
    <div class="row content justify-content-center m-auto" v-else>
      <div>
        <h1>Perdu&nbsp;!</h1>
        <h2>Ce n'est pas la bonne réponse.</h2>
        <div v-if="currentStep===1">
          <p class="margin-bot">Tant pis pour toi.</p>
          <button @click="next" class="primary-btn">Tester une autre charade</button>
        </div>
        <div v-else>
          <p class="margin-bot">Tu peux retenter ta chance ou découvrir le principe qui se cache derrière.</p>
          <v-row>
            <v-col sm>
              <button @click="retry" class="retry-btn">Réessayer à l'identique</button>
            </v-col>
            <v-col sm>
              <button @click="next" class="primary-btn">Voir le principe</button>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    success: Boolean,
    next: Function,
    step: Number,
    retry: Function,
  },

  data() {
    return {
      currentStep: null,
    };
  },

  mounted() {
    this.currentStep = this.step;
  }
};
</script>

<style scoped>
.margin-bot {
  margin-bottom: 40px !important;
}
</style>