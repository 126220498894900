import {createWebHistory, createRouter} from 'vue-router'
import Home from './views/home/HomeIndex.vue';
import Thanks from './views/ThanksPage.vue';
import LegalTerms from './views/LegalTerms.vue';
import Collab from './views/CollabPage.vue';
import CatchThemAllGame from './views/games/catchThemAll/CatchThemAllGame.vue';
import CharadeGame from './views/games/charade/CharradeGame.vue';
import CherryGame from './views/games/CherryOnTheCake/CherryGame.vue';
import PizzasGame from './views/games/pizzas/PizzasGame.vue';
import PoulpeGame from './views/games/poulpe/PoulpeGame.vue';
import OnTheDoubleGame from './views/games/onTheDouble/onTheDoubleGame.vue';
import Principles from './views/principles/HomePrinciple.vue';
import HomogeneityPrinciple from './views/principles/HomogeneityPrinciple.vue';
import ErrorHandlingPrinciple from './views/principles/ErrorHandling.vue';
import HickPrinciple from './views/principles/HickPrinciple.vue';
import MinimumActionsPrinciple from './views/principles/MinimumActions.vue';
import FittsPrinciple from './views/principles/FittsPrinciple.vue';
import Projet from './views/Projet/ProjetIndex.vue';
import Quiz from './views/Quiz/QuizPage.vue';
import ProximityLawPrinciple from "./views/principles/ProximityLawPrinciple.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: Thanks
  },
  {
    path: '/legal-terms',
    name: 'legalTerms',
    component: LegalTerms
  },
  {
    path: '/collab',
    name: 'collab',
    component: Collab
  },
  {
    path: '/game/catch-them-all',
    name: 'catchThemAll',
    component: CatchThemAllGame
  },
  {
    path: '/game/charade',
    name: 'charade',
    component: CharadeGame
  },
  {
    path: '/game/CherryOnTheCake',
    name: 'CherryOnTheCake',
    component: CherryGame
  },
  {
    path: '/game/pizzas',
    name: 'pizzas',
    component: PizzasGame
  },
  {
    path: '/game/poulpe',
    name: 'poulpe',
    component: PoulpeGame
  },
  {
    path: '/game/onTheDouble',
    name: 'onTheDouble',
    component: OnTheDoubleGame
  },
  {
    path: '/principles',
    name: 'principles',
    component: Principles
  },
  {
    path: '/principles/homogeneity',
    name: 'homogeneityPrinciple',
    component: HomogeneityPrinciple
  },
  {
    path: '/principles/error-handling',
    name: 'errorHandlingPrinciple',
    component: ErrorHandlingPrinciple
  },
  {
    path: '/principles/hick',
    name: 'HickPrinciple',
    component: HickPrinciple
  },
  {
    path: '/principles/minimum-actions',
    name: 'minimumActionsPrinciple',
    component: MinimumActionsPrinciple
  },
  {
    path: '/principles/fitts',
    name: 'FittsPrinciple',
    component: FittsPrinciple
  },
  {
    path: '/principles/proximity-law',
    name: 'ProximityLawPrinciple',
    component: ProximityLawPrinciple
  },
  {
    path: '/Projet',
    name: 'Projet',
    component: Projet
  },
  {
    path: '/quiz/:id',
    name: 'Quiz',
    component: Quiz
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;