<template>
  <span>
    <slot/>
  </span>
</template>

<style lang="scss" scoped>
@import "../../../assets/scss/colors.scss";
svg {
  width: 30px;
  height: 30px;
  margin: 15px 7px 0px 7px;
  transition: all 1s ease-out;
  -webkit-transition: all 1s ease-out;
  fill: $neutral-grey;
  &:hover {
    fill: $neutral-blue;
  }
}
</style>

