<template>
  <div class="credits">
    <div class="text">
      <p> 
        Une intiative <a class="external-link" href="http://uxshadow.fr/" target="_blank" rel="noopener noreferrer" aria-label="lien page web uxShadow">uxShadow</a> mise en oeuvre avec <a class="external-link" href="https://sogilis.com" target="_blank" rel="noopener noreferrer" aria-label="lien Sogilis">Sogilis</a> et des <router-link to="/collab" class="external-link">collaborateurs externes</router-link>. 
      </p>
      <div class="row row-legal justify-content-center m-auto">
        <div class="col-xs-12 col-md-6">
            <router-link to="/legal-terms" class="external-link">Mentions légales</router-link>
        </div>
        <div class="col-xs-12 col-md-6">
            <router-link to="/projet" class="external-link">En savoir plus sur le projet</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '../assets/scss/colors';
.credits {
  color: white;
  background-color: $neutral-blue;
  padding-bottom: 10px;
  text-align: center;
  position: absolute;
  width: 100%;

  .text {
    padding: 30px 10px 10px 10px;
    position: relative;
    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: -1px;
      left: -1px;
      background-color: white;
    }
    .external-link {
      color: white;
      text-decoration: underline;
      &:hover {
        font-weight: bold;
      }
    }
    .row-legal{
      max-width: 550px;
    }
  }
}
</style>
