<template>
  <v-app>
    <v-main class="wrapper" role="main">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  mounted () {
    // From testing, without a brief timeout, it won't work.
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), 1)
    }

  },
  methods: {
    scrollTo: function (hashtag) {
      setTimeout(() => { 
        const element = document.getElementById(hashtag.substring(1))
        window.scroll({
          top: element.offsetTop - 80, // 80 is header size
          left: 0,
          behavior: 'smooth'
        });
      }, 1)
    },
  }
}
</script>


<style lang="scss">
@import "assets/scss/colors.scss";

.basic_link {
  cursor: pointer;
  color: $dark-blue;
  font-weight: 500;
  text-decoration: underline $neutral-yellow;
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
  }
}

.basic_link:hover {
  color: $neutral-blue;
  text-decoration: underline $neutral-yellow;
}
.wrapper{
  padding-top: 0 !important;
}
</style>
