<template>
  <v-container class="products-department">
    <div v-if="round === 1" class="products-department__list">
      <ProductItem
        v-for="(ingredient, index) in ingredients"
        :key="`${index}`"
        :product="ingredient"
        @click="() => selectItem(ingredient)"
      />
    </div>
    <div v-if="round === 3">
      <p class="products-department__product-title">Bouteilles</p>
      <div class="products-department__list">
        <ProductItem
          v-for="(ingredient, index) in bottles"
          :key="`${index}`"
          :product="ingredient"
          @click="() => selectItem(ingredient)"
        />
      </div>
      <p class="products-department__product-title">Produits secs</p>
      <div class="products-department__list">
        <ProductItem
          v-for="(ingredient, index) in boxes"
          :key="`${index}`"
          :product="ingredient"
          @click="() => selectItem(ingredient)"
        />
      </div>
      <p class="products-department__product-title">Produits frais</p>
      <div class="products-department__list">
        <ProductItem
          v-for="(ingredient, index) in fruits"
          :key="`${index}`"
          :product="ingredient"
          @click="() => selectItem(ingredient)"
        />
      </div>
    </div>
    <v-fade-transition>
      <div v-if="validItemsSelected" class="products-department__finish">
        <slot name="button" />
      </div>
    </v-fade-transition>
  </v-container>
</template>

<script>
import ProductItem from './ProductItem.vue';

const images = require.context('@/assets/img/onTheDouble', true, /\.svg$/);

export default {
  name: 'onTheDoubleShoppingIngredients',
  components: { ProductItem },

  props: {
    round: {
      type: Number,
      required: true
    },
    validItemsSelected: Boolean
  },

  data() {
    return {
      ingredients: [],
      items: [
        images
          .keys()
          .filter(item => item.includes('bouteille'))
          .map(file => ({
            type: 'bouteille',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false
          })),

        images
          .keys()
          .filter(item => item.includes('boite'))
          .map(file => ({
            type: 'boite',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false
          })),

        images
          .keys()
          .filter(item => item.includes('fruit'))
          .map(file => ({
            type: 'fruit',
            name: `${file
              .split('/')
              .pop()
              .replace(/\.\w+$/, '')}`,
            img: images(file),
            isSelected: false,
            isValid: false
          }))
      ]
    };
  },
  methods: {
    selectItem(ingredient) {
      if (ingredient.isSelected && ingredient.isValid) {
        return;
      }

      ingredient.isSelected = !ingredient.isSelected;

      if (ingredient.isValid) {
        this.$emit('item-found', ingredient);
      }
    },
    isLastOfType(index) {
      const itemsRow1Start = 6;
      const itemsRow1End = 11;

      const itemsRow2Start = 18;
      const itemsRow2End = 23;

      if (this.round === 3) {
        if (index >= itemsRow1Start && index <= itemsRow1End) {
          return true;
        } else if (index >= itemsRow2Start && index <= itemsRow2End) {
          return true;
        }
      }
    }
  },
  computed: {
    bottles() {
      return this.ingredients.filter(
        ingredient => ingredient.type === 'bouteille'
      );
    },
    boxes() {
      return this.ingredients.filter(ingredient => ingredient.type === 'boite');
    },
    fruits() {
      return this.ingredients.filter(ingredient => ingredient.type === 'fruit');
    }
  },
  mounted() {
    this.ingredients = createIngredient(this.round, this.items);
  }
};

function createIngredient(round, items) {
  let ingredients = [];

  items.flat().reduce((acc, ingredient) => {
    ingredient.isValid = [
      'Farine',
      'Mozzarella',
      'Champignon',
      'Tomate'
    ].includes(ingredient.name);
    acc.push(ingredient);
    return acc;
  }, ingredients);

  if (round === 3) {
    return ingredients;
  }

  // On récupère les ingrédiens par type pour les mélanger
  let bouteilles = ingredients.filter(
    ingredient => ingredient.type === 'bouteille'
  );
  let boites = ingredients.filter(ingredient => ingredient.type === 'boite');
  let fruits = ingredients.filter(ingredient => ingredient.type === 'fruit');

  // On mélange les ingrédients de chaque type
  bouteilles.sort(() => Math.random() - 0.5);
  boites.sort(() => Math.random() - 0.5);
  fruits.sort(() => Math.random() - 0.5);

  let lines = [];
  // On boulce 6 fois pour créer 6 lignes
  for (let i = 0; i < 6; i++) {
    // On récupère au maximum 3 ingrédients de chaque type
    let line = [
      ...bouteilles.slice(i * 3, (i + 1) * 3),
      ...boites.slice(i * 3, (i + 1) * 3),
      ...fruits.slice(i * 3, (i + 1) * 3)
    ];

    // On mélange les ingrédients de chaque ligne
    line.sort(() => Math.random() - 0.5);
    lines.push(...line);
  }

  // On ajoute les ingrediens triés au dessus et on rajoute les ingrédients restants
  return [
    ...lines,
    ...bouteilles.slice(18),
    ...boites.slice(18),
    ...fruits.slice(18)
  ];
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/products-department.scss';
</style>
