<template>
  <div class="principle-practices">
    <slot name="header"></slot>
    <div class="principle-practices__illustrations">
      <slot name="good-practice"></slot>
      <slot name="bad-practice"></slot>
    </div>
    <slot name="footer"></slot>
  </div>
</template>

<script>
export default {
  name: 'PrinciplePractices'
};
</script>

<style lang="scss" scoped>
.principle-practices {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  &__illustrations {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 6rem;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 2rem;
    }
  }
}
</style>
