<template>
  <div v-if="currentVersion === 1" class="row content justify-content-center m-auto">
    <div>
      <h1>Dommage…</h1>
      <h2>
        Meiko n'a pas gagné la compétition avec un score de {{ score }}…
        <p class="p-second">Il faut dire qu'il a effectué {{ stepCount }} pas pour réaliser cette recette.</p>
      </h2>
      <p>
        Mais les concurrents qui le souhaitent peuvent s’affronter à
        nouveau dans un second concours&nbsp;!
      </p>

      <p class="margin-bot">Meiko sera-t-il capable de faire mieux avec une nouvelle recette, en optimisant ses
        déplacements dans une autre cuisine&nbsp;?</p>

      <div>
        <v-row>
          <v-col sm>
            <button @click="$emit('retry')" class="retry-btn">Réessayer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="$emit('next')" class="primary-btn">Tester une autre cuisine</button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>

  <div
      v-else-if="currentVersion === 2 && isBetterScore"
      class="row content justify-content-center m-auto"
  >
    <div>
      <h1>Bravo&nbsp;!</h1>
      <h2>
        Grâce à ton aide Meiko a décroché la médaille d’or avec un
        score de {{ score }}.
        <p class="p-second margin-bot">Cette fois, il n'a effectué que {{ stepCount }} pas pour réaliser sa recette.</p>
      </h2>

      <div>
        <v-row>
          <v-col sm>
            <button @click="$emit('retry')" class="retry-btn">Réessayer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="$emit('next')" class="primary-btn">Voir le principe</button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>

  <div
      v-else-if="currentVersion === 2 && !isBetterScore"
      class="row content justify-content-center m-auto"
  >
    <div>
      <h1>Dommage…</h1>
      <h2>
        Et bien&nbsp;? Meiko a-t-il été pris d’une crise de narcolepsie
        pendant le jeu&nbsp;?
      </h2>

      <p>Son score de {{ score }} ne lui permet pas de gagner ce concours, il va falloir s’entraîner plus dur&nbsp;!</p>

      <div>
        <v-row>
          <v-col sm>
            <button @click="$emit('retry')" class="retry-btn">Réessayer à l'identique</button>
          </v-col>
          <v-col sm>
            <button @click="$emit('next')" class="primary-btn">Voir le principe</button>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
// import stepCount from "./Game.vue";
export default {
  name: "PizzasResult",
  props: {
    version: Number,
    score: String,
    isBetterScore: Boolean,
    stepCount: Number
  },

  data() {
    return {
      currentVersion: null,
    };
  },

  mounted() {
    this.currentVersion = this.version;
  }
};


</script>
<style lang="scss">
.p-second {
  margin-top: 1em;
  margin-bottom: 0;
}

.margin-bot {
  margin-bottom: 40px !important;
}
</style>
