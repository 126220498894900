<template>
  <form name="form-contact" action="/thanks" method="post" netlify data-netlify-recaptcha="true">
    <input type="hidden" name="form-name" value="form-contact" />
    <input type="text" name="email" placeholder="Prénom Nom, ou pseudo rigolo" required aria-label="Nom et prénom">
    <input type="email" name="_replyto" placeholder="Email" required aria-label="Email">
    <textarea class="textArea" name="message" placeholder="Message (optionnel)" aria-label="Message"></textarea>
    <div data-netlify-recaptcha="true"></div>
    <div class="row justify-content-center">
      <button type="submit" class="send_mail">Envoyer </button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
@import "../../assets/scss/colors.scss";
input::placeholder,
textarea::placeholder {
   color: $neutral-grey;
}
input[type="email"] {
  margin: 15px 0 15px 0;
}

input[type="email"],
input[type="text"],
textarea {
  border: $neutral-grey 2px solid;
  text-indent: 15px;
  padding: 10px 15px 10px 15px;
  width: 100%;
  color: $neutral-grey;
  font-weight: 500;
  transition: all 0.5s;
  font-size: 0.9em;
}

input[type="email"]:focus,
input[type="text"]:focus,
textarea:focus {
  color: $dark-grey;
  border: $dark-grey 2px solid;
}

textarea {
  height: 180px;
}

@import '../../assets/scss/buttons';

.send_mail {
  @extend %action-button;
  color: white !important;

  &:hover {
    color: #ffce00 !important;
  }
}

.textArea {
  margin-bottom: 1.25rem;
}
</style>

