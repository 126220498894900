<template>
  <game-container ref="container">
    <template>
      <div>
        <v-dialog v-model="dialog" persistent>
          <v-card class="rounded-dialog">
            <cherry-result
                :alternative="this.alternative"
                :replay="this.replay"
                :gameAlternative="this.gameAlternative"
                :principle="this.principle"
                :score="scoreLabel"
                :ticks="this.ticks"
                :isBetterScore="isBetterScore"
                :wasSuccess="wasSuccess"
                :round="round"
                :last-round="lastRound"
            ></cherry-result>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template v-slot:result>
      <cherry-result
          :alternative="this.alternative"
          :replay="this.replay"
          :gameAlternative="this.gameAlternative"
          :principle="this.principle"
          :score="scoreLabel"
          :ticks="this.ticks"
          :isBetterScore="isBetterScore"
          :wasSuccess="wasSuccess"
      ></cherry-result>
    </template>
    <template v-slot:header>
      <v-col>
        <v-row class="game-header">
          <v-col class="align-items-center text-right header-timer">
            <font-awesome-icon :icon="faClock"></font-awesome-icon>
            {{ clock }}
          </v-col>
        </v-row>
      </v-col>
    </template>
    <div class="countdown">
      <img v-if="gameStartCountdown === 1" src="@/assets/img/Chrono/Chrono1.svg" class="countdown__img" alt="">
      <img v-if="gameStartCountdown === 2" src="@/assets/img/Chrono/Chrono2.svg" class="countdown__img" alt="">
      <img v-if="gameStartCountdown === 3" src="@/assets/img/Chrono/Chrono3.svg" class="countdown__img" alt="">
    </div>
    <v-container class="cakes" :class="{ 'cakes--blur': gameStartCountdown !== 0 }">
      <v-row v-for="y in [0,1,2]" :key="y">
        <v-col class="cake" v-for="x in [0,1,2]" :key="x">
          <div class="elements" @click="clickCake(getCake(round, x, y))">
            <img class="img-cake" v-if="getCake(round, x, y)" :src="getCake(round, x, y).img"/>
            <div class="error-message" v-if="getCake(round, x, y) && getCake(round, x, y).showError">
              <div>
                <font-awesome-icon :icon="wrongIcon" class="invalid-icon" size="2x"/>
              </div>
              <div> Il n'y a pas de cerise sur ce gâteau.</div>
            </div>
          </div>
          
        </v-col>
      </v-row>
    </v-container>
  </game-container>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import GameContainer from "../GameContainer.vue";
import CherryResult from "./CherryResult.vue";
import {getEntity} from "./entity_system";

/*
 * faire une seule page avec la spec d'une page 1 noCherry - 1 cherry
 * - faire un composant gateau(fichier_name, label) + 7 composants vides
 * - grid = [[{no-cherry}, empty, empty], [empty, empty, cherry], [empty, empty, empty]]
 *  <v-row v-for={row in grid}>
        <v-col class="cake" v-for={cell in row}>
          <img class="img-cake" src="../../../assets/img/{{cell.file}}" alt="cake">
        </v-col>
 *  </v-row>
 */

export default {
  name: "Cherry-Game",
  components: {
    GameContainer,
    CherryResult,
    FontAwesomeIcon
  },
  data() {
    return {
      alternative: false,
      round: 0,
      lastRound: 0,
      ticks: 0,
      firstScore: 0,
      faClock,
      wrongIcon: faTimes,
      dialog: false,
      gameFinished: false,
      wasSuccess: false,
      rounds: [
        [["c1", null, null], [null, null, null], [null, "f1", null]],

        [[null, "f2", null], [null, null, "c2"], [null, null, null]],

        [[null, null, "c3"], [null, null, null], [null, null, "f3"]],

        [[null, null, null], [null, "f4", null], ["c4", null, null]],

        [[null, null, null], [null, null, "c5"], [null, "f5", null]],

        [["c1", null, null], [null, null, null], [null, null, "ro"]]

      ],
      roundsAlternative: [
        [["c1", "f1", "f2"], ["f3", "f4", "f5"], ["fb", "ro", "no"]],

        [["f1", "f4", "f3"], ["fb", "ro", "c2"], ["no", "f2", "f5"]],

        [["no", "f2", "c3"], ["f1", "fb", "f5"], ["ro", "f4", "f3"]],

        [["ro", "f1", "f2"], ["f3", "no", "f5"], ["c4", "fb", "f4"]],

        [["f3", "f1", "f2"], ["f5", "f4", "c5"], ["fb", "ro", "no"]],

        [["c1", "f4", "f3"], ["fb", "ro", "f2"], ["no", "f1", "f5"]]

      ],
      cakes: [
        {name: "c1", img: require(`@/assets/img/cupcake-cherry1.png`)},
        {name: "c2", img: require(`@/assets/img/cupcake-cherry2.png`)},
        {name: "c3", img: require(`@/assets/img/cupcake-cherry3.png`)},
        {name: "c4", img: require(`@/assets/img/cupcake-cherry4.png`)},
        {name: "c5", img: require(`@/assets/img/cupcake-cherry5.png`)},
        {name: "no", img: require(`@/assets/img/cupcake-holly1.png`), showError: false},
        {name: "ro", img: require(`@/assets/img/cupcake-pink.png`), showError: false},
        {name: "fb", img: require(`@/assets/img/cupcake-raspberry1.png`), showError: false},
        {name: "f1", img: require(`@/assets/img/cupcake-strawberry1.png`), showError: false},
        {name: "f2", img: require(`@/assets/img/cupcake-strawberry2.png`), showError: false},
        {name: "f3", img: require(`@/assets/img/cupcake-strawberry3.png`), showError: false},
        {name: "f4", img: require(`@/assets/img/cupcake-strawberry4.png`), showError: false},
        {name: "f5", img: require(`@/assets/img/cupcake-strawberry5.png`), showError: false}
      ],
      gameStartCountdown: 3,
    };
  },
  methods: {
    init() {
      this.alternative = false;
      this.round = 0;
    },
    onTick() {
      // à chaque "tick", on incrémente le compteur de 1, sauf si le jeu est fini
      if (this.gameFinished == false) {
        this.ticks = this.ticks + 1;
      }
    },
    start() {
      window.scrollTo({top: 0});
      if (this.round === 0) {
        this.ticks = 0;
        this.gameFinished = false;
      }
    },
    startGameCountdown() {
      if (this.gameStartCountdown > 0) {
        setTimeout(() => {
          this.gameStartCountdown--;
          this.startGameCountdown();
        }, 1000);
      } else {
        this.start();
        this.tickInterval = setInterval(this.onTick, 1000);
      }
    },
    clickCake(cake) {
      if (cake !== null) {
        if (cake.name.startsWith("c")) {
          this.lastRound = this.round;
          this.round++;
          this.cakes.forEach(cake => cake.showError = false);
          if (this.alternative) {
            if (this.round === 6) {
              this.gameFinished = true;
              this.dialog = true;
            } else {
              this.replay();
            }
          } else {
            if (this.round === 6) {
              this.gameFinished = true;
              this.firstScore = this.ticks;
              this.wasSuccess = this.firstScore < 15;
              this.dialog = true;
            } else {
              this.replay();
            }
          }
        } else {
          cake.showError = true;
        }
      }
    },
    replay() {
      this.dialog = false;
      if (this.round === 6) {
        this.round = 0;
      }
      this.start();
    },
    gameAlternative() {
      this.dialog = false;
      this.alternative = true;
      if (this.round === 6) {
        this.round = 0;
      }
      this.replay();
    },
    principle() {
      this.$router.push("/principles/hick");
    },
    getCake(round, x, y) {
      const rounds = this.alternative ? this.roundsAlternative : this.rounds;
      const found = rounds[round] ? rounds[round][x][y] : null;
      return found ? this.cakes.filter(cake => cake.name === found)[0] : null;
    }
  },

  computed: {
    currentStep() {
      return getEntity(this.store, "currentStep").value;
    },

    steps() {
      return this.gameModule.stepLabels.filter(
          step => step.id <= this.currentStep
      );
    },

    clock() {
      // Tu divises le nombre de secondes par 60 ce qui donne le nombre de minutes.
      // Bien penser à tronquer, car le reste de la division correspond aux secondes à afficher (c'est la ligne qui suit)
      // Le toLocaleString permet juste de s'assurer d'avoir les chiffres affichés toujours par 2
      // même lorsque inférieur à 10 (donc on a 01:09 au lieu de 1:9)
      const minutes = Math.floor(this.ticks / 60).toLocaleString("fr-FR", {
        minimumIntegerDigits: 2
      });
      const seconds = (this.ticks % 60).toLocaleString("fr-FR", {
        minimumIntegerDigits: 2
      });
      return `${minutes}:${seconds}`;
    },

    scoreLabel() {
      const minutes = Math.floor(this.ticks / 60);
      const seconds = this.ticks % 60;
      const minutesLabel =
          minutes > 1 ? `${minutes} minutes` : `${minutes} minute`;
      const secondsLabel =
          seconds > 1 ? `${seconds} secondes` : `${seconds} seconde`;
      if (minutes === 0) {
        return secondsLabel;
      } else {
        return `${minutesLabel} et ${secondsLabel}`;
      }
    },

    isBetterScore() {
      return this.ticks <= this.firstScore;
    }
  },
  mounted() {
    this.init();
    this.startGameCountdown();
  },

  beforeUnmount() {
    // Et on supprime la fonction qui tourne toutes les secondes lorsqu'on quitte l'écran/le jeu
    // ça évite que ça continue indéfiniment même quand on revient à la page d'accueil
    window.clearInterval(this.tickInterval);
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/colors.scss";

.cakes {
  margin-top: 20px;
  margin-bottom: 20px;

  .cake {
    text-align: center;

    .img-cake {
      position: absolute;
      max-height: 90px;
      max-width: 120px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .elements {
    position: relative;
    background-color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 5px;
    transition: all 0.15s;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    height: 150px;
    justify-content: center;
  }

  .elements:not(:empty) {
    background-color: $light-blue;
    padding-top: 30px;
    text-align: center;
    padding-bottom: 30px;
    border-radius: 5px;
    transition: all 0.15s;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    height: 150px;
    justify-content: center;
  }

  .elements:not(:empty):hover {
    cursor: pointer;
    background-color: $dark-blue;
    -webkit-box-shadow: inset 0px 0px 0px 2px $neutral-grey;
    -moz-box-shadow: inset 0px 0px 0px 2px $neutral-grey;
    box-shadow: inset 0px 0px 0px 2px $neutral-grey;
  }

  .error-message {
    position: absolute;
    height: 100%;
    width: 100%;
    padding-top: 30px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
  }

  &--blur {
    filter: blur(20px);
    pointer-events: none;
  }
}


.game-header {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
  height: 62px;
}

.header-timer {
  margin-right: 20px;
}

@media screen and (min-width: 480px) and (max-width: 780px) {
  .img-cake {
    max-width: 120px;
  }
}

@media screen and (min-height: 410px) and (max-height: 600px) {
  .cakes .cake .elements {
    max-height: 90px;

    .img-cake {
      max-height: 60px;
    }
  }
}

@media screen and (max-height: 410px) {
  .cakes .cake .elements {
    max-height: 60px;
    line-height: 100px;

    .img-cake {
      max-height: 40px;
    }
  }
}

</style>