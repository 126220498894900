<template>
  <div class="collab">
    <closable-modal ref="pizzas">
      <pizzas-rule></pizzas-rule>
    </closable-modal>
    <closable-modal ref="poulpe">
      <poulpe-rule></poulpe-rule>
    </closable-modal>
    <home-header></home-header>
    <div class="title">
      <v-container>
        <h1>Collaborateurs externes</h1>
      </v-container>
    </div>
    <div class="content">
      <v-container>
        <h2></h2>
        <p>
          Ce site web est conçu et réalisé par
          <a
            class="basic_link"
            href="http://uxshadow.fr/"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="lien page web uxShadow"
          >uxShadow</a> et
          <a
            class="basic_link"
            href="https://sogilis.com"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="lien Sogilis"
          >Sogilis</a>. Pour la réalisation du
          <a
            class="basic_link"
            href="#"
            @click="() => showModal('pizzas')"
          >Jeu 4&nbsp;: À vous les pizzas&nbsp;!</a>, nous avons fait appel à des collaborateurs externes. Ce jeu a été réalisé quasi-intégralement par deux contributeurs bénévoles.
        </p>
        <v-row>
          <v-col lg="6">
            <v-row class="justify-content-center">
              <h2>Développement&nbsp;:</h2>
            </v-row>
            <v-row class="justify-content-center">
              <img height="250" src="../assets/img/marien.jpg" alt="Photo de Marien fressinaud">
            </v-row>
            <v-row class="justify-content-center">
              <h3 class="name">Marien fressinaud</h3>
            </v-row>
            <v-row class="justify-content-center">
              <a
                href="https://marienfressinaud.fr"
                target="_blank"
                rel="noopener noreferrer"
                class="basic_link"
                aria-label="linkedin Goulven"
              >marienfressinaud.fr</a>
            </v-row>
          </v-col>
          <v-col lg="6">
            <v-row class="justify-content-center">
              <h2>Illustrations&nbsp;:</h2>
            </v-row>
            <v-row class="justify-content-center">
              <img height="250" src="../assets/img/goulven.jpg" alt="Photo de Goulven Baron">
            </v-row>
            <v-row class="justify-content-center">
              <h3 class="name">Goulven Baron</h3>
            </v-row>
            <v-row class="justify-content-center">
              <a
                href="https://www.linkedin.com/in/goulven-baron/"
                target="_blank"
                rel="noopener noreferrer"
                class="basic_link"
                aria-label="linkedin Goulven"
              >Profil Linkedin</a>
            </v-row>
          </v-col>
        </v-row>
        <h2></h2>
        <p>Goulven Baron a également participé au design du 
          <a
            class="basic_link"
            href="#"
            @click="() => showModal('poulpe')"
          >Jeu 5&nbsp;: Sauve qui peut&nbsp;!</a>
        </p>
        <h2 class="thanks">Un énorme merci à eux&nbsp;!</h2>
      </v-container>
    </div>
  </div>
</template>

<script>
import HomeHeader from "./AppHeader.vue";
import ClosableModal from "./games/ClosableModal.vue";
import PizzasRule from "./games/pizzas/PizzasRule.vue";
import PoulpeRule from "./games/poulpe/PoulpeRule.vue";

export default {
  components: {
    HomeHeader,
    ClosableModal,
    PizzasRule,
    PoulpeRule
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  methods: {
    showModal(target) {
      this.$refs[target].show();
    }
  }
};
</script>


<style lang="scss">
@import "../assets/scss/zones";

.collab {
  padding-bottom: 50px;
  .title {
    @extend %title-zone;
    min-height: 150px;
    padding-top: 100px;
    padding-bottom: 30px;
    text-align: center;
    h1 {
      font-weight: 700;
    }
  }
  h2 {
    font-size: 1.3em;
    color: #0a5e5c;
    margin-top: 2em;
  }
  .name {
    padding-top: 0.5em;
  }
  .thanks {
    text-align: center;
  }
}
</style>

